<template>
    <div :class="classList">
        <label>{{ label }}</label>
        <input
            v-if="type === 'text'"
            type="text"
            ref="input"
            :value="modelValue"
            @input="updateValue"
            @keypress="numericValue"
        >

        <input
            v-if="type === 'email'"
            type="email"
            ref="input"
            :value="modelValue"
            @input="updateValue"
        >

        <multiselect
            v-if="type === 'multiselect'"
            v-model="modelValue"
            ref="input"
            :mode="multiselect.mode"
            :label="multiselect.label"
            :valueProp="multiselect.valueProp"
            :options="options"
            @input="updateValue"
        ></multiselect>

        <p
            class="error validation"
            v-if="error !== ''"
        >{{ error }}</p>
    </div>
</template>
<script>
    export default {
        props: {
            type: {
                default: 'text',
                type: String,
            },
            label: {
                type: String,
                required: true
            },
            error: {
                type: String,
                default: ''
            },
            attributes: {
                type: Object,
                default: {}
            },
            modelValue: {
                default: [],
                required: true
            },
            multiselect: {
                type: Object,
                default: {},
            },
            numeric: {
                type: Boolean,
                default: false
            },
            options: {
                type: Array,
                default: () => [],
            }
        },
        mounted() {
            const entries = Object.entries(this.attributes)

            if(entries.length) {

                if(this.type === 'text' || this.type === 'email') {
                    for(const [key, value] of entries) {
                        this.$refs.input.setAttribute(key, value)
                    }
                }
            }
        },
        data() {
            return {
            }
        },
        computed: {
            classList() {
                const classList = ['field-inline']

                if(this.error) {
                    classList.push('error')
                }

                return classList.join(' ')
            }
        },
        methods: {
            updateValue(e) {
                if(this.type === 'text' || this.type === 'email') {
                    this.$emit('update:modelValue', e.target.value)
                }

                if(this.type === 'multiselect') {
                    this.$emit('update:modelValue', e)
                }
            },
            numericValue(e) {
                if(this.numeric === true){
                    const regex = new RegExp("^[0-9]+$");
                    const key = String.fromCharCode(!e.charCode ? e.which : e.charCode);

                    if (!regex.test(key)) {
                        e.preventDefault();
                        return false;
                    }
                }
            }
        }
    }
</script>
