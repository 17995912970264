<template>
    <Constrained>
        <Titlebar title="New Machine" />

        <div class="field-group">
            <Field label="Identifier">
                <input id="identifier" type="text" v-model="form.identifier">
                <p class="error" v-if="$attrs.errors.identifier">{{ $attrs.errors.identifier }}</p>
            </Field>

            <Field label="Serial Number">
                <input v-model="form.serial_number" type="text" />
                <p class="error" v-if="$attrs.errors.serial_number">{{ $attrs.errors.serial_number }}</p>
            </Field>
        </div>

        <Field label="Model">
            <multiselect
                id="model"
                placeholder="Select a Model"
                v-model="form.machine_model_id"
                label="name"
                valueProp="id"
                :searchable="true"
                :options="machineModels"
            ></multiselect>
            <p class="error" v-if="$attrs.errors.machine_model_id">{{ $attrs.errors.machine_model_id }}</p>
        </Field>

        <Field label="Store">
            <multiselect
                id="store"
                placeholder="Select a store"
                v-model="form.store_id"
                label="name"
                valueProp="id"
                :searchable="true"
                :options="stores"
            ></multiselect>
            <p class="error" v-if="$attrs.errors.store_id">{{ $attrs.errors.store_id }}</p>
        </Field>

        <template #actions>
            <button
                @click="setAction(action)"
                v-for="action in actions"
                class="button-primary"
            >
            {{ action.text }}
            </button>
        </template>
    </Constrained>
</template>

<script>
    import { form, stores, machineModels } from '@/Mixins';

    export default {
        mixins: [form, stores, machineModels],
        data() {
            return {
                form: {
                    identifier: null,
                    machine_type_id: null,
                    store_id: null,
                    machine_model_id: null,
                    serial_number: null
                }
            }
        },
    }
</script>

