<template>
<svg viewBox="0 0 10.5 12">
<path fill="currentColor" d="M10.219,11.25c0.155,0,0.281,0.127,0.281,0.281v0.188c0,0.155-0.127,0.281-0.281,0.281H1.875
	C0.839,12,0,11.161,0,10.125v-8.25C0,0.839,0.839,0,1.875,0h8.062C10.249,0,10.5,0.251,10.5,0.562v8.625
	c0,0.234-0.145,0.436-0.349,0.52c-0.084,0.377-0.103,1.069-0.009,1.542H10.219z M1.875,9H2.25V0.75H1.875
	c-0.621,0-1.125,0.504-1.125,1.125v7.5C1.064,9.141,1.453,9,1.875,9z M9.476,9.75H1.875c-1.5,0-1.5,1.5,0,1.5h7.601
	C9.403,10.772,9.408,10.191,9.476,9.75z M9.75,9V0.75H3V9H9.75z M4.406,3.75c-0.155,0-0.281-0.127-0.281-0.281V3.281
	C4.125,3.127,4.252,3,4.406,3h3.938c0.155,0,0.281,0.127,0.281,0.281v0.188c0,0.155-0.127,0.281-0.281,0.281H4.406z M8.344,5.25
	H4.406c-0.155,0-0.281-0.127-0.281-0.281V4.781c0-0.155,0.127-0.281,0.281-0.281h3.938c0.155,0,0.281,0.127,0.281,0.281v0.188
	C8.625,5.123,8.498,5.25,8.344,5.25z"/>
</svg>
</template>
