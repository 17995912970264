<template>
    <Layout>
        <Titlebar :title="title" :links="links" />
        <Table :table="table" :row_action="showJob" />
    </Layout>
</template>

<script>
    import { table } from '@/Mixins'
    import Table from '@/Components/Table'

    export default {
        mixins: [table],
        components: {
            Table
        },
        methods: {
            showJob(id) {
                const link = route('jobs.show', id);
                this.$inertia.visit(link);
            }
        }
    }
</script>
