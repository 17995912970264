<template>
    <div class="table">
        <div class="table-actions" v-if="showActions">
            <div class="table-filters" v-if="table.filters">
                <Link
                    :key="filter"
                    v-for="filter in table.filters"
                    ref="filters"
                    :class="{active: filter.active}"
                    :data-key="filter.key"
                    :href="getFilterHref(filter)"
                >
                    {{ filter.value }} <span v-if="filter.extra">{{ filter.extra }}</span>
                </Link>
            </div>
            <div v-if="table.search !== false" class="table-search">
                <button @click="doSearch"><SearchIcon /></button>
                <input v-model="query" @keyup.enter="doSearch" type="text" />
                <button @click="clearSearch" v-if="query">
                    <CrossIcon />
                </button>
            </div>
        </div>
        <table>
            <Row
                :head="true"
                :cells="table.header"
                @sort="doSort"
            />
            <Row
                :key="item"
                v-for="(item) in table.content.data"
                :cells="getCells(item)"
            />
        </table>
        <Pagination v-if="table.content.data.length" :info="table.content" />
        <p v-else>There are no active jobs</p>
    </div>
</template>
<script>

import { table } from '@/Mixins'
import Row from '@Table/Row'
import Pagination from '@/Components/Pagination'

export default {
    mixins: [table],
    components: {
        Row,
        Pagination
    },
    props: {
        row_action: {
            type: Function,
            default: () => {}
        },
    },
    data() {
        return {
            query: this.table.search
        }
    },
    computed: {
        showActions() {
            return this.table.search !== false || this.table.filters
        }
    },
    mounted() {
        // this.setSort()
    },
    methods: {
        /*
        *   Get the value for the cell based on the key defined in the header.
        *
        *   With a recursive function getValue we can use dot notation to get
        *   nested values. For example machine.workshop.name will resolve and
        *   just return the value as a String
        */
        getCells(item) {

            const getValue = function(arr, ob) {

                // If we get 0 we are at the bottom of the stack
                if(arr.length === 0) {
                    return ob
                }

                const [first, ...rest] = arr;

                let next = ob[first]

                const regex = first.match(/\[(\d+)\]/)

                if(regex !== null) {
                    // This is for cases like parts.workshops[i]
                    const id = parseInt(regex[1])
                    const key = first.substr(0, regex.index)

                    const work = ob[key].find(ws => {
                        return ws.id === id
                    })

                    next = work
                } else {
                    // This is for cases like parts.workshop[i].pivot
                    next = ob[first]
                }

                return typeof(next) === "object" ? getValue(rest, next) : next
            }

            return this.table.header.map(({ type, key, extra, action, component, null_value, classList }) => {
                let cell  = {}

                // If null_value is set use it if not use empty string
                null_value = (null_value !== undefined && null_value !== null) ? null_value : ''

                cell.component = component
                cell.type = type
                cell.key = key
                cell.classList = classList
                cell.value = item[key] ?? null_value
                cell.action = () => {this.row_action(item.id)}

                if(action) {
                    cell.action = action
                }

                if(type === 'self') {
                    cell.value = item
                }

                if (key.indexOf('.') > -1) {
                    let dots = key.split('.')
                    let result = getValue(dots, item)
                    cell.value = result;
                }

                if(extra) {
                    cell.extra = {};

                    Object.keys(extra).forEach(i => {
                        cell.extra[extra[i]] = item[extra[i]];
                    })
                }

                return cell
            })
        },
        clearSearch() {
            const params = Object.fromEntries(new URLSearchParams(location.search));
            delete params.search
            this.$inertia.get(route(route().current(), params))
        },
        doSearch() {
            const params = Object.fromEntries(new URLSearchParams(location.search));
            if(this.query) {
                params.search = this.query
            } else {
                delete params.search
            }

            delete params.page

            this.$inertia.get(route(route().current(), params))
        },
        getFilterHref(filter) {

            if(filter.filter === false) return filter.href

            let params = Object.fromEntries(new URLSearchParams(location.search));

            if(filter.key && filter.key !== '') {
                params.filter = filter.key
            }

            if(filter.key === '') {
                delete params.filter
            }

            delete params.page

            return route(route().current(), params)
        },
        doSort(cell) {
            const params = Object.fromEntries(new URLSearchParams(location.search));

            if(cell.sortDirection !== '') {
                const prefix = (cell.sortDirection === 'desc') ? '-' : ''
                params.sort = `${prefix}${cell.sort}`
            } else {
                delete params.sort;
            }
            this.$inertia.get(route(route().current(), params))
        }
    }
}
</script>
<style scoped>
    table {
        width: 100%;
        border-spacing: 0 4px;
        border-collapse: seperate;
        margin-bottom: 20px;
    }

    table a {
        display: table;
        width: 100%;
    }

    .table-row {
        cursor: pointer;
    }

    .table-filters {
        display: flex;
    }

    .table-filters a {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid rgba(var(--default-contrast-rgb), 0.5);
        color: var(--default-contrast);
        border-radius: 100px;
        padding: .5em 1em;
    }

    .table-filters a span {
        height: 20px;
        width: auto;
        min-width: 20px;
        padding: 6px;
        font-size: 9px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        background-color: var(--default-off-white);
        margin-left: 2em;
    }

    .table-filters a span:before {
        content: '';
        display: block;
        padding-top: 100%;
    }

    .table-filters a.active {
        background-color: var(--default-contrast);
        color: var(--default-white);
    }

    .table-filters a.active span {
        background-color: rgba(0, 0, 0, 0.5);
    }

    .table-filters a + a {
        margin-left: 1em;
    }

    .table-actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
        margin-top: 60px;
    }

    .table-search {
        display: flex;
        align-items: center;
        border-radius: 100px;
        border: 1px solid rgba(var(--default-contrast-rgb), 0.5);
        color: var(--default-contrast);
    }

    .table-search input {
        border: none;
        border-radius: 100px;
        height: 38px;
        outline: none;
    }


    .table-search button {
        border: none;
        background: transparent;
        cursor: pointer;
    }

    .table-search svg {
        height: 16px;
        padding: 0 .5em;
        color: rgba(var(--default-contrast-rgb), 0.5);
    }

</style>
