<template>
    <Layout>
        <template #sidebar>
            <MachineCard :machine="job.machine" :showLink="true" />
            <JobTracker :steps="steps" />
        </template>

        <div v-if="isModalActive" class="modal">
            <div class="modal-content">
                <Titlebar title="Log Parts Used">
                    <button
                        @click="closeModal"
                        class="close"
                    >
                    <CrossIcon />
                    </button>
                </Titlebar>
                <div class="part-picker">
                    <div class="part-picker_search">
                        <label>Find Parts by name or part number</label>
                        <div class="part-picker_search-area">
                            <input
                                type="text"
                                v-model="partPicker.query"
                                @keyup.enter="submitPartPicker"
                            >
                            <multiselect
                                id="category"
                                placeholder="All parts"
                                v-model="partPicker.category"
                                label="name"
                                valueProp="id"
                                :options="partCategories"
                            ></multiselect>
                            <button @click="submitPartPicker">
                                <SearchIcon />
                            </button>
                        </div>
                    </div>
                    <div class="part-picker_parts-title">
                        <p>
                            {{ partPicker.title }}
                            <span v-if="partPicker.count">{{ partPicker.count}}</span>
                        </p>
                    </div>
                    <div class="part-picker_parts">
                        <PartModifier
                            :key="part.id"
                            v-for="part in combinedParts"
                            :part="part"
                            @update="updateParts"
                        />
                    </div>
                    <!-- Dummy action, parts are updated when selecting them -->
                    <div class="actions">
                        <button
                            @click="closeModal"
                            class="button-primary"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <Titlebar title="Servicing" />

        <Field label="Machine hours">
            <div class="field-short">
                <input
                    :disabled="!editable"
                    v-model="form.machine_hours"
                    type="number"
                    placeholder="Machine hours"
                />
            </div>
            <p class="error" v-if="$attrs.errors.machine_hours">{{ $attrs.errors.machine_hours }}</p>
        </Field>
        <Field label="Parts used">
            <PartModifier
                :key="part.id + part.count"
                v-for="(part, i) in form.selectedParts"
                :error="getSelectedPartError(part)"
                :editable="editable"
                :part="part"
                :startDirty="true"

                @update="updateParts"
            />
            <button
                type="button"
                @click="showModal"
                v-if="editable"
                class="button-section"
            >
            <PuzzleIcon />
            Add Parts
            </button>
        </Field>

        <!--<Field label="Photos/Videos">-->
        <!--...-->
        <!--</Field>-->

        <Checklist
            v-for="checklist in checklists"
            :checklist="checklist"
        />

        <Field label="Workshop comments">
            <textarea
                :disabled="!editable"
                v-model="form.service_comment"
                placeholder="Describe work done to diagnose and repair fault(s)"
            >
            </textarea>
            <p class="error" v-if="$attrs.errors.service_comment">{{ $attrs.errors.service_comment }}</p>
        </Field>

        <template #actions>
            <FormActions :actions="actions" :setAction="setAction" :submit="submit" />
        </template>
    </Layout>
</template>

<script>
    import { job, form, parts, checklists, editable } from '@/Mixins'
    import { useForm } from '@inertiajs/inertia-vue3'
    import MachineCard from '@Cards/MachineCard'
    import JobTracker from '@/Components/JobTracker'
    import Field from '@/Components/Field'
    import EditableField from '@/Components/EditableField'
    import PartPicker from '@/Components/PartModifier'
    import PartModifier from '@/Components/PartModifier'
    import PuzzleIcon from '@/Icons/PuzzleIcon'
    import SearchIcon from '@/Icons/SearchIcon'
    import CrossIcon from '@/Icons/CrossIcon'
    import Pagination from '@/Components/Pagination'
    import { debounce } from '@/Utils/debounce'

    export default {
        mixins: [job, parts, form, checklists, editable],
        components: {
            PartPicker,
            JobTracker,
            MachineCard,
            EditableField,
            Field,
            PuzzleIcon,
            SearchIcon,
            CrossIcon,
            PartModifier,
            Pagination,
        },
        props: {
            partCategories: {
                type: Array,
                default: () => []
            }
        },
        created() {
            this.debouncedSave = debounce((newValue, oldValue) => {
                if(newValue !== oldValue) {
                    this.$inertia.post(
                        route('jobs.update.service', this.job.id), 
                        this.form,
                        {
                            preserveScroll: true
                        }
                    )
                }
            }, 500)
        },
        data() {
            const selectedParts = this.normalizeParts(this.job.parts)
            const combinedParts = this.normalizeParts(this.parts.data)

            return {
                combinedParts,
                selectedParts,
                form: {
                    service_comment: this.job.service_comment,
                    machine_hours: this.job.machine_hours,
                    selectedParts
                },
                partPicker: {
                    query: '',
                    title: 'Suggested parts often used for this model',
                    category: null,
                },
                isModalActive: false
            }
        },
        mounted() {
            this.submitClone = this.submit
            this.submit = this.intercept

            this.partPicker = useForm(this.partPicker)
        },
        watch: {
            'form.service_comment'(...args) {
                this.debouncedSave(...args)
            },
            'form.machine_hours'(...args) {
                this.debouncedSave(...args)
            },
        },
        methods: {
            normalizeParts(parts) {
                return parts.map(part => {

                    // If we have a pivot with workshops this part is proably from the job relationship
                    if (part.pivot) {
                        part.quantity_on_job = part.pivot.quantity
                        part.count = part.quantity_on_job
                        part.quantity_in_workshop = part.workshops.find(workshop => workshop.id === this.job.workshop_id).pivot.quantity
                        part.low_stock_threshold = part.workshops.find(workshop => workshop.id === this.job.workshop_id).pivot.low_stock_threshold
                    } else {
                        // If we don't have a pivot this part is from the workshop and we need to fill in the job data
                        const PartOnJob = this.job.parts.find(p => p.id === part.part_id)
                        part.id = part.part_id
                        part.quantity_on_job = 0
                        part.count = 0 
                        part.quantity_in_workshop = part.quantity 

                        if (PartOnJob) {
                            part.quantity_on_job = PartOnJob.pivot.quantity
                        }
                    }

                    return part;
                })
            },
            getSelectedPartError(part) {

                if (this.$attrs.errors['selectedParts.0.count']) {
                    const split = this.$attrs.errors['selectedParts.0.count'].split(':')
                    if (parseInt(split[0]) === part.id) {
                        return split[1]
                    }
                }
            },
            showModal() {
                if(this.editable) {
                    this.isModalActive = true
                }
            },
            closeModal() {
                this.$inertia.post(route('jobs.parts.add', this.job.id), this.form, {
                    onSuccess: (data) => {
                        this.form.selectedParts = this.normalizeParts(data.props.job.parts)
                        this.combinedParts = this.normalizeParts(this.parts.data);
                    }
                })
                this.isModalActive = false
            },
            updateParts(value) {

                const form = useForm({
                    selectedParts: [value],
                })
                
                this.$inertia.post(route('jobs.parts.add', this.job.id), form, {
                    onSuccess: (data) => {
                        if (this.partPicker.query) {
                            this.submitPartPicker()
                        } else {
                            this.form.selectedParts = this.normalizeParts(data.props.job.parts)
                            this.combinedParts = this.normalizeParts(this.parts.data);
                        }
                    }
                })
            },
            intercept(action) {

                if(this.form.selectedParts.length < 1) {
                    this.ebus.emit('modal:open', {
                        title: 'Confirm to continue',
                        message: 'Did you use any parts in this service?',
                        actions: [
                            {
                                text: 'I want to add parts',
                                method: 'close'
                            },
                            {
                                text: 'I didn\'t use any parts',
                                method: 'post',
                                href: action.href,
                                componentProps: this.form
                            }
                        ],
                        onError: () => {
                            this.ebus.emit('modal:close')
                        }
                    })
                } else {
                    this.submitClone(this.form)
                }
            },
            submitPartPicker() {
                const path = route('jobs.servicing.show', this.job)

                this.partPicker.post(path, {
                    onSuccess: (response) => {
                        if(this.partPicker.query === '') {
                            this.partPicker.title = 'Suggested parts often used for this model'
                            this.partPicker.count = undefined
                        } else {
                            this.partPicker.title = `Search Results`
                            this.partPicker.count = this.parts.data.length
                        }
                        this.combinedParts = this.normalizeParts(response.props.parts.data);
                    }
                })
            }
        }
    }
</script>
<style>
    .modal {
        overflow-y: scroll;
        z-index: 50;
        position: absolute;
        top: 0; left: 0;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        background-color: var(--default-contrast);
        color: var(--default-white);
        padding: var(--main-padding);
    }

    .modal .close {
        border: none;
        background: transparent;
        cursor: pointer;
        color: var(--default-white);
    }

    .modal .close svg {
        width: 24px;
    }

    .modal h1 {
        color: var(--default-white);
    }

    .part-picker_search label,
    .part-picker .part-picker_parts-title p {
        font-weight: 900;
        margin-bottom: 16px;
    }

    .part-picker_search label {
        display: block;
    }

    .part-picker .part-picker_parts-title p {
        font-size: 14px;
    }

    .part-picker_search {
        margin-bottom: var(--main-padding);
    }

    .part-picker_search-area {
        display: flex;
        align-items: center;
        background-color: var(--default-white);
        border-radius: var(--default-border-radius);
    }

    .part-picker_search-area input {
        border-top-left-radius: var(--default-border-radius);
        border-bottom-left-radius: var(--default-border-radius);
        border: none;
        padding: 1em;
        flex-grow: 1;
    }

    .part-picker_search-area .multiselect {
        width: 184px;
        height: 36px;
        margin-right: 1em;
        border: 2px solid var(--default-border-white);
        border-radius: 48px;
    }

    .part-picker_search-area .multiselect-option,
    .part-picker_search-area .multiselect-single-label {
        color: var(--default-contrast);
    }

    .part-picker_search-area button {
        display: flex;
        flex: 0 0 60px;
        align-items: center;
        justify-content: center;
        height: 48px;
        width: 48px;
        padding: 0;
        border: 0;
        border-top-right-radius: var(--default-border-radius);
        border-bottom-right-radius: var(--default-border-radius);
        background-color: var(--default-primary);
        cursor: pointer;
        color: var(--default-white);
    }

    .part-picker_search-area button svg {
        width: 22px;
    }

    .checklist {
        margin-bottom: 30px;
    }

</style>
