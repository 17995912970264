<template>
    <div :class="fieldClassList">
        <div class="field-label" v-if="label">
            <p class="label">{{ label }}</p>
            <p v-if="editor || author" class="meta">
                <span>{{ create_date }}</span>
                by <span class="weight-medium">{{ author }}</span>
                <span v-if="edit_date">
                    - last edited on {{ edit_date }}
                    <span v-if="editor">
                        by
                        <span class="weight-medium">
                        {{ editor }}
                        </span>
                    </span>
                </span>
            </p>
        </div>
        <div class="field-input">
            <p
                v-if="!editing"
                :class="blockStyle"
            >{{ fieldValue }}</p>
            <textarea
                v-if="editing"
                v-model="fieldValue"
            ></textarea>
        </div>
        <div class="field-actions">
            <button
                @click="edit"
                v-if="!editing && editable"
                class="field-actions--edit"
                title="Edit"
            >
            <PencilIcon/>
            </button>
            <button
                @click="save"
                v-if="editing"
                class="field-actions--save"
                title="Save"
            >
            <SaveIcon/>
            </button>
            <button
                @click="cancel"
                v-if="editing"
                class="field-actions--cancel"
                title="Cancel"
            >
            <CrossIcon/>
            </button>
        </div>
    </div>
</template>
<script>

    import { debounce } from '@/Utils/debounce'
    import PencilIcon from '@/Icons/PencilIcon'
    import CrossIcon from '@/Icons/CrossIcon'
    import SaveIcon from '@/Icons/SaveIcon'

    export default {
        components: {
            PencilIcon,
            CrossIcon,
            SaveIcon
        },
        props: {
            model: {
                type: Object,
                default: ''
            },
            endpoint: {
                type: String,
                default: ''
            },
            label: {
                type: String,
                default: ''
            },
            value: {
                type: String,
                default: ''
            },
            blockStyle: {
                type: String,
                default: ''
            },
            create_date: {
                type: String,
                default: ''
            },
            edit_date: {
                type: String,
                default: ''
            },
            editor: {
                type: String,
                default: ''
            },
            editable: {
                type: Boolean,
                default: false 
            },
            author: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                editing: false,
                fieldValue: this.value,
            }
        },
        watch: {
            fieldValue(...args) {
                this.debouncedSave(...args)
            }
        },
        mounted() {
            // Check if the parent is a field-group
            // If it is then check if any of the children have labels
            // If they don't return them and apply extra style
            // to that field
            if(!this.label) {
                const nolabel = Array.from(this.$el.parentNode.children).filter(el => {
                    const label = el.querySelector('.field-label')
                    return (label === null)
                })

                nolabel.forEach(el => {
                    el.classList.add('field-label-compensation')
                })
            }
        },
        created() {
            this.debouncedSave = debounce((newValue, oldValue) => {
                if(newValue !== oldValue) {
                    this.save()
                }
            }, 500)
        },
        computed: {
            fieldClassList() {
                const classes = ['field field-editable']

                if(this.editing) {
                    classes.push('field--editing')
                }

                return classes.join(' ')
            }
        },
        methods: {
            edit() {
                if (!this.editable) return;
                this.editing = true
            },
            cancel() {
                this.fieldValue = this.value
                this.editing = false
            },
            save() {
                this.saveKeepOpen()
                this.editing = false;
            },
            saveKeepOpen() {
                const link = route(this.endpoint, this.model.id);
                this.$inertia.post(link, {
                    data: this.fieldValue,
                    preserveScroll: true
                });
            }
        }
    }
</script>
<style>

    .field-group {
        display: flex;
        align-items: flex-start;
        width: 100%;
    }

    .field-group .field {
        flex: 0 0 calc(50% - 1em);
    }

    .field-group .field:first-child {
        margin-right: 1em;
    }

    .field-group .field:last-child {
        margin-left: 1em;
    }

    .field-group .field + .field {
        margin-top: 0;
    }

    .field + .field-group,
    .field-group + .field-group,
    .field-group + .field {
        margin-top: 2em;
    }

    .field + .field {
        margin-top: 2em;
    }

    .field-editable {
        position: relative;
    }

    .field-label {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1em;
        color: var(--default-text-color);
    }

    .field-label-compensation .field-input {
        margin-top: 2.75em;
        /* This should be automatic */
    }

    .label, .meta {
        margin: 0;
    }

    .label {
        font-weight: 800;
        font-size: 18px;
    }

    .field--editing textarea {
        outline: 1px solid var(--default-info);
    }

    .field .multiselect {
        border-radius: var(--default-border-radius);
        min-height: 48px;
    }

    .field-short {
        width: 50%;
    }

    .field-has-icon {
        position: relative;
        display: flex;
        align-items: center;
    }

    .field-icon {
        position: absolute;
        z-index: 50;
        top: 0;
        right: 1em;
        display: flex;
        align-items: center;
        height: 100%;
        font-size: 18px;
        /*transform: scale(1.75, .9);*/
        color: var(--default-contrast);
    }

    .field textarea {
        min-height: 100px;
    }

    .field input[type="text"],
    .field input[type="email"],
    .field input[type="number"] {
        min-width: 50%;
        width: 100%;
        height: 48px;
        border: 0;
        padding: 0;
        padding-left: 14px;
        border-radius: var(--default-border-radius);
        background-color: var(--default-input-background);
    }

    .field-actions {
        display: flex;
        justify-content: flex-end;
        margin: 5px;
        margin-right: 0;

        position: absolute;
        bottom: 12px;
        right: 10px;
    }

    .field-actions button {
        cursor: pointer;
        display: block;
        background-color: transparent;
        /*background-color: var(--default-light);
        border-radius: var(--default-border-radius);*/
        padding: .5em 1em;
        border: none;
    }

    .field-actions button + button {
        /*margin-left: 5px;*/
    }

    .field-actions button svg {
        color: var(--default-contrast);
        width: 1.2em;
        transition: color 100ms var(--transition-timing-function);
    }

    .field-actions button:hover svg {
        color: var(--default-secondary);
    }

</style>
