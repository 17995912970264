<template>
    <Constrained>
        <Titlebar title="New Workshop" />

        <Field label="Name">
            <input v-model="form.name" type="text" />
            <p class="error" v-if="$attrs.errors.name">{{ $attrs.errors.name}}</p>
        </Field>

        <Field label="Address">
            <input v-model="form.address" type="text" />
            <p class="error" v-if="$attrs.errors.address">{{ $attrs.errors.address}}</p>
        </Field>

        <Field label="Rego">
            <input v-model="form.rego" type="text" />
            <p class="error" v-if="$attrs.errors.rego">{{ $attrs.errors.rego}}</p>
        </Field>

        <Field label="Stores">
            <multiselect
                id="stores"
                placeholder="Which stores does this workshop service"
                label="name"
                mode="tags"
                valueProp="id"
                v-model="form.stores"
                :options="stores"
            ></multiselect>
            <p class="error" v-if="$attrs.errors.stores">{{ $attrs.errors.stores }}</p>
        </Field>

        <template #actions>
            <button
                @click="setAction(action)"
                v-for="action in actions"
                class="button-primary"
            >
            {{ action.text }}
            </button>
        </template>
    </Constrained>
</template>

<script>
    import { form, stores } from '@/Mixins'
    import FieldInline from '@/Components/FieldInline'

    export default {
        mixins: [form, stores],
        components: {
            FieldInline
        },
        data() {

            return {
                form: {
                    name: null,
                    address: null,
                    rego: null,
                    stores: []
                },
            }
        }
    }
</script>
