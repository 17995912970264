
export default {
    props: {
        user: {
            type: Object,
            default: {}
        }
    },
    computed: {
        link() {
            return route('users.show', this.user.id)
        },
        rolesText() {
            return this.user.roles.map(r => r.name).join(',')
        }
    }
}

