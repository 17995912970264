<template>
    <div class="user-switcher" ref="switcher">
        <label v-for="user in orderedUsers">
            <input
                type="radio"
                :value="user"
                name="user"
                @change="update(user)"
            >
            <Avatar :name="user.name" :roles="user.rolesList" />
        </label>
    </div>
</template>

<script>
    import { users } from '@/Mixins'
    import Avatar from '@/Components/Avatar'

    export default {
        mixins: [users],
        components: {
            Avatar
        },
        computed: {
            orderedUsers() {
                let order = { 'support': 0, 'technician': 1, 'groomer': 2, 'admin': 3 }

                return this.users.sort(function (a, b) {
                    return order[a.rolesList] - order[b.rolesList];
                });
            },
        },
        methods: {
            update(user) {
               this.$emit('update', user.email)
            },
            reset() {
                Array.from(this.$refs.switcher.querySelectorAll('input')).forEach(input => {
                    input.checked = false
                })
            }
        }
    }
</script>

<style>
    .user-switcher {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 40px 0;
        /*background: rgba(orange, .25);*/
    }

    .user-switcher label {
        position: relative;
        cursor: pointer;
        margin: 20px 0;
    }

    .user-switcher input {
        position: absolute;
        opacity: 0;
    }

    .user-switcher input:checked + .avatar .avatar-thumbnail {
        /*background-color: var(--default-secondary); */
        opacity: 0.4;
    }
</style>
