<template>
<div class="machine-card">
    <ToolIcon v-if="machineIcon == 'tool'" />
    <MachineIcon v-else-if="machineIcon == 'machine'" />

    <p class="machine-id">{{ machineType }} #{{ machine.identifier }}</p>
    <Link v-if="showLink" :href="link" class="machine-card-link">
        <BookIcon />View service record
    </Link>
    <span v-if="machine.retiredText" :class="machineStateClass">{{ machine.retiredText }}</span>
    <SidebarTable v-if="showTable" :items="sidebarItems" />
</div>
</template>
<script>
    import { machine } from '@/Mixins'
    import MachineIcon from '@/Icons/MachineIcon'
    import ToolIcon from '@/Icons/ToolIcon'
    import BookIcon from '@/Icons/BookIcon'
    import SidebarTable from '@/Components/SidebarTable'

    export default {
        mixins: [machine],
        components: {
            ToolIcon,
            MachineIcon,
            BookIcon,
            SidebarTable
        },
        props: {
            showTable: {
                type: Boolean,
                default: false
            },
            showLink: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                sidebarItems: [
                    {
                        key: 'Serial no.',
                        value: this.machine.serial_number
                    },
                    {
                        key: 'Model',
                        value: this.machine.model.name
                    },
                    {
                        key: 'No. Repairs',
                        value: this.machine.completeJobs ?? 0
                    },
                    {
                        key: 'Machine Hours',
                        value: this.machine.totalHours ?? 0
                    }
                ]
            }
        },
        computed: {
            machineStateClass() {
                return this.machine.retired ? 'pill-grey' : 'pill-secondary'
            },
            machineIcon() {
                return this.machine.machineType.name
            },
            machineType() {
                return this.machine.machineType.nameUpper
            }
        }
    }
</script>

<style scoped>

    .machine-card p {
        margin: 1em 0;
    }

    .machine-id {
        font-size: 20px;
        font-weight: 800;
    }

    .machine-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 60px;
    }

    .icon-machine {
        width: 60px;
    }

    .machine-card-link {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        font-weight: 700;
        margin-bottom: 20px;
    }

    .machine-card-link svg {
        width: 1.25em;
        margin-right: 1em;
    }

</style>
