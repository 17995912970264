<template>
<svg viewBox="0 0 13.5 12">
<path fill="currentColor" d="M13.5,7.875c0,0.975-0.604,1.919-1.591,1.919c-1.03,0-1.777-1.198-2.168-0.682
	c-0.448,0.59,0.158,2.543,0.18,2.623c-1.884,0.001-3.561,0.581-4.385-0.147c-0.222-0.196-0.34-0.471-0.34-0.793
	C5.197,9.852,6,9.675,6,9c0-0.279-0.242-0.75-1.149-0.75C4.278,8.25,3.75,8.427,3.75,9c0,0.774,0.75,1.044,0.75,1.731
	C4.5,11.723,3.479,12,2.488,12C1.657,12,0.781,11.845,0,11.657V3.563C0.828,3.762,2.953,4.215,3.619,3.69
	c0.505-0.398-0.571-0.834-0.571-2.077C3.048,0.6,4.014,0,5.035,0C6.01,0,6.803,0.61,6.803,1.577c0,1.007-1.123,1.702-0.734,2.026
	c0.623,0.518,3.038,0.098,3.84-0.051C9.78,4.131,9.356,6.057,9.828,6.645C10.185,7.09,10.647,6,11.873,6
	C12.885,6,13.5,6.871,13.5,7.875z M12.75,7.875c0-0.579-0.292-1.125-0.877-1.125c-0.793,0-1.133,0.75-1.816,0.75
	c-1.292,0-1.288-1.751-1.098-3.023C7.614,4.66,5.25,4.822,5.25,3.467c0-0.934,0.803-1.2,0.803-1.889
	c0-0.611-0.548-0.827-1.018-0.827c-0.576,0-1.237,0.285-1.237,0.863C3.798,2.402,4.5,2.74,4.5,3.409c0,1.515-2.267,1.363-3.75,1.09
	v6.559c0.797,0.157,3,0.463,3-0.326c0-0.084-0.105-0.224-0.215-0.371C2.44,8.902,3.094,7.5,4.851,7.5C5.969,7.5,6.75,8.117,6.75,9
	c0,1.038-1.188,1.609-0.717,2.026c0.42,0.371,2.075,0.066,2.928-0.014C8.713,9.773,8.743,8.25,9.999,8.25
	c0.941,0,1.207,0.794,1.91,0.794C12.258,9.044,12.75,8.682,12.75,7.875z"/>
</svg>
</template>
