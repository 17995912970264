<template>
    <div :class="classList" @click="onClick">
        <div class="part-modifier-field">
            <div class="part-modifier-content">
                <div class="part-modifier-image">
                    <Thumbnail />
                </div>
                <div class="part-modifier-details">
                    <span class="part-modifier-name">{{ part.name }}</span>
                    <div class="part-modifier-meta">
                        <span class="part-modifier-identifier"
                            >P/N #{{ part.identifier }}</span
                        >
                        <span class="part-modifier-notice" v-if="showNotice">
                            <span>
                                <component :is="noticeIcon" /> </span
                            >{{ notice }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="part-modifier-controls">
                <div class="operators" v-if="showOperators">
                    <button
                        v-if="editable"
                        @click="decrement"
                        class="decrementer"
                    >
                        <MinusIcon />
                    </button>
                    <input
                        :disabled="!editable"
                        type="number"
                        v-model="count"
                    />
                    <button v-if="editable" @click="increment">
                        <PlusIcon />
                    </button>
                </div>
                <button @click="revealOperators">
                    <component :is="isDirtyIcon" />
                </button>
            </div>
        </div>
        <div v-if="error" class="part-modifier-error">
            <p>{{ error }}</p>
        </div>
    </div>
</template>
<script>
import { part } from "@/Mixins";
import Thumbnail from "@/Components/Thumbnail";
import TachometerIcon from "@/Icons/TachometerIcon";
import PlusIcon from "@/Icons/PlusIcon";
import CheckIcon from "@/Icons/CheckIcon";
import MinusIcon from "@/Icons/MinusIcon";
import ExclamationIcon from "@/Icons/ExclamationIcon";

export default {
    mixins: [part],
    components: {
        Thumbnail,
        PlusIcon,
        MinusIcon,
        CheckIcon,
        ExclamationIcon,
        TachometerIcon,
    },
    props: {
        error: {
            type: String,
            default: "",
        },
        startDirty: {
            type: Boolean,
            default: false,
        },
        editable: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            count: this.part.quantity_on_job,
            dirty: this.part.quantity_on_job ?? this.startDirty,
        };
    },
    computed: {
        classList() {
            const classes = ["part-modifier"];

            if (this.count) {
                classes.push("part-modifier-active");
            }

            return classes.join(" ");
        },
        isDirtyIcon() {
            return this.dirty ? "CheckIcon" : "PlusIcon";
        },
        showOperators() {
            return this.dirty;
        },
        showNotice() {
            return (
                this.part.quantity_in_workshop <= this.part.low_stock_threshold
            );
        },
        noticeIcon() {
            return this.part.quantity_in_workshop === 0
                ? "ExclamationIcon"
                : "TachometerIcon";
        },
        notice() {
            if (this.part.quantity_in_workshop === 0) {
                return "Out of stock";
            }

            if (this.part.quantity_in_workshop <= this.part.low_stock_threshold) {
                return `${this.part.quantity_in_workshop} in stock`;
            }
        },
    },
    watch: {
        count() {
            if (this.count === 0) {
                this.dirty = false;
            }

            if(this.part.quantity_in_workshop - (this.count - this.part.quantity_on_job) < 0) {
                this.count = this.part.quantity_on_job
            }

            this.$emit("update", { ...this.part, count: this.count });
        },
    },
    methods: {
        revealOperators() {
            if (!this.dirty) {
                this.increment();
            }
        },
        increment() {

            const next = this.count + 1

            if (!this.dirty) {
                this.dirty = true;
            }

            if (next < 0) return;

            if (this.part.quantity_in_workshop === 0) return;

            this.count = next;
        },
        decrement() {
            if (this.count - 1 < 0) return;

            this.count--;
        },
        onClick(e) {
            if (e.target.classList.contains("decrementer")) {
                return;
            }

            if (e.target.classList.contains("minus")) {
                return;
            }

            if (!this.dirty) {
                this.increment();
            }
        },
    },
};
</script>
<style scoped>
.part-modifier {
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: space-between; */
    padding: 10px;
    border-radius: var(--default-border-radius);
    background-color: rgba(var(--default-light-rgb), 0.85);
}

.part-modifier-field {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.part-modifier-error {
    font-size: 14px;
    margin-top: 10px;
    color: var(--default-error);
}

.part-modifier-active {
    background-color: rgba(var(--default-light-rgb), 1);
}

.part-modifier + .part-modifier {
    margin-top: 2px;
}

.part-modifier-content {
    display: flex;
    align-items: center;
}

.part-modifier-image {
    width: 48px;
    margin-right: 10px;
}

.part-modifier-image .thumbnail svg {
    width: 10px;
}

.part-modifier-details {
    display: flex;
    flex-direction: column;
}

.part-modifier-name {
    font-weight: 700;
    color: var(--default-contrast);
}

.part-modifier-meta {
    display: flex;
    align-items: center;
}

.part-modifier-identifier {
    font-size: 10px;
    color: var(--default-contrast);
}

.part-modifier-notice {
    display: flex;
    align-items: center;
    color: var(--default-error);
    text-transform: uppercase;
    font-size: 11px;
    margin-left: 20px;
}

.part-modifier-notice span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
    margin-right: 10px;
}

.part-modifier-notice svg {
    max-width: 100%;
    max-height: 100%;
}

.part-modifier-controls {
    display: flex;
    align-items: center;
    justify-content: center;
}

.part-modifier-controls .operators {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 11px;
    margin-right: 30px;
    border-radius: var(--default-border-radius);
    background-color: var(--default-white);
}

.part-modifier-controls input {
    background-color: transparent;
    text-align: center;
    width: 4em;
    margin: 0 5px;
    border: none;
}

.part-modifier-controls input::-webkit-outer-spin-button,
.part-modifier-controls input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.part-modifier-controls button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    color: rgba(var(--default-contrast-rgb), 0.5);
    border: 1px solid currentColor;
}

.part-modifier-controls button svg {
    width: 60%;
}
</style>
