
export default {
    props: {
        part: {
            type: Object,
            default: {}
        }
    },
    computed: {
        link() {
            return route('parts.show', this.part.id)
        }
    }
}
