<template>
<svg viewBox="0 0 10 8">
<path fill="currentColor" d="M10,5.75v0.5C10,6.387,9.888,6.5,9.75,6.5H9C9,7.328,8.328,8,7.5,8S6,7.328,6,6.5H4C4,7.328,3.328,8,2.5,8
	S1,7.328,1,6.5v-2h2.375C3.444,4.5,3.5,4.444,3.5,4.375v-0.25C3.5,4.056,3.444,4,3.375,4h-3.25C0.056,4,0,3.944,0,3.875v-0.25
	C0,3.556,0.056,3.5,0.125,3.5h3.75C3.944,3.5,4,3.444,4,3.375v-0.25C4,3.056,3.944,3,3.875,3h-3.25C0.556,3,0.5,2.944,0.5,2.875
	v-0.25C0.5,2.556,0.556,2.5,0.625,2.5h3.75C4.444,2.5,4.5,2.444,4.5,2.375v-0.25C4.5,2.056,4.444,2,4.375,2h-4.25
	C0.056,2,0,1.944,0,1.875v-0.25C0,1.556,0.056,1.5,0.125,1.5H1V0.75C1,0.336,1.336,0,1.75,0h4C6.164,0,6.5,0.336,6.5,0.75V1.5h0.689
	c0.198,0,0.389,0.08,0.53,0.22L9.28,3.281C9.42,3.422,9.5,3.613,9.5,3.811V5.5h0.25C9.888,5.5,10,5.613,10,5.75z M3.25,6.5
	c0-0.414-0.336-0.75-0.75-0.75S1.75,6.086,1.75,6.5S2.086,7.25,2.5,7.25S3.25,6.914,3.25,6.5z M8.75,3.811L7.189,2.25H6.5V4h2.25
	V3.811z M8.25,6.5c0-0.414-0.336-0.75-0.75-0.75S6.75,6.086,6.75,6.5S7.086,7.25,7.5,7.25S8.25,6.914,8.25,6.5z"/>
</svg>
</template>
