<template>
<svg viewBox="0 0 10.5 12">
<path fill="currentColor" d="M10.5,1.688v0.375c0,0.103-0.084,0.188-0.188,0.188H9.75v8.625C9.75,11.496,9.246,12,8.625,12h-6.75
	c-0.621,0-1.125-0.504-1.125-1.125V2.25H0.188C0.084,2.25,0,2.166,0,2.062V1.688C0,1.584,0.084,1.5,0.188,1.5h2.438l0.787-1.05
	C3.618,0.176,3.944,0,4.312,0h1.875c0.368,0,0.695,0.176,0.9,0.45L7.875,1.5h2.438C10.416,1.5,10.5,1.584,10.5,1.688z M9,2.25H1.5
	v8.625c0,0.207,0.168,0.375,0.375,0.375h6.75C8.832,11.25,9,11.082,9,10.875V2.25z M3,9.938V3.562c0-0.103,0.084-0.188,0.188-0.188
	h0.375c0.103,0,0.188,0.084,0.188,0.188v6.375c0,0.103-0.084,0.188-0.188,0.188H3.188C3.084,10.125,3,10.041,3,9.938z M3.562,1.5
	h3.375l-0.45-0.6c-0.069-0.091-0.177-0.15-0.3-0.15H4.312c-0.123,0-0.231,0.059-0.3,0.15L3.562,1.5z M4.875,9.938V3.562
	c0-0.103,0.084-0.188,0.188-0.188h0.375c0.103,0,0.188,0.084,0.188,0.188v6.375c0,0.103-0.084,0.188-0.188,0.188H5.062
	C4.959,10.125,4.875,10.041,4.875,9.938z M6.75,9.938V3.562c0-0.103,0.084-0.188,0.188-0.188h0.375c0.103,0,0.188,0.084,0.188,0.188
	v6.375c0,0.103-0.084,0.188-0.188,0.188H6.938C6.834,10.125,6.75,10.041,6.75,9.938z"/>
</svg>
</template>
