<template>
    <div v-if="notifications" class="notifications">
        <div
            :class="`notification hidden ${n.class ?? ''}`"
            ref="notifications"
            v-for="(n, i) in notifications"
        >
            <div class="notification-actions"></div>
            <div class="notification-message">
                <span>{{ n.message }}</span>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            timeout: {
                type: Number,
                default: 500
            },
            notifications: {
                type: Array,
                default: () => []
            }
        },
        watch: {
            notifications() {
                this.showNotifications()
            }
        },
        methods: {
            showNotifications() {
                // This is really bad and should be refactored
                if(!this.$refs.notifications) {
                    setTimeout(() => { this.showNotifications() }, 100)
                } else {
                    let n = this.$refs.notifications

                    if(n) {
                        n.reverse()

                        this.$gsap.fromTo(n, {
                            opacity: 0,
                            translateX: '0',
                            translateY: '-100%'
                        }, {
                            opacity: 1,
                            duration: .5,
                            translateY: '0',
                            delay: .5,
                            ease: "elastic.out(1.25, 0.75)",
                            stagger: {
                                each: .2
                            },
                            onComplete: () => {
                                setTimeout(() => {
                                    this.hideNotifications()
                                }, this.timeout)
                            }
                        })
                    }
                }
            },
            hideNotifications() {

                let n = this.$refs.notifications

                this.$gsap.to(n, {
                    opacity: 0,
                    duration: .75,
                    translateY: '0',
                    translateX: '100%',
                    delay: .5,
                    ease: "elastic.in(.75, 0.75)",
                    stagger: {
                        each: .2
                    },
                    onComplete: () => {
                    }
                })
            }
        }
    }
</script>
<style>

    .notifications {
        position: absolute;
        top: 0; right: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        overflow: hidden;
        padding: 25px;
        pointer-events: none;
    }

    .notifications .notification {
        transition: transform 50ms ease-in-out;
        display: inline-flex;
        padding: 15px;
        color: var(--default-white);
        background: var(--default-secondary);
        border-radius: var(--default-border-radius);
        box-shadow: 0px 2px 6px rgba(var(--default-contrast-rgb), 0.01),
                    0px 4px 8px rgba(var(--default-contrast-rgb), 0.01),
                    0px 6px 10px rgba(var(--default-contrast-rgb), 0.01);
    }

    .notifications .notification.hidden {
        opacity: 0;
    }

    .notifications .notification + .notification {
        margin-top: 15px;
    }
</style>
