
export default {
    props: {
        machines: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        getMachineLink: id => route('machines.show', id)
    }
}
