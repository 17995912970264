<template>
    <svg id="icon-sort" viewBox="0 0 16 28">
        <path :class="asc" fill="currentColor" d="M16 11c0 0.547-0.453 1-1 1h-14c-0.547 0-1-0.453-1-1 0-0.266 0.109-0.516 0.297-0.703l7-7c0.187-0.187 0.438-0.297 0.703-0.297s0.516 0.109 0.703 0.297l7 7c0.187 0.187 0.297 0.438 0.297 0.703z"></path>
        <path :class="des" fill="currentColor" d="M16 17c0 0.266-0.109 0.516-0.297 0.703l-7 7c-0.187 0.187-0.438 0.297-0.703 0.297s-0.516-0.109-0.703-0.297l-7-7c-0.187-0.187-0.297-0.438-0.297-0.703 0-0.547 0.453-1 1-1h14c0.547 0 1 0.453 1 1z"></path>
    </svg>
</template>
<script>
    export default {
        props:{
            sort: {
                type: String,
                default: ''
            }
        },
        computed: {
            asc() {
                return this.sort === 'asc' ? 'active' : 'inactive'
            },
            des() {
                return this.sort === 'desc' ? 'active' : 'inactive'
            }
        }
    }
</script>
<style scoped>

    .inactive {
        opacity: .3;
    }

    .active {
        opacity: 1;
    }

</style>
