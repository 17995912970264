<template>
    <Constrained>
        <Titlebar title="New Part" />

        <Field label="Name">
            <input v-model="form.name" type="text" />
            <p class="error" v-if="$attrs.errors.name">{{ $attrs.errors.name}}</p>
        </Field>

        <Field label="Indentifier">
            <input id="identifier" type="text" v-model="form.identifier">
            <p class="error" v-if="$attrs.errors.identifier">{{ $attrs.errors.identifier }}</p>
        </Field>

<!--        <Field label="Machine">
            <multiselect
                id="machinetype"
                placeholder="Select a Machine Type"
                v-model="form.machine_type_id"
                label="name"
                valueProp="id"
                :options="machineTypes"
            ></multiselect>
            <p class="error" v-if="$attrs.errors.machine_type_id">{{ $attrs.errors.machine_type_id }}</p>
        </Field>-->

        <Field label="Categories">
            <multiselect
                id="categories"
                placeholder="Which categories does this part belong to"
                label="name"
                mode="tags"
                valueProp="id"
                v-model="form.part_categories"
                :options="partCategories"
            ></multiselect>
            <p v-if="$attrs.errors.part_categories">{{ $attrs.errors.part_categories }}</p>
        </Field>

        <Field label="Related models">
            <multiselect
                id="models"
                placeholder="Which models does this part relate to"
                mode="tags"
                label="name"
                valueProp="id"
                v-model="form.part_models"
                :options="machineModels"
            ></multiselect>
            <p v-if="$attrs.errors.part_models">{{ $attrs.errors.part_models }}</p>
        </Field>

        <Field label="Related parts">
            <multiselect
                id="relations"
                placeholder="Which parts does this part relate to"
                mode="tags"
                label="name"
                valueProp="id"
                v-model="form.part_relations"
                :searchable="true"
                :options="parts"
            ></multiselect>
            <p v-if="$attrs.errors.part_relations">{{ $attrs.errors.part_relations }}</p>
        </Field>

        <Field label="On Order">
            <input id="on_order" type="text" v-model="form.on_order">
            <p class="error" v-if="$attrs.errors.on_order">{{ $attrs.errors.on_order }}</p>
        </Field>

        <template #actions>
            <button
                @click="setAction(action)"
                v-for="action in actions"
                class="button-primary"
            >
            {{ action.text }}
            </button>
        </template>
    </Constrained>
</template>

<script>
    import {
        form,
        machineTypes,
        machineModels,
        partCategories,
        parts,
    } from '@/Mixins';

    export default {
        mixins: [form, machineTypes, machineModels, partCategories, parts],
        data() {
            return {
                form: {
                    name: null,
                    identifier: null,
                    on_order: null,
                    machine_type_id: null,
                    part_relations: [],
                    part_models: [],
                    part_categories: [],
                }
            }
        },
    }
</script>

