<template>
    <div class="login-screen">
        <div class="login-sidebar">
            <UserSwitcher
                :users="users"
                @update="updateUser"
                ref="userSwitcher"
            />
        </div>
        <div class="login-main">
            <div class="app-title">
                <Logo />
                Service Repair App
            </div>
            <p
            class="keypad-message"
            v-if="keypadMessage">
                {{ keypadMessage }}
            </p>
            <div :class="keypadClassList">
                <Keypad
                    ref="keypad"
                    @update="updatePin"
                    @clear="clear"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { users } from '@/Mixins'
    import { useForm } from '@inertiajs/inertia-vue3'
    import Logo from '@/Components/Logo'
    import Keypad from '@/Components/Keypad'
    import UserSwitcher from '@/Components/UserSwitcher'

    export default {
        mixins: [users],
        components: {
            UserSwitcher,
            Logo,
            Keypad,
        },
        data() {
            return {
                keypadMessage: null,
                pin: [],
                form: useForm({
                    email: null,
                    password: null
                })
            }
        },
        watch: {
            pin() {
                this.form.password = (this.pin.length === 4) ? this.pin : null
            },
            '$attrs.errors.authentication'() {
                this.keypadMessage = this.$attrs.errors.authentication
            },
            'form.password'() {
                if(this.form.email && this.pin.length === 4) {
                    this.submit()
                } else if (this.pin.length === 4) {
                    this.keypadMessage = 'No user selected'
                }
            }
        },
        computed: {
           keypadClassList() {
               const classes = ['keypad-wrapper']
               if(!this.form.email) {
                   classes.push('disabled')
               }
               return classes.join(' ')
           }
        },
        methods: {
            clear() {
                //this.$refs.userSwitcher.reset()
                this.$refs.keypad.reset()
                this.form.password = ''
                /* this.form = useForm({
                     email: null,
                     password: null
                 })*/
                this.pin = ''
                this.$attrs.errors.authentication = null
            },
            updateUser(value) {
                this.$attrs.errors.authentication = null
                this.$refs.keypad.reset()

                this.form.password = ''
                this.pin = ''

                this.form.email = value
            },
            updatePin(value) {
                this.pin = value
            },
            submit() {
                this.form = useForm(this.form)
                this.form.post(route('authenticate'), {
                    preserveScroll: true,
                    onSuccess: () => {
                        this.pin = [];
                        this.form.reset()
                    },
                    onError: () => {
                        this.pin = [];
                        this.$refs.keypad.reset()
                        // this.clear()
                    }
                })
            }
        }
    }
</script>
<style>

    .login-screen {
        display: flex;
        height: 100vh;
        overflow: hidden;
        background-color: var(--default-contrast);
    }

    .login-sidebar {
        width: var(--sidebar-width);
        background-color: rgba(0, 0, 0, .15);
        overflow-y: scroll;
        scrollbar-color: var(--default-contrast) var(--default-contrast);
    }

    .login-sidebar::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }

    .login-sidebar::-webkit-scrollbar-thumb {
        background-color: var(--default-contrast);
        border-radius: 10px;
    }

    .login-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-grow: 1;
        padding: 60px 0;
    }

    .login-main .app-title {
        display: flex;
        align-items: center;
        flex-direction: column;
        color: var(--default-white);
        font-weight: 900;
    }

    .login-main .app-title svg {
        width: 102px;
        height: auto;
        transform: rotate(0deg);
        margin-bottom: 1em;
        color: currentColor;
    }

    .login-main .keypad-message {
        margin-top: 20px;
        background-color: var(--default-primary);
        color: var(--default-white);
        border-radius: var(--default-border-radius);
        padding: 1em 2em;
    }

    .login-main .keypad-wrapper {
        display: flex;
        align-items: center;
    }

    .login-main .keypad-wrapper.disabled {
        filter: blur(2px);
        pointer-events: none;
    }

</style>
