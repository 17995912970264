<template>
    <div :class="classList">
        <div class="user-cell-image">
            <UserIcon />
        </div>
        <div class="user-cell-data">
            <span class="user-cell-title">{{ name }}</span>
        </div>
    </div>
</template>
<script>

import UserIcon from '@/Icons/UserIcon'

export default {
    components: {
        UserIcon,
    },
    props: {
        extra: {
            type: Object,
            default: {}
        },
        value: {
            type: Object,
            required: true,
            default: {}
        }
    },
    data() {
        return {
            ...this.value,
            ...this.extra
        }
    },
    computed: {
        classList() {
            const list = ['user-cell']

            if(this.suspended) {
                list.push('user-suspended')
            }

            return  list.join(' ')
        }
    }
}
</script>
<style scoped>

    .user-cell {
        display: flex;
        align-items: center;
    }

    .user-cell.priority {
        color: var(--default-primary);
    }

    .user-cell-image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        margin-right: 20px;
        border-radius: 50%;
        background-color: var(--default-secondary);
    }

    .user-suspended .user-cell-image {
        background-color: var(--default-error);
    }

    .user-cell svg {
        height: 55%;
        color: var(--default-white);
    }

    .user-cell span {
    }

    .user-cell-data {
        display: flex;
        flex-direction: column;
    }

    .user-cell-title {
        font-weight: 900;
    }

    .user-cell-meta {
        display: flex;
        align-items: center;
        margin-bottom: .5em;
        font-size: 9px;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 0.125em;
    }

    .user-cell-meta svg {
        width: 1.5em;
        color: inherit;
        margin-right: .5em;
    }

</style>
