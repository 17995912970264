<template>
    <svg class="icon-machine" viewBox="0 0 17 17">
        <path d="M13.051,3.322c-1.248-1.283-2.94-2.003-4.705-2.003c-1.412,0-2.766,0.576-3.764,1.603
            C3.583,3.948,3.022,5.34,3.022,6.791c0,1.452,0.561,2.844,1.559,3.87c0.998,1.026,2.352,1.603,3.764,1.603
            c1.427,0,2.745-0.782,3.458-2.052c0.713-1.27,0.713-2.835,0-4.104c-0.659-1.173-1.835-1.931-3.135-2.039
            C7.761,3.993,6.857,4.335,6.283,5.061C5.575,5.956,5.494,7.187,6.04,8.159c0.424,0.755,1.168,1.251,1.997,1.35
            c0.147,0.018,0.278-0.098,0.282-0.25l0.022-0.853C8.345,8.273,8.248,8.162,8.12,8.143c-0.15-0.023-0.295-0.07-0.426-0.15
            C6.919,7.516,6.807,6.504,7.358,5.874C7.63,5.561,8.035,5.41,8.443,5.425c0.913,0.034,1.748,0.549,2.208,1.366
            c0.475,0.847,0.475,1.89,0,2.736c-0.476,0.847-1.354,1.368-2.305,1.368c-1.426,0-2.744-0.782-3.458-2.052
            c-0.821-1.463-0.697-3.316,0.374-4.66c0.858-1.077,2.201-1.597,3.55-1.475c1.241,0.112,2.41,0.669,3.298,1.582
            c0.998,1.026,1.559,2.418,1.559,3.87s-0.561,2.843-1.559,3.87c-0.999,1.026-2.353,1.603-3.765,1.603
            c-1.168,0-2.316-0.316-3.327-0.917c-1.012-0.6-1.852-1.464-2.436-2.504c-0.584-1.04-0.892-2.22-0.892-3.42V6.466L1.786,6.48
            C1.927,6.5,2.058,6.4,2.078,6.255l0.2-1.446C2.283,4.77,2.297,4.733,2.318,4.7l1.475-2.287c0.021-0.033,0.035-0.07,0.04-0.109
            l0.276-2.001c0.033-0.24-0.239-0.396-0.42-0.24L0.505,2.797c-0.049,0.042-0.081,0.101-0.09,0.166L0.003,5.952
            c-0.02,0.145,0.078,0.279,0.219,0.299l0.139,0.02v0.52c0,2.177,0.841,4.265,2.339,5.805C4.197,14.135,6.228,15,8.346,15h0
            c1.765,0,3.457-0.721,4.705-2.004C14.299,11.714,15,9.973,15,8.159C15,6.345,14.299,4.605,13.051,3.322" fill="currentColor"/>
    </svg>
</template>
