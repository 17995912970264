<template>
    <div class="thumbnail">
        <div class="thumbnail-spacer"></div>
        <div class="thumbnail-content">
            <CameraIcon />
        </div>
    </div>
</template>
<script>

    import CameraIcon from '@/Icons/CameraIcon'

    export default {
        components: {
            CameraIcon
        },
        props: {
            image: {
                type: Object,
                default: undefined
            }
        }
    }
</script>
<style scoped>


    .card-image .thumbnail {
        width: 96px;
        margin: 0 auto;
    }

    .thumbnail {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .thumbnail-spacer {
        width: 100%;
        height: 0;
        padding-bottom: 100%;
    }

    .thumbnail-content {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: rgba(var(--default-contrast-rgb), .15);
        border-radius: var(--default-border-radius)
    }

    .thumbnail svg {
        width: 25%;
        color: var(--default-white);
    }
</style>
