<template>
    <Layout>
        <template #sidebar>
            <MachineCard :machine="job.machine" :showLink="true" />
            <JobTracker :steps="steps" />
        </template>
        <Titlebar title="Testing" />

        <div class="fields-container">
            <Field label="Vaccum pressure">
                <div class="field-short">
                    <input
                        v-model="form.vaccum_pressure"
                        :disabled="!editable"
                        type="number"
                    />
                </div>
                <p class="error" v-if="$attrs.errors.vaccum_pressure">{{ $attrs.errors.vaccum_pressure }}</p>
            </Field>
            <Field label="Pump pressure">
                <div class="field-short">
                    <input
                        v-model="form.pump_pressure"
                        :disabled="!editable"
                        type="number"
                    />
                </div>
                <p class="error" v-if="$attrs.errors.pump_pressure">{{ $attrs.errors.pump_pressure }}</p>
            </Field>
        </div>
        <div class="fields-container">
            <Field label="Continuity / Earth">
                <div class="field-short field-has-icon">
                    <input
                        v-model="form.insulation"
                        :disabled="!editable"
                        type="number"
                        @input="insulation"
                    />
                    <div class="field-icon">&#x2126;</div>
                </div>
                <p class="error" v-if="$attrs.errors.insulation">{{ $attrs.errors.insulation }}</p>
            </Field>
            <Field label="Insulation MΩ">
                <div class="field-short field-has-icon">
                    <input
                        v-model="form.insulation_mega"
                        :disabled="!editable"
                        type="number"
                        @input="insulation"
                    />
                    <div class="field-icon">M&#x2126;</div>
                </div>
                <p class="error" v-if="$attrs.errors.insulation_mega">{{ $attrs.errors.insulation_mega }}</p>
            </Field>
        </div>

        <Checklist
            :key="checklist"
            v-for="checklist in checklists"
            :checklist="checklist"
        />

        <template #actions>
            <FormActions
                :actions="actions"
                :setAction="setAction"
                :submit="submit"
            />
        </template>
    </Layout>
</template>

<script>
    import { job, form, checklists, editable } from '@/Mixins'
    import MachineCard from '@Cards/MachineCard'
    import JobTracker from '@/Components/JobTracker'
    import { debounce } from '@/Utils/debounce'

    export default {
        mixins: [job, form, checklists, editable],
        components: {
            MachineCard,
            JobTracker,
        },
        data() {
            return {
                form: {
                    insulation: this.job.insulation,
                    insulation_mega: this.job.insulation_mega,
                    pump_pressure: this.job.pump_pressure,
                    vaccum_pressure: this.job.vaccum_pressure
                }
            }
        },
        created() {
            this.debouncedSave = debounce((newValue, oldValue) => {
                if(newValue !== oldValue) {
                    this.$inertia.post(route('jobs.update.testing', this.job.id), this.form)
                }
            }, 500)
        },
        watch: {
            'form.insulation'(...args) {
                this.debouncedSave(...args)
            },
            'form.insulation_mega'(...args) {
                this.debouncedSave(...args)
            },
            'form.pump_pressure'(...args) {
                this.debouncedSave(...args)
            },
            'form.vaccum_pressure'(...args) {
                this.debouncedSave(...args)
            }
        }
    }
</script>
