<template>
    <Layout>
        <template #sidebar>
            <MachineCard
                :machine="machine"
                :showTable="true"
            />
        </template>
        <Titlebar title="Service Record" :links="links" />
        <Table v-if="table.content.total" :table="table" :row_action="showHistory" />
        <p v-else>This machine has no service record.</p>
    </Layout>
</template>

<script>
    import { machine, table, links } from '@/Mixins'
    import MachineCard from '@Cards/MachineCard'
    import Table from '@/Components/Table'

    export default {
        mixins: [machine, table, links],
        components: {
            MachineCard,
            Table
        },
        methods: {
            showHistory(id) {
                return this.$inertia.visit(route('jobs.history', id))
            }
        }
    }
</script>
