<template>
    <Layout>
        <template #sidebar>
            <MachineCard :machine="machine" :showTable="true" />
        </template>

        <Titlebar title="Job Record" :links="links">
            <div class="job-date">{{ job.updated_at }}</div>
            <div :class="jobStateClass">{{ job.statusText }}</div>
        </Titlebar>

        <p class="job-service-comment" v-if="job.notes"><MessageIcon /> {{ job.notes }}</p>

        <div class="content-block">
            <h3>Milestones</h3>
            <div class="milestone-table">
                <div
                    :key="history"
                    v-for="history in simplifiedHistory"
                    class="milestone-row content-block-section"
                >
                    <div class="milestone-action">
                        <p>{{ history.milestone.text }}</p>
                    </div>
                    <div class="milestone-user">
                        <p>{{ history.milestone.user }}</p>
                    </div>
                    <div class="milestone-date">
                        <p>{{ history.milestone.date }}</p>
                    </div>
                </div>
                <div
                    class="milestone-row content-block-section"
                >
                    <div class="milestone-action">
                        <p>Repair Time</p>
                    </div>
                    <div class="milestone-user">
                    </div>
                    <div class="milestone-date">
                        <p>{{ job.turnaround }}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-block">
            <h3>Internal Notes</h3>
            <div class="content-block-section">
            <p>{{ job.special_request }}</p>
            </div>
        </div>

        <div class="content-block" v-if="job.grooming_notes">
            <h3>Grooming Notes</h3>
            <div class="content-block-section">
            <p>{{ job.grooming_notes }}</p>
            </div>
        </div>

        <div class="content-block">
            <h3>Reported Fault</h3>
            <div class="content-block-section">
                <p>{{ job.fault_report }}</p>
            </div>
            <div class="content-block-section content-space-between">
                <p>Store</p>
                <p>{{ job.store.name }}</p>
            </div>
        </div>

        <div class="content-block">
            <h3>Workshop Comments</h3>
            <div class="content-block-section" v-if="job.service_comment">
                <p>{{ job.service_comment }}</p>
            </div>
            <div class="content-block-split-section">
                <div class="content-block-section">
                    <div class="content-space-between">
                        <p>Workshop hours</p>
                        <p v-if="job.workshopHours">{{ job.workshopHours }}</p>
                        <p v-else>-</p>
                    </div>
                </div>
                <div class="content-block-section">
                    <div class="content-space-between">
                        <p>Vac.</p>
                        <p v-if="job.vaccum_pressure">{{ job.vaccum_pressure }}</p>
                        <p v-else>-</p>
                    </div>
                </div>
            </div>
            <div class="content-block-split-section">
                <div class="content-block-section">
                    <div class="content-space-between">
                        <p>Pump</p>
                        <p v-if="job.pump_pressure">{{ job.pump_pressure }}</p>
                        <p v-else>-</p>
                    </div>
                </div>
                <div class="content-block-section">
                    <div class="content-space-between">
                        <p>Insul.</p>
                        <p v-if="job.insulation || job.insulation_mega">{{ job.insulation }} Ω / {{ job.insulation_mega }} MΩ</p>
                        <p v-else>-</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-block-parts" v-if="job.parts.length">
            <h3>Parts used</h3>
            <PartModifier
                :key="part.id"
                v-for="part in job.parts"
                :editable="false"
                :startDirty="true"
                :part="part"
            />
        </div>
        <div class="content-block-parts" v-else>
            <h3>Parts used</h3>
            <p>No parts where used on this job</p>
        </div>

        <template #actions>
            <FormActions :actions="actions" :setAction="setAction" :submit="submit" />
        </template>

    </Layout>
</template>

<script>
    import { job, machine, form, links } from '@/Mixins'
    import MessageIcon from '@/Icons/MessageIcon'
    import MachineCard from '@Cards/MachineCard'
    import JobTracker from '@/Components/JobTracker'
    import PartModifier from '@/Components/PartModifier'

    export default {
        mixins: [job, machine, form, links],
        components: {
            JobTracker,
            MachineCard,
            MessageIcon,
            PartModifier
        },
        computed: {
            jobStateClass() {

                if(this.job.isInactive) {
                    return 'pill-grey'
                }

                if(this.job.status === 14) {
                    return 'pill-secondary'
                }

                return 'pill-primary'
            },
            simplifiedHistory() {
                let list = [];
                let statues = [
                    1, 3, 5, 7, 9, 11, 12, 13, 14
                ];

                this.job.history.forEach(history => {
                    let current = list.find(item => item.status == history.status);
                    if(!current && statues.indexOf(history.status) > -1) {
                        list.push(history)
                    }
                    //return statues.indexOf(history.status) > -1
                })

                return list;
            }
        }
    }
</script>

<style scoped>

    .info-split {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 15px;
    }

    .content-block-parts {
        margin-top: 30px;
    }

    .job-date {
        margin-right: 1em;
    }

    .job-service-comment {
        margin-bottom: 20px;
        word-wrap: break-word;
    }

    .job-service-comment svg {
        width: 1em;
        margin-right: 10px;
    }

    .milestone-row {
        display: flex;
    }

    .milestone-action {
        flex: 0 0 60%;
    }

    .milestone-user {
        flex: 0 0 20%;
    }

    .milestone-date {
        display: flex;
        justify-content: flex-end;
        flex: 0 0 20%;
    }

</style>
