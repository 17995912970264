
export default {
    props: {
        parts: {
            type: Object,
            default: () => []
        }
    },
    methods: {
        getPartLink: id => route('parts.show', id),
    },
}
