<template>
<svg viewBox="0 0 12 10.5">
<path fill="currentColor" d="M7.219,5.25C7.373,5.25,7.5,5.123,7.5,4.969V4.781C7.5,4.627,7.373,4.5,7.219,4.5H4.781
	C4.627,4.5,4.5,4.627,4.5,4.781v0.188c0,0.155,0.127,0.281,0.281,0.281H7.219z M10.125,9.75h-8.25C1.669,9.75,1.5,9.581,1.5,9.375V3
	h9v6.375C10.5,9.581,10.331,9.75,10.125,9.75z M11.25,2.25H0.75V1.125c0-0.206,0.169-0.375,0.375-0.375h9.75
	c0.206,0,0.375,0.169,0.375,0.375V2.25z M10.125,10.5c0.621,0,1.125-0.504,1.125-1.125V3h0.375C11.831,3,12,2.831,12,2.625v-1.5
	C12,0.504,11.496,0,10.875,0h-9.75C0.504,0,0,0.504,0,1.125v1.5C0,2.831,0.169,3,0.375,3H0.75v6.375
	c0,0.621,0.504,1.125,1.125,1.125H10.125z"/>
<path fill="currentColor" d="M7.219,5.25C7.373,5.25,7.5,5.123,7.5,4.969V4.781C7.5,4.627,7.373,4.5,7.219,4.5H4.781
	C4.627,4.5,4.5,4.627,4.5,4.781v0.188c0,0.155,0.127,0.281,0.281,0.281H7.219z M10.125,9.75h-8.25C1.669,9.75,1.5,9.581,1.5,9.375V3
	h9v6.375C10.5,9.581,10.331,9.75,10.125,9.75z M11.25,2.25H0.75V1.125c0-0.206,0.169-0.375,0.375-0.375h9.75
	c0.206,0,0.375,0.169,0.375,0.375V2.25z M10.125,10.5c0.621,0,1.125-0.504,1.125-1.125V3h0.375C11.831,3,12,2.831,12,2.625v-1.5
	C12,0.504,11.496,0,10.875,0h-9.75C0.504,0,0,0.504,0,1.125v1.5C0,2.831,0.169,3,0.375,3H0.75v6.375
	c0,0.621,0.504,1.125,1.125,1.125H10.125z"/>
</svg>
</template>
