
export default {
    props: {
        job: {
            type: Object,
            default: {}
        },
        steps: {
            type: Array,
            default: () => {}
        }
    },
    computed: {
        link() {
            return route('jobs.show', this.job.id)
        },
    }
}
