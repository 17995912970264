<template>
    <div class="checklist">
        <h3>{{ title }}</h3>
        <label v-for="(item, i) in items" class="accordion">
            <input type="checkbox">
            <div class="accordion-index">
                <span>{{ i + 1 }}.</span>
            </div>
            <div class="accordion-main">
                <div class="accordion-title">
                    <span>{{ item.title }}</span><span class="accordion-icon"><Chevron /></span>
                </div>
                <div class="accordion-content">
                    {{ item.content }}
                </div>
            </div>
        </label>
    </div>
</template>
<script>
    import Chevron from '@/Icons/Chevron'

    export default {
        components: {
            Chevron
        },
        props: {
            checklist: {
                type: Object,
                default: {}
            }
        },
        data() {
            return {
                ...this.checklist
            }
        }
    }
</script>
<style>

    .checklist {
        margin-top: 40px;
    }

    .accordion {
        position: relative;
        display: flex;
        align-items: flex-start;
        padding: 1em .5em;
        border-top: 1px solid var(--default-light);
        cursor: pointer;
    }

    .accordion input {
        position: absolute;
        opacity: 0;
    }

    .accordion-index {
        margin-right: 1em;
    }

    .accordion-main {
        flex-grow: 1;
    }

    .accordion-title,
    .accordion-index {
        font-weight: bold;
        font-size: 18px;
    }

    .accordion-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .accordion-title span {
        display: block;
    }

    .accordion-icon {
        width: 24px;
    }

    .accordion-icon svg {
        max-width: inherit;
    }

    .accordion-content {
        overflow: hidden;
        max-height: 0;
        padding-top: 0;
    }

    .accordion input:checked
    ~ .accordion-main .accordion-content {
        max-height: 999px;
        padding-top: 1em;
    }

    .accordion input:checked
    ~ .accordion-main .accordion-icon {
        transform: rotate(180deg);
    }

</style>
