<template>
    <div :class="classList">
        <div class="machine-cell-image">
            <ToolIcon v-if="machineType.name == 'tool'" />
            <MachineIcon v-else-if="machineType.name == 'machine'" />
        </div>
        <div class="machine-cell-data">
            <span v-if="priority" class="machine-cell-meta">
                <DeliveryIcon /> priority
            </span>
            <span class="machine-cell-title">{{ machineTypeString }} #{{ identifier }}</span>
        </div>
    </div>
</template>
<script>

import ToolIcon from '@/Icons/ToolIcon'
import MachineIcon from '@/Icons/MachineIcon'
import DeliveryIcon from '@/Icons/DeliveryIcon'

export default {
    components: {
        ToolIcon,
        MachineIcon,
        DeliveryIcon
    },
    props: {
        extra: {
            type: Object,
            default: {}
        },
        value: {
            type: Object,
            required: true,
            default: {}
        }
    },
    data() {
        return {
            priority: false,
            ...this.value,
            ...this.extra
        }
    },
    computed: {
        classList() {
            const list = ['machine-cell']

            if(this.jobState === 'Inbound') {
                list.push('machine-cell-inbound')
            }

            if(this.jobState === 'Archived') {
                list.push('machine-cell-archived')
            }

            if(this.priority) {
                list.push('priority')
            }

            return  list.join(' ')
        },
        machineTypeString() {
            return this.machineType.nameUpper
        }
    }
}
</script>
<style scoped>

    .machine-cell {
        display: flex;
        align-items: center;
    }

    .machine-cell.priority {
        color: var(--default-primary);
    }

    .machine-cell-image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        margin-right: 20px;
        border-radius: 50%;
        background-color: var(--default-secondary);
    }

    .machine-cell-inbound .machine-cell-image {
        background-color: var(--default-contrast);
    }

    .machine-cell-archived .machine-cell-image {
        background-color: rgba(var(--default-contrast-rgb), 0.3);
    }


    .machine-cell svg {
        height: 55%;
        color: var(--default-white);
    }

    .machine-cell span {
    }

    .machine-cell-data {
        display: flex;
        flex-direction: column;
    }

    .machine-cell-title {
        font-weight: 900;
    }

    .machine-cell-meta {
        display: flex;
        align-items: center;
        margin-bottom: .5em;
        font-size: 9px;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 0.125em;
    }

    .machine-cell-meta svg {
        width: 1.5em;
        color: inherit;
        margin-right: .5em;
    }

</style>
