<template>
    <div :class="classList">
        <div class="store-cell-image">
            <MarkerIcon />
        </div>
        <div class="store-cell-data">
            <span class="store-cell-title">{{ name }} ({{ store_code }})</span>
        </div>
    </div>
</template>
<script>

export default {
    props: {
        extra: {
            type: Object,
            default: {}
        },
        value: {
            type: Object,
            required: true,
            default: {}
        }
    },
    data() {
        return {
            ...this.value,
            ...this.extra
        }
    },
    computed: {
        classList() {
            const list = ['store-cell']

            return  list.join(' ')
        },
    }
}
</script>
<style scoped>

    .store-cell {
        display: flex;
        align-items: center;
    }

    .store-cell.priority {
        color: var(--default-primary);
    }

    .store-cell-image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        margin-right: 20px;
        border-radius: 50%;
        background-color: var(--default-secondary);
    }

    .store-cell-inbound .store-cell-image {
        background-color: var(--default-contrast);
    }

    .store-cell-archived .store-cell-image {
        background-color: rgba(var(--default-contrast-rgb), 0.3);
    }


    .store-cell svg {
        height: 55%;
        color: var(--default-white);
    }

    .store-cell-data {
        display: flex;
        flex-direction: column;
    }

    .store-cell-title {
        font-weight: 900;
    }

    .store-cell-meta {
        display: flex;
        align-items: center;
        margin-bottom: .5em;
        font-size: 9px;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 0.125em;
    }

    .store-cell-meta svg {
        width: 1.5em;
        color: inherit;
        margin-right: .5em;
    }

</style>
