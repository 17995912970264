<template>
    <button
        :key="action"
        v-bind:disabled="action.disabled"
        @click="setAction(action)"
        v-for="action in actions"
        :class="getActionClassList(action)"
    >
    <span v-if="action.text && !action.disabled">{{ action.text }}</span>
    <span v-if="action.text && action.disabled">Loading</span>
    <component v-if="action.icon" :is="action.icon" />
    </button>
</template>
<script>
    export default {
        props: {
            actions: {
                type: Array,
                default: () => []
            },
            setAction: {
                type: Function,
                default: () => {}
            },
            submit: {
                type: Function,
                default: () => {}
            }
        },
        methods: {
            getActionClassList(action) {
                let classList = ['button-primary']

                if (action.type && action.type == 'secondary') {
                    classList = ['button-secondary']
                } else if (action.type && action.type == 'outline') {
                    classList = ['button-primary-outline']
                }

                if(action.icon) {
                    classList.push('button-icon')
                }

                if(action.disabled) {
                    classList.push('button-disabled')
                }

                return classList.join(' ')
            }
        }
    }
</script>
