<template>
    <Layout>
        <template #sidebar>
            <Card
                :title="part.name"
                :subtitle="`Part #${part.identifier}`"
            >
            <template #image>
                <Thumbnail />
            </template>
            </Card>
            <SidebarTable
                v-if="showTable"
                title="Usage Stats"
                :items="sidebarItems"
                :total="sidebarTotal"
            />
        </template>

        <Titlebar title="Part Record" />

        <div class="content-block">
            <div class="field-inline">
                <label>Name</label>
                <input v-model="form.name" type="text" />
                <p v-if="$attrs.errors.name">{{ $attrs.errors.name}}</p>
            </div>
            <div class="field-inline">
                <label for="identifier">Serial number:</label>
                <input id="identifier" type="text" v-model="form.identifier">
                <p v-if="$attrs.errors.identifier">{{ $attrs.errors.identifier }}</p>
            </div>
            <div class="field-inline">
                <label>Categories:</label>
                <multiselect
                    id="categories"
                    placeholder="Which categories does this part belong to"
                    label="name"
                    mode="tags"
                    valueProp="id"
                    v-model="form.part_categories"
                    :options="partCategories"
                ></multiselect>
                <p v-if="$attrs.errors.part_categories">{{ $attrs.errors.part_categories }}</p>
            </div>
            <div class="field-inline">
                <label>Related Models:</label>
                <multiselect
                    id="models"
                    placeholder="Which models does this part relate to"
                    mode="tags"
                    label="name"
                    valueProp="id"
                    v-model="form.part_models"
                    :options="machineModels"
                ></multiselect>
                <p v-if="$attrs.errors.part_models">{{ $attrs.errors.part_models }}</p>
            </div>
            <div class="field-inline">
                <label>Related Parts:</label>
                <multiselect
                    id="relations"
                    placeholder="Which parts does this part relate to"
                    mode="tags"
                    label="name"
                    valueProp="id"
                    v-model="form.part_relations"
                    :searchable="true"
                    :options="parts"
                ></multiselect>
                <p v-if="$attrs.errors.part_relations">{{ $attrs.errors.part_relations }}</p>
            </div>
            <div class="field-inline">
                <label for="identifier">On Order:</label>
                <input id="on_order" type="text" v-model="form.on_order">
                <p v-if="$attrs.errors.on_order">{{ $attrs.errors.on_order }}</p>
            </div>
        </div>

        <div class="content-block">
            <div class="table">
                <table>
                    <tr class="table-head">
                        <th>
                            <div class="table-cell">Stock levels</div>
                        </th>
                        <th>
                            <div class="table-cell cell-upper">Actual</div>
                        </th>
                        <th>
                            <div class="table-cell cell-upper">Minimum</div>
                        </th>
                    </tr>
                    <tr
                        :key="workshop"
                        v-for="(workshop, i) in workshops"
                        class="table-row"
                    >
                        <td>
                            <div class="table-cell">
                                {{ workshop.name }}
                            </div>
                        </td>
                        <td>
                            <div :class="getStockClass(form.workshops[i].pivot)">
                                <input
                                    v-model="form.workshops[i].pivot.quantity"
                                    class="stock-table-quantity"
                                />
                                <component :is="getStockIcon(form.workshops[i].pivot)" />
                            </div>
                        </td>
                        <td style="width: 50px">
                            <div class="table-cell">
                                <input
                                    v-model="form.workshops[i].pivot.low_stock_threshold"
                                    class="stock-table-quantity"
                                />
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <template #actions>
            <FormActions
                :actions="actions"
                :setAction="setAction"
                :submit="submit"
            />
        </template>
    </Layout>
</template>

<script>
    import Card from '@/Components/Card'
    import Thumbnail from '@/Components/Thumbnail'
    import SidebarTable from '@/Components/SidebarTable'
    import ExclamationIcon from '@/Icons/ExclamationIcon'
    import TachometerIcon from '@/Icons/TachometerIcon'

    import {
        form,
        machineTypes,
        machineModels,
        partCategories,
        parts,
        workshops,
        part,
    } from '@/Mixins';

    export default {
        mixins: [form, machineTypes, machineModels, partCategories, parts, part, workshops],
        components: {
            ExclamationIcon,
            TachometerIcon,
            Card,
            Thumbnail,
            SidebarTable,
        },
        props: {
            usage: {
                type: Array,
                default: () => []
            }
        },
        data() {

            const workshops = this.workshops.map(workshop => {
                const o = this.part.workshops.find(w => w.id === workshop.id)
                workshop.pivot = o ? o.pivot : { quantity: 0 };
                return workshop
            })

            return {
                sidebarItems: [],
                sidebarTotal: {
                    key: 'Total',
                    value: 0
                },
                showTable: true,
                form: {
                    name: this.part.name,
                    on_order: this.part.on_order,
                    identifier: this.part.identifier,
                    machine_type_id: this.part.machine_type_id,
                    part_relations: this.part.parts.map(p => p.id),
                    part_models: this.part.machine_models.map(m => m.id),
                    part_categories: this.part.categories.map(c => c.id),
                    workshops
                }
            }
        },
        mounted() {
            this.sidebarTotal = {
                key: 'Total',
                value: 0
            }

            this.workshops.forEach(workshop => {
                let workshopData = this.usage.find(data => {
                    return data.workshop_id == workshop.id
                })

                let total = (workshopData != undefined && workshopData.total > 0) ? workshopData.total : 0;
                this.sidebarTotal.value += Number(total)

                this.sidebarItems.push({
                    key: workshop.name,
                    value: total
                })
            })
        },
        methods: {
            getStockClass(pivot) {
                const classes = ['table-cell']

                const quantity = parseInt(pivot.quantity);
                const lowStock = parseInt(pivot.low_stock_threshold);

                if (quantity == 0) {
                    classes.push('cell-error')
                }

                if (quantity < lowStock) {
                    classes.push('cell-warning')
                }

                return classes.join(' ')
            },
            getStockIcon(pivot) {
                const quantity = parseInt(pivot.quantity);
                const lowStock = parseInt(pivot.low_stock_threshold);

                if(quantity !== 0 && quantity > lowStock) return

                if (quantity == 0) {
                    return 'ExclamationIcon'
                }

                if (quantity < lowStock) {
                    return 'TachometerIcon'
                }
            }
        }
    }
</script>

<style scoped>
    table {
        width: 100%;
        border-spacing: 0 4px;
        border-collapse: seperate;
        margin-bottom: 20px;
    }

    .table-head .cell-upper {
        text-transform: uppercase;
        font-size: 13px;
        letter-spacing: 1px;
        color: var(--default-grey)
    }

    th {
        text-align: left;
    }

    .cell-warning {
        color: var(--default-warning);
    }

    .cell-error {
        color: var(--default-error);
    }

    th:nth-of-type(2) .table-cell,
    th:nth-of-type(3) .table-cell {
        text-align: right;
        margin-right: 15px;
    }

    td {
        font-size: 14px;
        background-color: var(--default-light);
    }

    td:nth-of-type(2) {
        flex-grow: 1;
    }

    td:nth-of-type(2),
    td:nth-of-type(3) {
        width: 120px;
    }

    td:nth-of-type(2) .table-cell,
    td:nth-of-type(3) .table-cell {
        display: flex;
        align-items: center;
        margin-right: 15px;
    }

    td:nth-of-type(2) .table-cell svg,
    td:nth-of-type(3) .table-cell svg {
        height: 16px;
        max-width: 16px;
    }

    td:nth-of-type(1) .table-cell {
        padding: 15px;
    }

    input.stock-table-quantity {
        height: 35px;
        margin: 0 10px;
        border-radius: 4px;
        padding: 8px;
        width: auto;
        min-width: 0;
        max-width: 100px;
        /*border: none;*/
        border: 1px solid var(--default-border-white);
        text-align: right;
        background: var(--default-off-white);
    }

    td:nth-of-type(2) input.stock-table-quantity {
        font-size: 18px;
        font-weight: 900;
    }

</style>
