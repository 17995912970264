<template>
    <div class="constrained">
        <Navigation />
        <main>
            <button class="close-constrained" @click="back">
                <CrossIcon />
            </button>
            <div class="container">
                <slot></slot>
                <div class="actions"> <slot name="actions"></slot>
                </div>
            </div>
            <Notifications ref="notifications" :notifications="notifications" />
        </main>
    </div>
</template>

<script>
    import Navigation from '@/Components/Navigation'
    import Notifications from '@/Components/Notifications'

    export default {
        components: {
            Navigation,
            Notifications,
        },
        props: {
            toasts: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                notifications: [],
            }
        },
        mounted() {
            if(this.$page.props.flash) {
                this.notifications = this.$page.props.flash.toasts
            }
        },
        watch: {
            '$page.props.flash'() {
                if(this.$page.props.flash) {
                    this.notifications = this.$page.props.flash.toasts
                }
            }
        },
        methods: {
            back() {
                // This should be changed with some laravel controller
                // magic so we know where we came from
                this.$inertia.get(route(route().current().split('.')[0] + '.index'))
            }
        }
    }
</script>

<style>
    @import '@vueform/multiselect/themes/default.css';

    .constrained {
        display: grid;
        grid-template-columns: var(--nav-width) calc(100% - var(--nav-width));
        background-color: var(--default-contrast);
        --default-text-color: var(--default-white);
        --default-error: var(--default-contrast-error);
        --default-input-background: var(--default-white);
    }

    .constrained main {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        /* max-width: 976px; */
        padding: var(--main-padding);
        padding-top: 100px;
    }

    .close-constrained {
        display:  flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: var(--main-padding); right: var(--main-padding);
        border: none;
        padding: 0;
        cursor: pointer;
        background-color: transparent;
    }

    .close-constrained svg {
        width: 24px;
        color: var(--default-white);
    }

    .constrained .container {
        width: 590px;
    }

    .constrained .actions {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        padding-top: 40px;
    }

    .constrained .actions button + button {
        margin-left: 1em;
    }

</style>
