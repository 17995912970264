<template>
<svg viewBox="0 0 12 9.454">
<path fill="currentColor" d="M3.695,0.321C3.729,0.355,3.75,0.402,3.75,0.454S3.729,0.553,3.695,0.586L1.633,2.649
	C1.599,2.683,1.552,2.704,1.5,2.704S1.401,2.683,1.367,2.649L0.055,1.336C0.021,1.303,0,1.256,0,1.204s0.021-0.099,0.055-0.133
	l0.266-0.266C0.355,0.771,0.402,0.75,0.453,0.75s0.099,0.021,0.133,0.055L1.5,1.719l1.664-1.664C3.198,0.021,3.245,0,3.297,0
	S3.396,0.021,3.43,0.055L3.695,0.321z M3.695,4.071C3.729,4.105,3.75,4.152,3.75,4.204S3.729,4.303,3.695,4.336L1.633,6.399
	C1.599,6.433,1.552,6.454,1.5,6.454S1.401,6.433,1.367,6.399L0.055,5.086C0.021,5.053,0,5.006,0,4.954s0.021-0.099,0.055-0.133
	l0.266-0.266C0.355,4.521,0.402,4.5,0.453,4.5s0.099,0.021,0.133,0.055L1.5,5.469l1.664-1.664C3.198,3.771,3.245,3.75,3.297,3.75
	S3.396,3.771,3.43,3.805L3.695,4.071z M2.248,8.704c0,0.414-0.336,0.75-0.75,0.75c-0.414,0-0.759-0.336-0.759-0.75
	s0.345-0.75,0.759-0.75C1.912,7.954,2.248,8.29,2.248,8.704z M12,1.016v0.375c0,0.103-0.084,0.188-0.188,0.188H4.687
	C4.584,1.579,4.5,1.495,4.5,1.391V1.016c0-0.103,0.084-0.188,0.188-0.188h7.125C11.916,0.829,12,0.913,12,1.016z M12,4.766v0.375
	c0,0.103-0.084,0.188-0.188,0.188H4.687C4.584,5.329,4.5,5.245,4.5,5.141V4.766c0-0.103,0.084-0.188,0.188-0.188h7.125
	C11.916,4.579,12,4.663,12,4.766z M12,8.516v0.375c0,0.103-0.084,0.188-0.188,0.188H4.687C4.584,9.079,4.5,8.995,4.5,8.891V8.516
	c0-0.103,0.084-0.188,0.188-0.188h7.125C11.916,8.329,12,8.413,12,8.516z"/>
</svg>
</template>
