
export default {
    props: {
        checklists: {
            type: Object,
            default: {}
        }
    },
}

