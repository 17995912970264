<template>
    <Constrained>
        <Titlebar title="New Checklist" />

        <Field label="Title">
            <input id="title" type="text" v-model="form.title">
            <p class="error" v-if="$attrs.errors.title">{{ $attrs.errors.title }}</p>
        </Field>

        <Field label="Show this checklist when">
            <multiselect
                id="statuses"
                placeholder="Select a step to show this checklist on"
                mode="tags"
                v-model="form.statuses"
                :options="statuses"
            ></multiselect>
        </Field>

        <Field label="Machine Type">
            <multiselect
                id="machinetype"
                placeholder="Select a Machine Type"
                v-model="form.machine_type_id"
                label="nameUpper"
                valueProp="id"
                :options="machineTypes"
            ></multiselect>
            <p class="error" v-if="$attrs.errors.machine_type_id">{{ $attrs.errors.machine_type_id }}</p>
        </Field>

        <template #actions>
            <button
                @click="setAction(action)"
                v-for="action in actions"
                class="button-primary"
            >
            {{ action.text }}
            </button>
        </template>
    </Constrained>
</template>

<script>
    import { form, statuses, machineTypes } from '@/Mixins'

    export default {
        mixins: [form, statuses, machineTypes],
        data() {

            return {
                form: {
                    title: null,
                    statuses: null,
                    machine_type_id: null,
                }
            }
        },
    }
</script>
