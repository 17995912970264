
export default {
    props: {
        stores: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        getStoreLink: id => route('stores.show', id)
    }
}
