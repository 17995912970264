<template>
    <Layout>
        <template #sidebar>
            <UserCard
                :user="user"
            />
        </template>

        <Titlebar title="User Settings" />

        <div class="content-block">

            <FieldInline
                v-model="form.name"
                label="Name:"
                type="text"
                :error="$attrs.errors.name"
            />

            <FieldInline
                v-model="form.roles"
                :multiselect="{
                    id: 'roles',
                    placeholder: 'Select roles',
                    mode: 'tags',
                    label: 'name',
                    valueProp: 'id',
                }"
                label="Roles:"
                type="multiselect"
                :error="$attrs.errors.roles"
                :options="roles"
            />

            <FieldInline
                v-if="needsWorkshop"
                v-model="form.workshops"
                :multiselect="{
                    id: 'workshops',
                    placeholder: 'Select workshops',
                    mode: 'tags',
                    label: 'name',
                    valueProp: 'id',
                }"
                label="Workshops:"
                type="multiselect"
                :error="$attrs.errors.workshops"
                :options="workshops"
            />

            <FieldInline
                v-model="form.email"
                label="Email:"
                type="email"
                :error="$attrs.errors.email"
            />

            <FieldInline
                v-model="form.phone"
                label="Phone number:"
                :error="$attrs.errors.phone"
            />

            <FieldInline
                v-model="form.password"
                label="Pin number:"
                :attributes="{
                    maxlength: 4,
                    minlength: 4,
                    type: 'text',

                }"
                :numeric="true"
                :error="$attrs.errors.password"
            />

            <div class="field-inline field-checkboxes">
                <label>Suspended:</label>
                <BooleanField
                    v-model="form.suspended"
                />
                <p v-if="$attrs.errors.suspended">{{ $attrs.errors.suspended }}</p>
            </div>
            <div class="field-inline">
                <label>Recieves Email</label>
                <div>
                    <BooleanField
                        v-model="form.receives_mail"
                    />
                <p v-if="$attrs.errors.receives_mail">{{ $attrs.errors.receives_mail }}</p>
                </div>
            </div>
        </div>

        <template #actions>
            <FormActions
                :actions="actions"
                :setAction="setAction"
                :submit="submit"
            />
        </template>
    </Layout>
</template>

<script>
    import { user, form, roles, workshops } from '@/Mixins'
    import UserCard from '@Cards/UserCard'
    import FieldInline from '@/Components/FieldInline'
    import BooleanField from '@/Components/Fields/BooleanField'

    export default {
        mixins: [user, form, roles, workshops],
        components: {
            UserCard,
            FieldInline,
            BooleanField,
        },
        data() {
            return {
                form: {
                    name: this.user.name,
                    email: this.user.email,
                    phone: this.user.phone,
                    password: '',
                    workshops: this.user.workshops.map(w => w.id),
                    roles: this.user.roles.map(r => r.id),
                    suspended: this.user.suspended ? true : false,
                    receives_mail: this.user.receives_mail ? true : false
                }
            }
        },
        computed: {
            needsWorkshop() {
                let needs = true

                const roles = this.roles.filter(role => {
                    return role.name === 'admin' || role.name === 'support'
                }).map(r => (r.id))

                this.form.roles.forEach(role => {
                    if(roles.indexOf(role) > -1) {
                        needs = false
                    }
                })

                return needs;
            }
        },
    }
</script>
