<template>
    <Constrained>
        <Titlebar title="New Job" />

        <Field label="Store">
            <multiselect
                id="store"
                placeholder="Select a store"
                v-model="form.store_id"
                label="name"
                valueProp="id"
                :searchable="true"
                :options="stores"
                @select="select_store"
            ></multiselect>
            <p class="error" v-if="$attrs.errors.store_id">{{ $attrs.errors.store_id }}</p>
        </Field>

        <div class="field-group">
            <Field label="Machine">
                <multiselect
                    id="machine"
                    placeholder="Select a Machine"
                    v-model="form.machine_id"
                    label="fullName"
                    valueProp="id"
                    :disabled="!form.store_id"
                    :searchable="true"
                    :options="machines"
                    @select="select_machine"
                ></multiselect>
                <p class="error" v-if="$attrs.errors.machine_id">{{ $attrs.errors.machine_id }}</p>
            </Field>

            <Field label="Workshop">
                <multiselect
                    id="workshop"
                    placeholder="Select a Workshop"
                    v-model="form.workshop_id"
                    label="name"
                    valueProp="id"
                    :disabled="!form.store_id"
                    :searchable="true"
                    :options="workshops"
                    @select="select_workshop"
                ></multiselect>
                <p class="error" v-if="$attrs.errors.workshop_id">{{ $attrs.errors.workshop_id }}</p>
            </Field>
        </div>

        <Field label="Reported fault">
            <textarea v-model="form.fault_report"></textarea>
            <p class="error" v-if="$attrs.errors.fault_report">{{ $attrs.errors.fault_report }}</p>
        </Field>

        <Field label="Internal notes">
            <textarea v-model="form.special_request"></textarea>
            <p class="error" v-if="$attrs.errors.special_request">{{ $attrs.errors.special_request }}</p>
        </Field>

        <div class="field-group">
            <Field label="Status">
                <multiselect
                    id="status"
                    placeholder="Select a status"
                    v-model="form.status"
                    label="label"
                    valueProp="value"
                    :options="statusMap"
                    @select="change_status"
                ></multiselect>
                <p class="error" v-if="$attrs.errors.status">{{ $attrs.errors.status }}</p>
            </Field>
            <Field>
                <Checkbox
                    label="Priority Job"
                    v-model="form.priority"
                    :error="$attrs.errors.priority"
                />
            </Field>
        </div>

        <div class="field-group" v-if="form.status == 1">
            <Field label="Dashboard Status">
                <multiselect
                    id="dashboard_status"
                    placeholder="Select a dashboard status"
                    v-model="form.dashboard_status"
                    label="label"
                    valueProp="value"
                    :options="dashboardMap"
                ></multiselect>
                <p class="error" v-if="$attrs.errors.dashboard_status">{{ $attrs.errors.dashboard_status }}</p>
            </Field>
            <Field label="Dashboard Location">
                <multiselect
                    id="dashboard_location"
                    placeholder="Select a location"
                    v-model="form.dashboard_location"
                    label="label"
                    valueProp="value"
                    :options="locationMap"
                ></multiselect>
                <p class="error" v-if="$attrs.errors.dashboard_location">{{ $attrs.errors.dashboard_location }}</p>
            </Field>
        </div>

        <template #actions>
            <FormActions
                :actions="actions"
                :setAction="setAction"
                :submit="submit"
            />
        </template>
    </Constrained>
</template>

<script>

    import {
        form,
        stores,
        machines,
        workshops,
        statuses,
        dashboardStatuses,
        dashboardLocations
    } from '@/Mixins';

    import Field from '@/Components/Field'

    export default {
        mixins: [
            form,
            stores,
            machines,
            workshops,
            statuses,
            dashboardStatuses,
            dashboardLocations
        ],
        components: {
            Field,
        },
        data() {
            const statusMap = this.make_status_map(this.statuses)
            const dashboardMap = this.make_status_map(this.dashboardStatuses, true)
            const locationMap = this.make_status_map(this.dashboardLocations, true)
            return {
                statusMap,
                dashboardMap,
                locationMap,
                form: {
                    workshop_id: null,
                    machine_id: null,
                    store_id: null,
                    fault_report: null,
                    special_request: null,
                    status: null,
                    priority: false,
                    dashboard_status: null,
                    dashboard_location: null
                },
            }
        },
        methods: {
            change_status(status) {
                if(status !== 1) {
                    this.form.dashboard_location = null;
                    this.form.dashboard_status = null;
                }
            },
            make_status_map(statuses, alternate = false) {
                return Object.keys(statuses).map(status => {
                    if(alternate) {
                        return {
                            value: status,
                            label: statuses[status]
                        }
                    } else {
                        return {
                            value: statuses[status],
                            label: status
                        }
                    }

                })
            },
            select_item(key, value) {
                this.form[key] = value

                this.$inertia.post(route('jobs.create'), this.form, {
                    preserveState: true,
                    preserveScroll: true,
                    replace: true
                })
            },
            select_store(id) {
                this.select_item('store_id', id)

                this.form.workshop_id = '';
                this.form.machine_id = '';
            },
            select_machine(id) {
                this.select_item('machine_id', id)
            },
            select_workshop(id) {
                this.select_item('workshop_id', id)
            },
        }
    }
</script>
