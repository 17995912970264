
export default {
    props: {
        users: {
            type: Array,
            default: () => {}
        }
    },
}

