<template>
    <div :class="classList">
        <div>
            <span>{{ value.quantity }}</span>
            <component :is="getIcon" />
        </div>
    </div>
</template>
<script>

import ExclamationIcon from '@/Icons/ExclamationIcon'
import TachometerIcon from '@/Icons/TachometerIcon'

export default {
    components: {
        ExclamationIcon,
        TachometerIcon
    },
    props: {
        value: {
            type: Object,
            required: true,
            default: {}
        }
    },
    data() {
        return {
            ...this.value,
        }
    },
    computed: {
        getIcon() {
            const quantity = parseInt(this.quantity);
            const lowStock = parseInt(this.low_stock_threshold);

            if(quantity !== 0 && quantity > lowStock) return

            if (quantity == 0) {
                return 'ExclamationIcon'
            }

            if (quantity < lowStock) {
                return 'TachometerIcon'
            }

            return
        },
        classList() {
            const classes = []
            const quantity = parseInt(this.quantity);
            const lowStock = parseInt(this.low_stock_threshold);

            classes.push('cell')

            if (quantity === 0) {
                classes.push('cell-error')
            } else if (quantity < lowStock) {
                classes.push('cell-warning')
            }

            return classes.join(' ')
        },
    }
}
</script>
<style scoped>

    .cell {
        display: flex;
        align-items: center;
        font-weight: 900;
    }

    .cell > div {
        display: flex;
        align-items: center;
    }

    .cell > div svg {
        height: 16px;
        max-width: 16px;
        margin-left: 10px;
    }

    .cell-error {
        color: var(--default-error);
    }

    .cell-warning {
        color: var(--default-warning);
    }

</style>
