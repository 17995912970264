<template>
<div class="workshop-card">
    <div class="workshop-icon">
        <MarkerIcon />
    </div>
    <p class="workshop-title">{{ workshop.name }}</p>
    <SidebarTable
        v-if="showTable"
        :items="sidebarItems"
        title="Workshop Stats (current)"
    />
</div>
</template>
<script>
    import { workshop } from '@/Mixins'
    import MarkerIcon from '@/Icons/MarkerIcon'
    import SidebarTable from '@/Components/SidebarTable'

    export default {
        mixins: [workshop],
        components: {
            MarkerIcon,
            SidebarTable
        },
        props: {
            showTable: {
                type: Boolean,
                default: false
            },
            showLink: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                sidebarItems: [
                    {
                        key: 'Active Jobs',
                        value: this.workshop.activeJobsCount
                    },
                    {
                        key: 'Technicians',
                        value: this.workshop.techniciansCount
                    },
                ]
            }
        }
    }
</script>

<style scoped>

    .workshop-card p {
        margin: 1em 0;
    }

    .workshop-title {
        font-size: 20px;
        font-weight: 800;
    }

    .workshop-card {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .workshop-icon {
        width: 60px;
    }

</style>
