
export default {
    props: {
        workshop: {
            type: Object,
            default: {}
        }
    },
    computed: {
        link() {
            return route('workshops.show', this.workshop.id)
        },
    }
}

