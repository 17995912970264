import { useForm } from '@inertiajs/inertia-vue3'
import FormActions from '@/Components/FormActions'

export default {
    props: {
        actions: {
            type: Array,
            default: () => {}
        }
    },
    components: {
        FormActions
    },
    data() {
        return {
            form: {},
            action: null
        }
    },
    mounted() {
        this.form = useForm(this.form)
    },
    methods: {
        setAction(action) {
            if(action.modal) {
                this.ebus.emit('modal:open', { ...action.modal })
            }

            if(action.href) {
                // prevent double clicking
                action.disabled = true;

                this.action = action
                this.submit(action)
            }
        },
        submit(action) {
            this.form.post(this.action.href, {
                onSuccess: () => {
                }
            })
        },
    }
}
