<template>
    <div class="card">
        <div class="card-icon">
            <UserIcon />
        </div>
        <p class="card-title">{{ user.name }}</p>
        <p class="card-subtitle">{{ user.rolesList }}</p>
    </div>
</template>
<script>
    import { user } from '@/Mixins'
    import UserIcon from '@/Icons/UserIcon'

    export default {
        mixins: [user],
        components: {
            UserIcon
        }
    }
</script>
<style>

    .card {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 60px;
    }

    .card-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 76px;
        height: 76px;
        margin: 0 auto;
        margin-bottom: 30px;
        border-radius: 50%;
        background-color: var(--default-contrast);
        color: var(--default-white);
    }

    .card-icon svg {
        width: 26px;
    }

    .card-title {
        font-size: 20px;
        font-weight: 800;
    }

    .card-subtitle {
        margin-top: 8px;
    }

</style>
