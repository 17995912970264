<template>
    <div class="job-checklist">
        <template v-for="link in steps">
            <Link v-if="link.href" :href="link.href" :class="getClassList(link)">
                <span>{{ link.label }}</span>
                <span class="icon">
                    <CheckIcon v-if="link.complete" />
                    <InfoIcon v-if="!link.complete && link.href" />
                </span>
            </Link>
            <div v-else class="incomplete">
                <span>{{ link.label }}</span>
                <span class="icon">
                    <CheckIcon v-if="link.complete" />
                </span>
            </div>
        </template>
    </div>
</template>

<script>
    import CheckIcon from '@/Icons/CheckIcon'
    import InfoIcon from '@/Icons/InfoIcon'

    export default {
        components: {
            CheckIcon,
            InfoIcon
        },
        props: {
            steps: {
                type: Array,
                default: () => {}
            }
        },
        methods: {
            getClassList(link) {

                let classes = [];

                if(link.active)  {
                    classes.push('active')
                }

                return classes.join(' ')
            }
        }
    }
</script>

<style scoped>

    .job-checklist {
        display: flex;
        flex-direction: column;
        margin-top: 80px;
    }

    .job-checklist a,
    .job-checklist div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 100%;
        padding: 15px;
        border-bottom: 1px solid rgba(var(--default-contrast-rgb), .11);
        transition: color var(--transition-delay) var(--transition-timing-function);
    }

    .job-checklist a:hover {
        color: var(--default-secondary)
    }

    .job-checklist .active {
        font-weight: 800;
    }

    .job-checklist .incomplete {
        opacity: .33;
    }

    .job-checklist .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        border: 1px solid var(--default-contrast);
        border-radius: 20px;
    }

    .job-checklist .icon svg {
        width: 70%;
    }


</style>
