<template>
    <StreamBarcodeReader @decode="onDecode" @loaded="onLoaded"/>
</template>

<script>
import { StreamBarcodeReader } from 'vue-barcode-reader';
export default {
    components: {
        StreamBarcodeReader
    },
    methods: {
        onDecode(text) {
            if (text) {
                this.$inertia.get(route('jobs.scan', { machine: text }))
            }
        },
        onLoaded() {
        },
    }
}
</script>

<style scoped>
</style>
