<template>
    <Layout>
        <Titlebar title="Parts" :links="links" />
        <Table :table="table" :row_action="showPart" />
    </Layout>
</template>

<script>
    import { links } from '@/Mixins'
    import Table from '@/Components/Table'

    export default {
        mixins: [links],
        components: {
            Table
        },
        props: {
            table: {
                type: Object,
                default: {}
            }
        },
        methods: {
            showPart(id) {
                const link = route('parts.show', id);
                this.$inertia.visit(link);
            }
        }
    }
</script>
