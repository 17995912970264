<template>
    <Layout>
        <template #sidebar>
            <MachineCard :machine="job.machine" :showLink="true" />
            <JobTracker :steps="steps" />
        </template>
        <Titlebar title="Grooming" />

        <EditableField
            label="Grooming notes"
            :editable="true"
            :value="job.grooming_notes"
            endpoint="jobs.update.groomingNotes"
            blockStyle="notice-light"
            :model="job"
        />

        <Checklist
            v-for="checklist in checklists"
            :checklist="checklist"
        />

        <template #actions>
            <FormActions :actions="actions" :setAction="setAction" :submit="submit" />
        </template>
    </Layout>
</template>

<script>
    import { job, form, checklists } from '@/Mixins'
    import MachineCard from '@Cards/MachineCard'
    import JobTracker from '@/Components/JobTracker'
    import EditableField from '@/Components/EditableField'
    import Field from '@/Components/Field'

    export default {
        mixins: [job, form, checklists],
        components: {
            MachineCard,
            JobTracker,
            Field,
            EditableField,
        },
    }
</script>
