<template>
<svg viewBox="0 0 15 20">
<path fill="currentColor" d="M15,4.375v13.75C15,19.16,14.16,20,13.125,20H1.875C0.84,20,0,19.16,0,18.125V4.375
	C0,3.34,0.84,2.5,1.875,2.5h3.461C5.32,2.398,5.312,2.293,5.312,2.188C5.312,0.98,6.293,0,7.5,0s2.188,0.98,2.188,2.188
	c0,0.105-0.008,0.211-0.023,0.312h3.461C14.16,2.5,15,3.34,15,4.375z M13.75,4.375c0-0.344-0.281-0.625-0.625-0.625H11.25v0.781
	C11.25,4.789,11.039,5,10.781,5H4.219C3.961,5,3.75,4.789,3.75,4.531V3.75H1.875c-0.344,0-0.625,0.281-0.625,0.625v13.75
	c0,0.344,0.281,0.625,0.625,0.625h11.25c0.344,0,0.625-0.281,0.625-0.625V4.375z M4.641,10.563L3.191,9.102
	c-0.082-0.082-0.082-0.215,0-0.297l0.496-0.492c0.082-0.082,0.215-0.082,0.297,0l0.805,0.816l1.859-1.844
	c0.082-0.082,0.215-0.082,0.297,0l0.492,0.496c0.082,0.082,0.082,0.215,0,0.297L4.93,10.563C4.848,10.645,4.715,10.645,4.641,10.563
	z M5.312,13.75c0,0.52-0.418,0.938-0.938,0.938c-0.52,0-0.938-0.418-0.938-0.938c0-0.52,0.418-0.938,0.938-0.938
	C4.895,12.812,5.312,13.23,5.312,13.75z M11.25,13.438v0.625c0,0.172-0.141,0.312-0.312,0.312H6.562
	c-0.172,0-0.312-0.141-0.312-0.312v-0.625c0-0.172,0.141-0.312,0.312-0.312h4.375C11.109,13.125,11.25,13.266,11.25,13.438z
	 M6.562,2.188c0,0.52,0.418,0.938,0.938,0.938c0.52,0,0.938-0.418,0.938-0.938c0-0.52-0.418-0.938-0.938-0.938
	C6.98,1.25,6.562,1.668,6.562,2.188z M11.25,9.688v0.625c0,0.172-0.141,0.312-0.312,0.312H6.645l1.262-1.25h3.031
	C11.109,9.375,11.25,9.516,11.25,9.688z"/>
</svg>
</template>
