<template>
    <div v-if="value" class="frequency">
        <RefreshIcon />
        Repeat Offender
    </div>
</template>

<script>
    import RefreshIcon from '@/Icons/RefreshIcon'

    export default {
        components: {
            RefreshIcon
        },
        props: {
            value: {
                type: Boolean,
                default: false
            }
        },
    }
</script>

<style>
    .frequency {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        font-size: 11px;
        letter-spacing: 0.1em;
        font-weight: 700;
        color: var(--default-error);
    }

    .frequency svg {
        width: 16px;
        margin-right: .5em;
    }
</style>
