<template>
    <Layout>
        <div v-if="isModalActive" class="modal">

        </div>

        <div class="dashboard-title">
            <a href="/jobs" class="dashboard-back">&larr; Back</a>
            <h1>Dashboard</h1>
            <span> </span>
        </div>

        <div class="dashboard">
            <DashboardColumn title="Auckland" :rows="auckland" />
            <DashboardColumn title="North Island" :rows="north" />
            <DashboardColumn title="South Island" :rows="south" />
        </div>
    </Layout>
</template>

<script>
    import { Inertia } from '@inertiajs/inertia'

    import DashboardColumn from "../../Components/DashboardColumn";
    export default {
        mixins: [],
        components: {
            DashboardColumn
        },
        props: {
            table: {
                type: Object,
                default: {}
            },
            auckland: {
                type: Array,
                default: []
            },
            north: {
                type: Array,
                default: []
            },
            south: {
                type: Array,
                default: []
            }
        },
        data() {
          return  {
              isModalActive: false
          }
        },
        mounted() {
            setInterval(() => {
                Inertia.reload({ only: ['auckland', 'north', 'south'] })
            }, 60000)
        }
    }
</script>

<style scoped>

.dashboard {
    display: flex;
    justify-content: space-between;
    padding: 25px var(--main-padding);
}

.dashboard-title {
    padding: 15px 25px;
    background-color: var(--default-off-white);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dashboard-title .dashboard-back {
    text-transform: uppercase;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 1px;
    color: var(--default-grey);
    transition: color var(--transition-delay) var(--transition-timing-function);
}
.dashboard-title .dashboard-back:hover {
    color: var(--default-contrast);
}

.dashboard-title h1 {
    color: var(--default-contrast);
    font-size: 1.5em;
}

</style>
