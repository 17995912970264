
export default {
    props: {
        checklist: {
            type: Object,
            default: {}
        }
    },
}

