<template>
    <div class="pagination">
        <div class="pagination-controls">
            <Link
                :key="link"
                v-for="link in links"
                :href="link.url"
                :class="getClassList(link)"
            >
                <span v-if="isNumberLink(link)">{{ link.label }}</span>
                <RightChevron v-else :reverse="isFirst(link)"/>
            </Link>
        </div>
        <div class="pagination-meta">
            Showing {{ from }} - {{ to }} of {{ total }} total
        </div>
    </div>
</template>
<script>
    import RightChevron from '@/Icons/RightChevron'

    export default {
        components: {
            RightChevron
        },
        props: {
            info: {
                type: Object,
                default: {}
            }
        },
        data() {
            return {
                ...this.info
            }
        },
        methods: {
            isNumberLink(link) {
                const index = this.links.indexOf(link)
                return (index !== 0 && index !== this.links.length - 1)
            },
            isFirst(link) {
                return this.links.indexOf(link) === 0
            },
            getClassList(link) {

                const classList = []

                if(link.active) {
                    classList.push('active')
                }

                if(link.url === null) {
                    classList.push('disabled')
                }

                return classList.join(' ')
            }
        }
    }
</script>
<style scoped>

    .pagination {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
    }

    .pagination-controls {
        display: flex;
        align-items: center;
    }

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 27px;
        height: 27px;
        border-radius: 27px;
        font-size: 14px;
        margin: 6px;
    }

    a.disabled {
        pointer-events: none;
    }

    a:hover {
        background-color: rgba(var(--default-contrast-rgb), .15);
    }

    a.active {
        background-color: var(--default-contrast);
        color: var(--default-white);
    }

    a.disabled {
        opacity: .25;
    }

    a svg {
        width: 1em;
    }
</style>
