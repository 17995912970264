<template>
    <div class="card">
        <div v-if="$slots.image" class="card-image">
            <slot name="image" />
        </div>
        <p class="card-title">{{ title }}</p>
        <p class="card-subtitle">{{ subtitle }}</p>
        <Link v-if="link" :href="link.href">{{ link.text }}</Link>
    </div>
</template>
<script>
    export default {
        props: {
            title: {
                type: String,
                default: ''
            },
            subtitle: {
                type: String,
                default: ''
            },
            link: {
                type: Object,
                default: {}
            },
        }
    }
</script>
<style scoped>

    aside .card {
        margin-top: 60px;
    }

    .card {
        text-align: center;
    }

    .card-title {
        font-size: 24px;
        font-weight: 700;
        margin-top: 30px;
    }

    .card-subtitle {
        margin-top: 1em;
        font-size: 16px;
        font-weight: 700;
    }

    .card-image {
    }

</style>
