<template>
    <div class="machine-cell">
        <div class="machine-cell-image">
            <MachineIcon />
        </div>
        <div class="machine-cell-data">
            <span class="machine-cell-title">{{ created_at }}</span>
        </div>
    </div>
</template>
<script>

import MachineIcon from '@/Icons/MachineIcon'

export default {
    components: {
        MachineIcon,
    },
    props: {
        value: {
            type: Object,
            required: true,
            default: {}
        }
    },
    data() {
        return {
            ...this.value,
        }
    },
}
</script>
<style scoped>

    .machine-cell {
        display: flex;
        align-items: center;
    }

    .machine-cell-image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        margin-right: 20px;
        border-radius: 50%;
        background-color: var(--default-secondary);
    }

    .machine-cell svg {
        height: 55%;
        color: var(--default-white);
    }

    .machine-cell-data {
        display: flex;
        flex-direction: column;
    }

    .machine-cell-title {
        font-weight: 900;
    }

</style>
