<template>
    <div class="titlebar">
        <h1>{{ title }}</h1>
        <div class="meta">
            <slot />
            <div class="links" v-if="links">
                <template
                    v-for="link in links"
                    :key="link"
                >
                    <a
                        v-if="link.normal"
                        :href="link.href"
                        class="button-primary"
                    >{{ link.text }}</a>
                    <Link
                        v-else-if="!link.normal && link.href"
                        @click="call(link)"
                        :href="link.href"
                        :method="link.method"
                        class="button-primary"
                    >{{ link.text }}</Link>
                    <button
                        v-else
                        @click="call(link)"
                        class="button-primary"
                        :class="link.class"
                    >
                    {{ link.text }}
                    </button>
                </template>
            </div>
        </div>
        <!-- Temp modal for scanner -->
        <div v-if="isModalActive" class="modal">
            <button
                @click="closeModal"
                class="button-close"
            >
                Close
            </button>
            <Scanner />
        </div>
    </div>
</template>

<script>
    import { links } from '@/Mixins'
    import Scanner from '@/Components/Scanner'

    export default {
        mixins: [links],
        components: {Scanner},
        data() {
            return {
                isModalActive: false
            }
        },
        props: {
            title: {
                type: String,
                default: ''
            }
        },
        methods: {
            showModal() {
                this.isModalActive = true
            },
            closeModal() {
                this.isModalActive = false
            },
            call(link) {

                if(this[link.trigger]) {
                    this[link.trigger](link)
                }

                if(link.modal) {
                    this.ebus.emit('modal:open', link.modal)
                }
            },
            triggerCamera(link) {
                this.isModalActive = true
            },
        }
    }
</script>

<style scoped>

    .titlebar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;
    }

    h1 {
        font-weight: 300;
        font-size: 36px;
        color: var(--default-text-color);
    }

    div {
        display: flex;
    }

    .links {
        margin-left: 1em;
    }

    .links a + a {
        margin-left: 1em;
    }

    .meta {
        align-items: center;
    }

    .modal {
        z-index: 50;
        position: fixed;
        top: 0; left: 0;
        width: 100vw;
        height: 100vh;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--default-contrast);
        color: var(--default-white);
    }

    .button-close {
        display: block;
        position: absolute;
        z-index: 20;
        right: 15px;
        top: 15px;
    }

    @media only screen and (min-width: 1366px) {
        .button--scanner {
            display: none;
        }
    }

</style>
