<template>
    <div class="avatar">
        <span class="avatar-name"><span>{{ name }}</span></span>
        <div class="avatar-thumbnail" :class="colour">
            <span class="avatar-image">
                {{ first_letter_upper }}
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            name: {
                type: String,
                default: ''
            },
            roles: {
                type: String,
                default: ''
            }
        },
        computed: {
            first_letter_upper() {
                return this.name.charAt(0).toUpperCase()
            },
            colour() {
                if(this.roles.includes('support')) {
                    return 'avatar-blue';
                }

                if(this.roles.includes('admin')) {
                    return 'avatar-grey';
                }
            }
        }
    }
</script>

<style>

    .avatar {
        --font-size: 50px;
        --size: calc(var(--font-size) * 1.6);
        position: relative;
        width: var(--size);
        color: var(--default-white);
    }

    .avatar-thumbnail {
        display: flex;
        align-items: center;
        justify-content: center;
        width: var(--size); height: var(--size);
        margin: 10px 0;
        background-color: var(--default-primary);
        border-radius: 50%;
        font-size: var(--font-size);
        transition: background var(--transition-delay) var(--transition-timing-function), opacity var(--transition-delay) var(--transition-timing-function);
    }

    .avatar-blue {
        background-color: var(--default-secondary);
    }

    .avatar-grey {
        background-color: var(--default-grey);
    }

    .avatar-name {
        display: flex;
        justify-content: center;
    }
</style>
