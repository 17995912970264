<template>
    <label class="input-boolean">
        <input
            :value="modelValue"
            :checked="modelValue"
            @change="updateValue"
            type="checkbox"
        >
        <div class="input-checkbox_faux">
            <CheckIconSharp v-if="modelValue" />
        </div>
    </label>
</template>
<script>
    import CheckIconSharp from '@/Icons/CheckIconSharp'

    export default {
        components: {
            CheckIconSharp
        },
        props: {
            options: {
                type: Array,
                default: () => [],
            },
            modelValue: {
                // Make sure when passing your value you cast it to Boolean
                // Numbers will throw a warning
                type: Boolean,
                default: false,
                required: true
            },
        },
        methods: {
            updateValue() {
                console.log(this.modelValue)
                this.$emit('update:modelValue', !this.modelValue)
            }
        }
    }
</script>
<style>

    .input-boolean {
        cursor: pointer;
        position: relative;
    }

    .input-boolean input {
        position: absolute;
        opacity: 0;
    }

    .input-checkbox_faux {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px; height: 40px;
        background-color: var(--default-light);
    }

    .input-checkbox_faux svg {
        width: 45%; height: 45%;
    }

</style>

