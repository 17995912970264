<template>
    <div class="archive-icon" @click="link" v-if="status != 13">
        <ArchiveIcon />
    </div>
</template>
<script>

import ArchiveIcon from '@/Icons/ArchiveIcon'

export default {
    components: {
        ArchiveIcon,
    },
    props: {
        value: {
            type: Object,
            required: true,
            default: {}
        }
    },
    data() {
        return {
            ...this.value,
        }
    },
    methods: {
        link() {
            this.$inertia.post(route('jobs.archive', this.value.id))
        }
    }
}
</script>
<style scoped>

    .archive-icon {
        display: flex;
        align-items: center;
    }

    .archive-icon svg {
        width: 24px;
    }

</style>
