<template>
    <svg class="logo" viewBox="0 0 10434 2404" xmlns="http://www.w3.org/2000/svg">
        <path fill="currentColor" d="M4488.12 1.49158L4243.12 3.49158L4002.12 901.492L3762.12 1798.49L4186.12 1801.49C4658.12 1804.49 4669.12 1802.49 4808.12 1735.49C4919.12 1682.49 5026.12 1571.49 5095.12 1438.49C5186.12 1261.49 5281.12 999.492 5314.12 828.492C5340.12 697.492 5338.12 493.492 5310.12 390.492C5254.12 185.492 5139.12 74.4916 4930.12 23.4916C4832.12 0.491577 4788.12 -2.50842 4488.12 1.49158ZM4787.12 392.492C4853.12 458.492 4833.12 660.492 4728.12 1011.49C4671.12 1201.49 4633.12 1294.49 4595.12 1338.49C4562.12 1376.49 4561.12 1376.49 4474.12 1381.49C4391.12 1385.49 4386.12 1384.49 4392.12 1367.49C4401.12 1337.49 4658.12 380.492 4658.12 377.492C4658.12 375.492 4683.12 373.492 4713.12 373.492C4754.12 373.492 4773.12 378.492 4787.12 392.492Z" />
        <path fill="currentColor" d="M249.117 891.492C115.117 1379.49 3.11686 1784.49 0.116864 1790.49C-2.88314 1800.49 51.1169 1803.49 250.117 1803.49H505.117L608.117 1415.49C664.117 1202.49 714.117 1021.49 719.117 1013.49C724.117 1003.49 727.117 1016.49 728.117 1048.49C728.117 1148.49 749.117 1699.49 754.117 1750.49L759.117 1803.49H1014.12H1268.12V1780.49C1268.12 1768.49 1248.12 1601.49 1223.12 1408.49C1198.12 1216.49 1178.12 1046.49 1178.12 1031.49C1178.12 1009.49 1184.12 1003.49 1211.12 998.492C1395.12 959.492 1580.12 674.492 1595.12 406.492C1599.12 335.492 1597.12 302.492 1583.12 261.492C1546.12 148.492 1460.12 71.4917 1324.12 27.4917C1248.12 3.4917 1243.12 3.4917 870.117 3.4917H493.117L249.117 891.492ZM1030.12 374.492C1060.12 403.492 1060.12 406.492 1056.12 477.492C1047.12 613.492 972.117 704.492 869.117 706.492C841.117 706.492 816.117 704.492 813.117 701.492C809.117 698.492 830.117 616.492 859.117 519.492L912.117 343.492H956.117C991.117 343.492 1005.12 349.492 1030.12 374.492Z" />
        <path fill="currentColor" d="M7377.12 900.491L7121.12 1798.49L7356.12 1801.49C7507.12 1802.49 7594.12 1800.49 7598.12 1793.49C7601.12 1788.49 7664.12 1563.49 7737.12 1293.49L7871.12 804.491L7937.12 801.491L8003.12 798.491L8070.12 633.491L8136.12 468.491L8052.12 465.491C8006.12 464.491 7968.12 461.491 7968.12 459.491C7968.12 457.491 7999.12 354.491 8037.12 229.491L8106.12 3.49146H7869.12H7632.12L7377.12 900.491Z" />
        <path fill="currentColor" d="M5923.12 414.492C5729.12 444.492 5564.12 555.492 5442.12 739.492C5263.12 1008.49 5206.12 1401.49 5313.12 1627.49C5342.12 1688.49 5430.12 1776.49 5485.12 1800.49C5640.12 1865.49 5845.12 1855.49 6005.12 1775.49C6271.12 1640.49 6468.12 1267.49 6468.12 899.492C6467.12 593.492 6327.12 427.492 6055.12 409.492C6015.12 406.492 5956.12 408.492 5923.12 414.492ZM6002.12 816.492C6044.12 847.492 6040.12 961.492 5992.12 1114.49C5908.12 1382.49 5788.12 1514.49 5707.12 1427.49C5686.12 1404.49 5683.12 1393.49 5685.12 1330.49C5688.12 1164.49 5805.12 881.492 5895.12 822.492C5927.12 800.492 5976.12 797.492 6002.12 816.492Z" />
        <path fill="currentColor" d="M8618.12 409.492C8421.12 438.492 8274.12 522.492 8153.12 675.492C8010.12 856.492 7924.12 1112.49 7925.12 1358.49C7925.12 1461.49 7929.12 1490.49 7952.12 1558.49C7995.12 1690.49 8066.12 1767.49 8187.12 1815.49C8235.12 1834.49 8262.12 1837.49 8373.12 1838.49C8489.12 1838.49 8511.12 1835.49 8583.12 1810.49C8870.12 1712.49 9077.12 1407.49 9129.12 1008.49C9155.12 807.492 9110.12 627.492 9006.12 523.492C8921.12 437.492 8756.12 389.492 8618.12 409.492ZM8677.12 829.492C8706.12 865.492 8704.12 936.492 8674.12 1054.49C8625.12 1241.49 8553.12 1380.49 8479.12 1430.49C8435.12 1460.49 8403.12 1459.49 8373.12 1428.49C8352.12 1408.49 8348.12 1394.49 8348.12 1341.49C8348.12 1188.49 8460.12 904.492 8550.12 828.492C8592.12 793.492 8649.12 793.492 8677.12 829.492Z" />
        <path fill="currentColor" d="M7048.12 428.491C6852.12 483.491 6708.12 613.492 6598.12 838.492C6479.12 1080.49 6421.12 1368.49 6458.12 1531.49C6502.12 1720.49 6657.12 1822.49 6900.12 1823.49C7035.12 1823.49 7025.12 1833.49 7087.12 1627.49C7117.12 1528.49 7143.12 1442.49 7146.12 1434.49C7150.12 1424.49 7145.12 1424.49 7122.12 1436.49C7080.12 1457.49 7022.12 1463.49 6987.12 1451.49C6932.12 1432.49 6909.12 1351.49 6927.12 1243.49C6946.12 1135.49 6998.12 986.492 7039.12 923.492C7091.12 843.492 7144.12 808.492 7217.12 808.492C7248.12 808.492 7282.12 810.492 7292.12 813.492C7310.12 817.492 7318.12 799.491 7365.12 643.491C7394.12 547.491 7418.12 465.491 7418.12 461.491C7418.12 458.491 7397.12 447.491 7371.12 438.491C7302.12 414.491 7119.12 408.491 7048.12 428.491Z" />
        <path fill="currentColor" d="M9823.12 449.492C9529.12 502.492 9323.12 654.492 9237.12 882.492C9221.12 925.492 8978.12 1786.49 8978.12 1800.49C8978.12 1802.49 9079.12 1803.49 9203.12 1803.49H9427.12L9437.12 1765.49C9555.12 1313.49 9591.12 1191.49 9633.12 1107.49C9708.12 954.492 9794.12 898.492 9972.12 885.492L10062.1 878.492L10119.1 663.492C10162.1 505.492 10174.1 446.492 10165.1 441.492C10145.1 428.492 9908.12 434.492 9823.12 449.492Z" />
        <path fill="currentColor" d="M10253.1 476.492C10214.1 515.492 10208.1 554.492 10233.1 603.492C10252.1 640.492 10276.1 653.492 10327.1 653.492C10404.1 653.492 10454.1 581.492 10425.1 512.492C10406.1 466.492 10386.1 451.492 10333.1 446.492C10292.1 441.492 10286.1 443.492 10253.1 476.492ZM10383.1 488.492C10430.1 534.492 10410.1 614.492 10346.1 636.492C10318.1 646.492 10268.1 623.492 10253.1 592.492C10232.1 552.492 10234.1 529.492 10264.1 494.492C10297.1 454.492 10347.1 451.492 10383.1 488.492Z" />
        <path fill="currentColor" d="M10288.1 553.492C10288.1 586.492 10292.1 613.492 10298.1 613.492C10304.1 613.492 10308.1 602.492 10308.1 588.492C10308.1 574.492 10312.1 563.492 10317.1 563.492C10322.1 563.492 10333.1 574.492 10342.1 588.492C10351.1 602.492 10363.1 613.492 10368.1 613.492C10374.1 613.492 10371.1 602.492 10362.1 588.492C10347.1 565.492 10347.1 562.492 10363.1 547.492C10372.1 538.492 10377.1 524.492 10374.1 516.492C10371.1 508.492 10368.1 499.492 10368.1 497.492C10368.1 495.492 10350.1 493.492 10328.1 493.492H10288.1V553.492ZM10353.1 523.492C10353.1 531.492 10343.1 539.492 10331.1 541.492C10313.1 544.492 10308.1 539.492 10308.1 523.492C10308.1 507.492 10313.1 502.492 10331.1 505.492C10343.1 507.492 10353.1 515.492 10353.1 523.492Z" />
        <path fill="currentColor" d="M1639.12 530.492C1413.12 1300.49 1385.12 1408.49 1385.12 1488.49C1384.12 1583.49 1408.12 1646.49 1466.12 1706.49C1547.12 1790.49 1649.12 1817.49 1853.12 1810.49C2064.12 1803.49 2199.12 1756.49 2312.12 1652.49C2405.12 1566.49 2414.12 1544.49 2567.12 990.492C2645.12 710.492 2708.12 477.492 2708.12 472.492C2708.12 467.492 2610.12 463.492 2483.12 463.492H2259.12L2243.12 515.492C2235.12 544.492 2177.12 746.492 2114.12 963.492C1988.12 1399.49 1986.12 1403.49 1909.12 1403.49C1863.12 1403.49 1848.12 1389.49 1848.12 1343.49C1848.12 1317.49 2020.12 713.492 2093.12 482.492C2099.12 464.492 2093.12 463.492 1879.12 463.492H1659.12L1639.12 530.492Z" />
        <path fill="currentColor" d="M3146.12 473.492C3010.12 496.492 2929.12 533.492 2859.12 603.492C2680.12 782.492 2558.12 1109.49 2571.12 1373.49C2577.12 1492.49 2593.12 1554.49 2640.12 1633.49C2707.12 1743.49 2805.12 1788.49 2981.12 1788.49H3089.12L3083.12 1823.49C3064.12 1925.49 3027.12 1945.49 2869.12 1941.49L2755.12 1938.49L2686.12 2118.49C2649.12 2217.49 2618.12 2299.49 2618.12 2299.49C2618.12 2300.49 2657.12 2306.49 2704.12 2313.49C2826.12 2331.49 3061.12 2321.49 3158.12 2295.49C3338.12 2246.49 3407.12 2199.49 3462.12 2088.49C3481.12 2048.49 3854.12 731.492 3913.12 490.492L3920.12 463.492L3557.12 464.492C3357.12 465.492 3172.12 469.492 3146.12 473.492ZM3361.12 810.492C3365.12 818.492 3204.12 1405.49 3193.12 1423.49C3190.12 1429.49 3168.12 1433.49 3144.12 1433.49C3111.12 1433.49 3096.12 1427.49 3077.12 1407.49C3007.12 1333.49 3076.12 1003.49 3186.12 881.492C3206.12 859.492 3238.12 833.492 3257.12 823.492C3293.12 804.492 3353.12 797.492 3361.12 810.492Z" />
        <path fill="currentColor" d="M4817.12 1903.49C4746.12 1924.49 4700.12 1991.49 4677.12 2108.49C4655.12 2218.49 4684.12 2276.49 4769.12 2291.49C4874.12 2310.49 4958.12 2269.49 4983.12 2185.49C4989.12 2165.49 4986.12 2163.49 4945.12 2163.49C4905.12 2163.49 4899.12 2167.49 4877.12 2200.49C4846.12 2249.49 4798.12 2258.49 4773.12 2220.49C4759.12 2198.49 4759.12 2189.49 4773.12 2117.49C4793.12 2021.49 4824.12 1962.49 4860.12 1949.49C4893.12 1937.49 4928.12 1963.49 4928.12 2000.49C4928.12 2021.49 4932.12 2023.49 4973.12 2023.49C5016.12 2023.49 5018.12 2022.49 5018.12 1994.49C5018.12 1920.49 4915.12 1873.49 4817.12 1903.49Z" />
        <path fill="currentColor" d="M6549.12 1907.49C6531.12 1913.49 6503.12 1932.49 6487.12 1947.49C6449.12 1985.49 6414.12 2092.49 6413.12 2170.49C6413.12 2225.49 6416.12 2236.49 6441.12 2260.49C6465.12 2285.49 6477.12 2288.49 6546.12 2291.49C6609.12 2293.49 6629.12 2290.49 6655.12 2274.49C6689.12 2253.49 6711.12 2223.49 6723.12 2185.49C6729.12 2165.49 6726.12 2163.49 6685.12 2163.49C6645.12 2163.49 6639.12 2167.49 6617.12 2200.49C6591.12 2241.49 6549.12 2255.49 6522.12 2232.49C6502.12 2215.49 6504.12 2137.49 6529.12 2058.49C6551.12 1987.49 6584.12 1943.49 6616.12 1943.49C6641.12 1943.49 6668.12 1974.49 6668.12 2001.49C6668.12 2020.49 6673.12 2023.49 6713.12 2023.49C6756.12 2023.49 6758.12 2022.49 6758.12 1994.49C6758.12 1916.49 6649.12 1871.49 6549.12 1907.49Z" />
        <path fill="currentColor" d="M3703.12 1918.49C3700.12 1926.49 3696.12 1940.49 3693.12 1948.49C3690.12 1960.49 3699.12 1963.49 3739.12 1963.49C3782.12 1963.49 3789.12 1965.49 3784.12 1980.49C3775.12 2011.49 3708.12 2282.49 3708.12 2288.49C3708.12 2291.49 3729.12 2293.49 3756.12 2291.49L3803.12 2288.49L3844.12 2128.49L3884.12 1968.49L3929.12 1965.49C3974.12 1962.49 3988.12 1951.49 3988.12 1920.49C3988.12 1905.49 3975.12 1903.49 3848.12 1903.49C3732.12 1903.49 3707.12 1906.49 3703.12 1918.49Z" />
        <path fill="currentColor" d="M3969.12 2080.49C3944.12 2178.49 3923.12 2266.49 3920.12 2276.49C3916.12 2292.49 3921.12 2294.49 3958.12 2291.49L4001.12 2288.49L4028.12 2185.49C4043.12 2129.49 4059.12 2078.49 4064.12 2073.49C4069.12 2068.49 4085.12 2062.49 4098.12 2061.49C4132.12 2057.49 4135.12 2091.49 4109.12 2188.49C4080.12 2291.49 4081.12 2293.49 4128.12 2293.49C4165.12 2293.49 4168.12 2291.49 4177.12 2255.49C4225.12 2073.49 4227.12 2053.49 4202.12 2029.49C4183.12 2009.49 4132.12 2009.49 4096.12 2027.49C4063.12 2044.49 4062.12 2041.49 4083.12 1963.49C4091.12 1933.49 4098.12 1907.49 4098.12 1905.49C4098.12 1904.49 4079.12 1903.49 4055.12 1903.49H4012.12L3969.12 2080.49Z" />
        <path fill="currentColor" d="M7733.12 1915.49C7731.12 1922.49 7710.12 2002.49 7688.12 2093.49C7665.12 2184.49 7645.12 2266.49 7642.12 2275.49C7637.12 2292.49 7647.12 2293.49 7762.12 2293.49C7864.12 2293.49 7888.12 2290.49 7888.12 2279.49C7888.12 2271.49 7891.12 2258.49 7894.12 2249.49C7899.12 2235.49 7890.12 2233.49 7831.12 2233.49C7793.12 2233.49 7759.12 2231.49 7756.12 2227.49C7753.12 2224.49 7756.12 2201.49 7762.12 2175.49L7774.12 2128.49L7841.12 2125.49C7890.12 2123.49 7908.12 2118.49 7908.12 2108.49C7908.12 2101.49 7911.12 2088.49 7914.12 2079.49C7919.12 2065.49 7911.12 2063.49 7859.12 2063.49C7802.12 2063.49 7798.12 2061.49 7799.12 2040.49C7802.12 1975.49 7809.12 1968.49 7883.12 1963.49C7948.12 1958.49 7952.12 1956.49 7959.12 1930.49L7966.12 1903.49H7852.12C7769.12 1903.49 7736.12 1906.49 7733.12 1915.49Z" />
        <path fill="currentColor" d="M6191.12 1963.49C6178.12 1969.49 6168.12 1981.49 6168.12 1993.49C6168.12 2006.49 6161.12 2013.49 6149.12 2013.49C6139.12 2013.49 6127.12 2020.49 6124.12 2029.49C6115.12 2051.49 6116.12 2053.49 6133.12 2053.49C6152.12 2053.49 6152.12 2058.49 6127.12 2158.49C6107.12 2237.49 6107.12 2244.49 6122.12 2268.49C6135.12 2288.49 6146.12 2293.49 6183.12 2293.49C6217.12 2293.49 6230.12 2288.49 6238.12 2274.49C6252.12 2247.49 6251.12 2243.49 6228.12 2243.49C6200.12 2243.49 6195.12 2223.49 6207.12 2174.49C6213.12 2151.49 6223.12 2115.49 6228.12 2094.49C6236.12 2065.49 6244.12 2054.49 6261.12 2051.49C6290.12 2047.49 6297.12 2013.49 6269.12 2013.49C6252.12 2013.49 6249.12 2009.49 6255.12 1983.49C6260.12 1956.49 6258.12 1953.49 6237.12 1954.49C6224.12 1954.49 6203.12 1958.49 6191.12 1963.49Z" />
        <path fill="currentColor" d="M9096.12 1960.49C9087.12 1962.49 9078.12 1974.49 9076.12 1986.49C9074.12 2004.49 9065.12 2009.49 9026.12 2013.49C9000.12 2016.49 8970.12 2027.49 8959.12 2037.49C8940.12 2054.49 8940.12 2054.49 8945.12 2033.49C8950.12 2013.49 8947.12 2012.49 8907.12 2015.49L8864.12 2018.49L8831.12 2148.49C8813.12 2219.49 8798.12 2282.49 8798.12 2286.49C8798.12 2291.49 8817.12 2293.49 8840.12 2291.49L8882.12 2288.49L8904.12 2198.49C8929.12 2100.49 8953.12 2067.49 8994.12 2077.49C9008.12 2081.49 9018.12 2078.49 9022.12 2068.49C9027.12 2053.49 9058.12 2047.49 9058.12 2060.49C9058.12 2065.49 9049.12 2102.49 9038.12 2143.49C9027.12 2184.49 9018.12 2228.49 9018.12 2240.49C9018.12 2274.49 9045.12 2293.49 9094.12 2293.49C9131.12 2293.49 9140.12 2289.49 9147.12 2270.49C9154.12 2252.49 9152.12 2247.49 9134.12 2243.49C9106.12 2235.49 9104.12 2212.49 9126.12 2128.49C9140.12 2075.49 9149.12 2057.49 9164.12 2054.49C9199.12 2048.49 9210.12 2013.49 9177.12 2013.49C9159.12 2013.49 9157.12 2009.49 9163.12 1983.49C9169.12 1955.49 9168.12 1953.49 9141.12 1954.49C9126.12 1955.49 9105.12 1957.49 9096.12 1960.49Z" />
        <path fill="currentColor" d="M4320.12 2033.49C4279.12 2059.49 4246.12 2128.49 4244.12 2195.49C4243.12 2238.49 4247.12 2248.49 4271.12 2269.49C4293.12 2288.49 4310.12 2293.49 4354.12 2293.49C4412.12 2293.49 4439.12 2284.49 4476.12 2250.49C4509.12 2220.49 4504.12 2203.49 4462.12 2203.49C4435.12 2203.49 4421.12 2210.49 4406.12 2228.49C4368.12 2276.49 4314.12 2251.49 4325.12 2191.49C4330.12 2164.49 4331.12 2164.49 4422.12 2161.49L4513.12 2158.49L4516.12 2112.49C4521.12 2042.49 4498.12 2020.49 4417.12 2016.49C4365.12 2013.49 4347.12 2016.49 4320.12 2033.49ZM4436.12 2090.49C4439.12 2123.49 4439.12 2123.49 4394.12 2123.49C4344.12 2123.49 4337.12 2113.49 4364.12 2075.49C4375.12 2059.49 4388.12 2053.49 4406.12 2055.49C4428.12 2057.49 4434.12 2064.49 4436.12 2090.49Z" />
        <path fill="currentColor" d="M5092.12 2023.49C5070.12 2032.49 5028.12 2079.49 5028.12 2094.49C5028.12 2099.49 5045.12 2103.49 5067.12 2103.49C5098.12 2103.49 5109.12 2098.49 5122.12 2077.49C5146.12 2041.49 5183.12 2047.49 5183.12 2088.49C5183.12 2117.49 5181.12 2118.49 5123.12 2129.49C5027.12 2146.49 4998.12 2171.49 4998.12 2238.49C4998.12 2288.49 5061.12 2310.49 5120.12 2279.49C5143.12 2267.49 5147.12 2267.49 5152.12 2279.49C5155.12 2288.49 5172.12 2293.49 5199.12 2293.49C5233.12 2293.49 5239.12 2290.49 5234.12 2277.49C5230.12 2268.49 5237.12 2226.49 5249.12 2183.49C5275.12 2086.49 5272.12 2043.49 5238.12 2026.49C5210.12 2012.49 5124.12 2010.49 5092.12 2023.49ZM5159.12 2206.49C5145.12 2242.49 5116.12 2256.49 5092.12 2239.49C5065.12 2219.49 5087.12 2177.49 5130.12 2165.49C5168.12 2155.49 5174.12 2165.49 5159.12 2206.49Z" />
        <path fill="currentColor" d="M5319.12 2138.49C5302.12 2206.49 5288.12 2269.49 5288.12 2278.49C5288.12 2289.49 5299.12 2293.49 5327.12 2293.49H5366.12L5388.12 2204.49C5413.12 2100.49 5436.12 2068.49 5479.12 2077.49C5498.12 2081.49 5508.12 2078.49 5508.12 2070.49C5508.12 2064.49 5511.12 2048.49 5514.12 2035.49C5519.12 2017.49 5516.12 2013.49 5497.12 2013.49C5485.12 2013.49 5464.12 2023.49 5451.12 2035.49L5428.12 2056.49V2035.49C5428.12 2016.49 5422.12 2013.49 5389.12 2013.49H5350.12L5319.12 2138.49Z" />
        <path fill="currentColor" d="M5539.12 2050.49C5533.12 2071.49 5511.12 2159.49 5490.12 2245.49L5450.12 2403.49H5494.12C5541.12 2403.49 5543.12 2399.49 5564.12 2295.49C5570.12 2265.49 5572.12 2264.49 5591.12 2278.49C5625.12 2302.49 5682.12 2297.49 5717.12 2268.49C5772.12 2221.49 5801.12 2075.49 5763.12 2033.49C5741.12 2009.49 5698.12 2007.49 5658.12 2028.49C5631.12 2042.49 5628.12 2042.49 5628.12 2028.49C5628.12 2017.49 5617.12 2013.49 5588.12 2013.49C5551.12 2013.49 5548.12 2015.49 5539.12 2050.49ZM5692.12 2075.49C5706.12 2098.49 5682.12 2197.49 5654.12 2230.49C5637.12 2249.49 5626.12 2254.49 5610.12 2249.49C5584.12 2240.49 5582.12 2220.49 5603.12 2143.49C5623.12 2066.49 5666.12 2034.49 5692.12 2075.49Z" />
        <path fill="currentColor" d="M5891.12 2028.49C5850.12 2045.49 5817.12 2108.49 5811.12 2182.49C5804.12 2262.49 5824.12 2285.49 5905.12 2291.49C5978.12 2296.49 6031.12 2275.49 6054.12 2232.49L6069.12 2203.49H6026.12C5991.12 2203.49 5981.12 2207.49 5973.12 2225.49C5961.12 2252.49 5910.12 2262.49 5897.12 2241.49C5892.12 2234.49 5888.12 2213.49 5888.12 2195.49V2163.49H5982.12H6076.12L6082.12 2129.49C6090.12 2088.49 6077.12 2050.49 6046.12 2029.49C6018.12 2009.49 5935.12 2008.49 5891.12 2028.49ZM6004.12 2077.49C6007.12 2091.49 6007.12 2107.49 6003.12 2112.49C5997.12 2123.49 5908.12 2128.49 5908.12 2118.49C5908.12 2114.49 5915.12 2099.49 5923.12 2083.49C5944.12 2043.49 5994.12 2040.49 6004.12 2077.49Z" />
        <path fill="currentColor" d="M6838.12 2020.49C6812.12 2030.49 6778.12 2066.49 6778.12 2085.49C6778.12 2115.49 6841.12 2110.49 6862.12 2077.49C6886.12 2041.49 6923.12 2047.49 6923.12 2088.49C6923.12 2114.49 6919.12 2118.49 6883.12 2125.49C6794.12 2142.49 6768.12 2154.49 6753.12 2183.49C6711.12 2264.49 6776.12 2323.49 6860.12 2279.49C6883.12 2267.49 6887.12 2267.49 6892.12 2279.49C6895.12 2288.49 6912.12 2293.49 6939.12 2293.49C6970.12 2293.49 6979.12 2290.49 6975.12 2279.49C6972.12 2271.49 6978.12 2230.49 6989.12 2188.49C7015.12 2089.49 7012.12 2043.49 6978.12 2026.49C6953.12 2013.49 6866.12 2010.49 6838.12 2020.49ZM6908.12 2178.49C6908.12 2210.49 6865.12 2255.49 6843.12 2247.49C6832.12 2242.49 6828.12 2231.49 6830.12 2209.49C6833.12 2181.49 6853.12 2165.49 6906.12 2154.49C6907.12 2153.49 6908.12 2164.49 6908.12 2178.49Z" />
        <path fill="currentColor" d="M7059.12 2138.49C7042.12 2206.49 7028.12 2269.49 7028.12 2278.49C7028.12 2289.49 7039.12 2293.49 7067.12 2293.49H7106.12L7128.12 2204.49C7153.12 2102.49 7180.12 2066.49 7222.12 2077.49C7240.12 2081.49 7248.12 2079.49 7248.12 2071.49C7248.12 2064.49 7251.12 2048.49 7254.12 2035.49C7259.12 2017.49 7256.12 2013.49 7237.12 2013.49C7225.12 2013.49 7204.12 2023.49 7191.12 2035.49L7168.12 2056.49V2035.49C7168.12 2016.49 7162.12 2013.49 7129.12 2013.49H7090.12L7059.12 2138.49Z" />
        <path fill="currentColor" d="M7321.12 2029.49C7276.12 2052.49 7243.12 2121.49 7243.12 2195.49C7243.12 2268.49 7259.12 2285.49 7331.12 2291.49C7398.12 2296.49 7451.12 2280.49 7478.12 2246.49C7506.12 2210.49 7503.12 2203.49 7459.12 2203.49C7429.12 2203.49 7417.12 2208.49 7404.12 2228.49C7373.12 2275.49 7313.12 2253.49 7320.12 2196.49L7323.12 2168.49L7415.12 2165.49L7507.12 2162.49L7514.12 2127.49C7522.12 2083.49 7504.12 2039.49 7472.12 2024.49C7436.12 2008.49 7358.12 2011.49 7321.12 2029.49ZM7434.12 2077.49C7443.12 2113.49 7432.12 2123.49 7382.12 2123.49C7334.12 2123.49 7333.12 2122.49 7349.12 2090.49C7373.12 2045.49 7424.12 2038.49 7434.12 2077.49Z" />
        <path fill="currentColor" d="M7980.12 2025.49C7983.12 2032.49 7993.12 2062.49 8002.12 2091.49L8020.12 2143.49L7980.12 2191.49C7958.12 2217.49 7931.12 2247.49 7919.12 2257.49C7889.12 2284.49 7893.12 2293.49 7936.12 2293.49C7969.12 2293.49 7979.12 2287.49 8008.12 2251.49L8042.12 2209.49L8056.12 2251.49C8069.12 2291.49 8072.12 2293.49 8109.12 2293.49C8130.12 2293.49 8148.12 2290.49 8148.12 2286.49C8148.12 2282.49 8138.12 2249.49 8126.12 2212.49L8105.12 2145.49L8161.12 2081.49L8218.12 2018.49L8181.12 2015.49C8149.12 2012.49 8140.12 2016.49 8112.12 2050.49L8080.12 2088.49L8071.12 2053.49C8062.12 2021.49 8059.12 2018.49 8019.12 2015.49C7986.12 2013.49 7977.12 2015.49 7980.12 2025.49Z" />
        <path fill="currentColor" d="M8249.12 2050.49C8243.12 2071.49 8221.12 2159.49 8200.12 2245.49L8160.12 2403.49H8203.12H8246.12L8263.12 2333.49L8280.12 2264.49L8304.12 2280.49C8332.12 2298.49 8398.12 2293.49 8424.12 2272.49C8476.12 2229.49 8508.12 2071.49 8473.12 2033.49C8452.12 2009.49 8405.12 2007.49 8366.12 2027.49C8342.12 2040.49 8338.12 2040.49 8338.12 2027.49C8338.12 2017.49 8326.12 2013.49 8298.12 2013.49C8261.12 2013.49 8258.12 2015.49 8249.12 2050.49ZM8398.12 2068.49C8417.12 2091.49 8394.12 2196.49 8360.12 2235.49C8341.12 2258.49 8309.12 2258.49 8301.12 2236.49C8291.12 2210.49 8318.12 2100.49 8341.12 2075.49C8365.12 2049.49 8381.12 2047.49 8398.12 2068.49Z" />
        <path fill="currentColor" d="M8592.12 2032.49C8552.12 2056.49 8525.12 2113.49 8520.12 2185.49C8516.12 2236.49 8518.12 2244.49 8542.12 2268.49C8567.12 2293.49 8574.12 2295.49 8640.12 2291.49C8707.12 2287.49 8712.12 2285.49 8747.12 2248.49L8783.12 2208.49L8738.12 2205.49C8712.12 2203.49 8692.12 2206.49 8690.12 2212.49C8682.12 2236.49 8654.12 2254.49 8629.12 2251.49C8606.12 2248.49 8603.12 2243.49 8600.12 2205.49L8597.12 2163.49H8692.12H8788.12V2109.49C8788.12 2062.49 8784.12 2052.49 8762.12 2034.49C8727.12 2006.49 8636.12 2005.49 8592.12 2032.49ZM8706.12 2069.49C8725.12 2103.49 8709.12 2123.49 8662.12 2123.49C8614.12 2123.49 8608.12 2112.49 8634.12 2075.49C8652.12 2049.49 8693.12 2046.49 8706.12 2069.49Z" />
        <path fill="currentColor" d="M9237.12 2042.49C9188.12 2092.49 9203.12 2144.49 9277.12 2177.49C9319.12 2196.49 9331.12 2213.49 9322.12 2238.49C9313.12 2261.49 9257.12 2258.49 9251.12 2234.49C9246.12 2215.49 9221.12 2203.49 9186.12 2203.49C9157.12 2203.49 9164.12 2248.49 9196.12 2273.49C9219.12 2292.49 9233.12 2294.49 9290.12 2291.49C9366.12 2287.49 9394.12 2269.49 9404.12 2218.49C9412.12 2172.49 9395.12 2147.49 9338.12 2118.49C9286.12 2092.49 9275.12 2070.49 9306.12 2054.49C9327.12 2043.49 9358.12 2057.49 9358.12 2078.49C9358.12 2088.49 9369.12 2093.49 9393.12 2093.49C9423.12 2093.49 9428.12 2090.49 9428.12 2069.49C9428.12 2032.49 9393.12 2013.49 9325.12 2013.49C9274.12 2013.49 9263.12 2017.49 9237.12 2042.49Z" />
    </svg>
</template>
