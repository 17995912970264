<template>
    <div class="action-modal-wrapper">
        <div class="action-modal-wrapper__background" @click="close"></div>
        <div class="action-modal">
            <div class="action-modal__content">
                <h3 v-if="title">{{ title }}</h3>
                <p v-if="message">{{ message }}</p>
                <div class="action-modal__component">
                    <component
                        v-if="component"
                        :is="component"
                        v-model="componentProps"
                        @change="updateProps"
                    />
                </div>
            </div>
            <div class="action-modal__actions">
                <button
                    :key="action"
                    :class="getActionClassList(action)"
                    @click="doAction(action)"
                    v-for="action in actions"
                >
                {{ action.text }}
                </button>
            </div>
        </div>
    </div>
</template>
<script>
    import { defineAsyncComponent, shallowRef } from 'vue'
    import { useForm } from '@inertiajs/inertia-vue3'

    export default {
        data() {
            return {
                title: '',
                message: '',
                component: null,
                componentProps: {},
                actions: [],
                visible: false
            }
        },
        mounted() {
            this.updateBody()
            this.ebus.on('modal:close', this.close)
            this.ebus.on('modal:open', this.open)
        },
        watch: {
            visible() {
                this.updateBody()
            }
        },
        methods: {
            getActionClassList(action) {
                let classList = ['button-primary']

                if(action.type && action.type == 'secondary') {
                    classList = ['button-secondary']
                } else if(action.type && action.type == 'outline') {
                    classList = ['button-primary-outline']
                }

                return classList.join(' ')
            },
            doAction(action) {
                if(action.method) {
                    this[action.method](action, this.componentProps)
                }
            },
            updateBody() {
                const action = this.visible ? 'add' : 'remove'
                document.body.classList[action]('action-modal-active')
            },
            open(data) {
                window.scrollTo(0, 0)
                const entries = Object.entries(data)

                // This could probably be safer maybe remove the component
                // and it's props before adding the other key values
                if(entries.length) {
                    for(const [key, value] of entries) {
                        if(key !== 'component') {
                            this[key] = value
                        } else {
                            const promise = import(`@/Components/${value}`)
                            this.component = shallowRef(defineAsyncComponent(() => promise))
                        }
                    }
                }
                this.visible = true
            },
            close() {
                this.visible = false
                this.component = null
                this.componentProps = {}
            },
            post(action, props) {

                if(action.href === undefined) {
                    console.error('Without an href this form will not post.')
                    return
                }

                let data = {}

                if(action.componentProps) {
                    data = { ...data, ...action.componentProps.data() }
                }

                if(props) {
                    data = { ...data, ...props }
                }

                console.log(data)

                const form = useForm(data)

                form.post(action.href, {
                    preserveScroll: true,
                    onSuccess: () => {
                        this.close()
                    },
                    onError: this.onError
                })
            },
            onError() {
            },
            updateProps(props) {
                const entries = Object.entries(props)

                if(entries.length) {
                    for(const [key, value] of entries) {
                        this.componentProps[key] = value
                    }
                }
            }
        },
    }
</script>
<style>

    .action-modal-wrapper {
        display: none;
        pointer-events: none;
        position: absolute;
        z-index: 1000;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.5);
    }

    .action-modal-wrapper__background {
        position: absolute;
        z-index: 1005;
        width: 100%;
        height: 100%;
    }

    .action-modal {
        position: relative;
        z-index: 1010;
        padding: 20px;
        border-radius: var(--default-border-radius);
        background-color: var(--default-white);
    }

    .action-modal__actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .action-modal__actions button + button {
        margin-left: 1em;
    }

    .action-modal__content {
        min-width: 360px;
        padding-bottom: 20px;
    }

    .action-modal__content h3 {
        margin-top: 0;
    }

    .action-modal__component {
        margin-top: 20px;
    }

    body.action-modal-active {
        overflow: hidden;
        max-height: 100vh;
    }

    body.action-modal-active .action-modal-wrapper {
        display: flex;
        pointer-events: all;
    }

    body.action-modal-active nav,
    body.action-modal-active aside,
    body.action-modal-active main {
        filter: blur(2px);
    }

</style>
