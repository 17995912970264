<template>
    <div class="checkbox">
        <label class="checkbox-label">
            <input
                type="checkbox"
                @change="handle"
                v-model="checked"
            />
            <div class="checkbox-faux">
                <CheckIconSharp v-if="checked" />
            </div>
            <span class="label">{{ label }}</span>
        </label>
        <p class="checkbox-error error" v-if="error">{{ error }}</p>
    </div>
</template>

<script>
    import CheckIconSharp from '@/Icons/CheckIconSharp'

    export default {
        components: {
            CheckIconSharp
        },
        props:{
            error: {
                type: String,
                default: ''
            },
            label: {
                type: String,
                default: ''
            },
            modelValue: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                checked: this.modelValue
            }
        },
        methods: {
            handle() {
                this.$emit('update:modelValue', this.checked)
            }
        }
    }
</script>
<style>

.checkbox {
    position: relative;
}

.checkbox input {
    position: absolute;
    opacity: 0;
}

.checkbox-label {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.checkbox-label span {
    margin-left: 1em;
    color: var(--default-text-color);
}

.checkbox-faux {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    background-color: rgba(0, 0, 0, 0.33);
    border-radius: var(--default-border-radius);
}

.checkbox-faux svg {
    width: 40%;
    color: var(--default-white);
}
</style>
