
export default {
    props: {
        workshops: {
            type: Object,
            default: () => {}
        }
    },
    methods: {
        getWorkshopLink: id => route('workshops.show', id)
    }
}
