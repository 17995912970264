<template>
<svg viewBox="0 0 15 20">
<path fill="currentColor" d="M15,7.5c0,3.024-1.053,3.868-6.729,12.096c-0.373,0.538-1.169,0.538-1.542,0C1.054,11.368,0,10.524,0,7.5
	C0,3.358,3.358,0,7.5,0S15,3.358,15,7.5z M13.75,7.5c0-1.669-0.65-3.239-1.831-4.419C10.739,1.9,9.169,1.25,7.5,1.25
	S4.261,1.9,3.081,3.081C1.9,4.261,1.25,5.831,1.25,7.5c0,2.519,0.809,3.144,6.25,11.013C12.942,10.643,13.75,10.019,13.75,7.5z
	 M11.25,7.5c0,2.068-1.682,3.75-3.75,3.75c-2.068,0-3.75-1.682-3.75-3.75c0-2.068,1.682-3.75,3.75-3.75
	C9.568,3.75,11.25,5.432,11.25,7.5z M10,7.5C10,6.121,8.879,5,7.5,5C6.121,5,5,6.121,5,7.5C5,8.879,6.121,10,7.5,10
	C8.879,10,10,8.879,10,7.5z"/>
</svg>
</template>
