<template>
<svg viewBox="0 0 12 12">
<path fill="currentColor" d="M12,1.5v6.75c0,0.827-0.673,1.5-1.5,1.5H7.125l-2.927,2.194C4.146,11.984,4.088,12,4.031,12
	c-0.145,0-0.281-0.115-0.281-0.281V9.75H1.5C0.673,9.75,0,9.077,0,8.25V1.5C0,0.673,0.673,0,1.5,0h9C11.327,0,12,0.673,12,1.5z
	 M11.25,1.5c0-0.412-0.338-0.75-0.75-0.75h-9c-0.412,0-0.75,0.338-0.75,0.75v6.75C0.75,8.662,1.088,9,1.5,9h3v1.781L6.675,9.15
	L6.874,9H10.5c0.412,0,0.75-0.338,0.75-0.75V1.5z M9,3.562v0.375c0,0.103-0.084,0.188-0.188,0.188H3.188
	C3.084,4.125,3,4.041,3,3.938V3.562c0-0.103,0.084-0.188,0.188-0.188h5.625C8.916,3.375,9,3.459,9,3.562z M6.75,5.812v0.375
	c0,0.103-0.084,0.188-0.188,0.188H3.188C3.084,6.375,3,6.291,3,6.188V5.812c0-0.103,0.084-0.188,0.188-0.188h3.375
	C6.666,5.625,6.75,5.709,6.75,5.812z"/>
</svg>
</template>
