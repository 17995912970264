<template>
    <div :class="classList">
        <div class="workshop-cell-image">
            <MarkerIcon />
        </div>
        <div class="workshop-cell-data">
            <span class="workshop-cell-title">{{ name }}</span>
        </div>
    </div>
</template>
<script>

import MarkerIcon from '@/Icons/MarkerIcon'

export default {
    components: {
        MarkerIcon,
    },
    props: {
        extra: {
            type: Object,
            default: {}
        },
        value: {
            type: Object,
            required: true,
            default: {}
        }
    },
    data() {
        return {
            ...this.value,
            ...this.extra
        }
    },
    computed: {
        classList() {
            const list = ['workshop-cell']

            return  list.join(' ')
        }
    }
}
</script>
<style scoped>

    .workshop-cell {
        display: flex;
        align-items: center;
    }

    .workshop-cell.priority {
        color: var(--default-primary);
    }

    .workshop-cell-image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        margin-right: 20px;
        border-radius: 50%;
        background-color: var(--default-secondary);
    }

    .workshop-cell svg {
        height: 55%;
        color: var(--default-white);
    }

    .workshop-cell span {
    }

    .workshop-cell-data {
        display: flex;
        flex-direction: column;
    }

    .workshop-cell-title {
        font-weight: 900;
    }

    .workshop-cell-meta {
        display: flex;
        align-items: center;
        margin-bottom: .5em;
        font-size: 9px;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 0.125em;
    }

    .workshop-cell-meta svg {
        width: 1.5em;
        color: inherit;
        margin-right: .5em;
    }

</style>
