export default {
    props: {
        title: {
            type: String,
            default: 'Page title',
        },
        links: {
            type: Array,
            default: () => [],
        },
        table: {
            type: Object,
            required: true,
            default: {}
        },
    },
}
