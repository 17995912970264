<template>
<svg viewBox="0 0 12 11.922">
<path fill="currentcolor" d="M1.316,8.116L1.183,8.25C1.062,8.371,0.862,8.357,0.759,8.22C0.283,7.591,0,6.809,0,5.961
	c0-2.068,1.682-3.75,3.75-3.75h5.657L7.809,0.613c-0.11-0.11-0.11-0.288,0-0.398l0.133-0.133c0.11-0.11,0.288-0.11,0.398,0
	l2.305,2.305c0.11,0.11,0.11,0.288,0,0.398L8.339,5.09c-0.11,0.11-0.288,0.11-0.398,0L7.809,4.957c-0.11-0.11-0.11-0.288,0-0.398
	l1.598-1.598H3.75c-1.654,0-3,1.346-3,3c0,0.669,0.22,1.288,0.592,1.788C1.426,7.861,1.415,8.017,1.316,8.116z M8.25,9.711H2.593
	l1.598,1.598c0.11,0.11,0.11,0.288,0,0.398L4.059,11.84c-0.11,0.11-0.288,0.11-0.398,0L1.356,9.535c-0.11-0.11-0.11-0.288,0-0.398
	l2.305-2.305c0.11-0.11,0.288-0.11,0.398,0l0.133,0.133c0.11,0.11,0.11,0.288,0,0.398L2.593,8.961H8.25c1.654,0,3-1.346,3-3
	c0-0.669-0.22-1.288-0.592-1.788c-0.084-0.112-0.073-0.269,0.026-0.368l0.134-0.134c0.121-0.121,0.321-0.107,0.424,0.03
	C11.717,4.331,12,5.114,12,5.961C12,8.029,10.318,9.711,8.25,9.711z"/>
</svg>
</template>
