<template>
    <Layout>
        <Titlebar title="Users" :links="links" />
        <Table :table="table" :row_action="showUser" />
    </Layout>
</template>

<script>
    import { links, table } from '@/Mixins'
    import Table from '@/Components/Table'

    export default {
        mixins: [links, table],
        components: {
            Table
        },
        methods: {
            showUser(id) {
                const link = route('users.show', id);
                this.$inertia.visit(link);
            }
        }
    }
</script>
