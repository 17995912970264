<template>
    <div class="keypad">
        <div class="keypad-pin">{{ resolved }}</div>
        <div class="keypad-buttons">
            <button
                v-for="button in buttons"
                class="keyspace"
                @click="update"
                :value="button.value"
            >
                <span>{{ button.value }}</span>
            </button>
        </div>
        <div
            class="keypad-clear"
            @click="clear"
        >
            <RefreshIcon /> Reset
        </div>
    </div>
</template>
<script>
    import RefreshIcon from '@/Icons/RefreshIcon'

    export default {
        components: {
            RefreshIcon
        },
        data() {

            let buttons = []

            for(let i = 0; i < 10; i++) {
                buttons.push({ value: i })
            }

            buttons.push(buttons.shift())

            return {
                pin: [],
                buttons
            }
        },
        computed: {
            resolved() {
                return this.pin.map(v => '*').join('')
            }
        },
        methods: {
            update(e) {
                if(this.pin.length < 4) {
                    const v = e.currentTarget.value
                    this.pin.push(v)
                    this.$emit('update', this.pin.join(''))
                }
            },
            reset() {
                this.pin = []
            },
            clear(e) {
                this.pin = []
                this.$emit('clear')
            }
        }
    }
</script>

<style>

    .keypad {
        --keysize: 70px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 340px;
    }

    .keypad-pin {
        min-height: 32px;
        font-size: 24px;
        color: var(--default-white);
        letter-spacing: .5em;
        text-align: center;
        margin-left: .5em;
        margin-bottom: 1em;
        margin-top: 1em;
    }

    .keypad-buttons {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .keyspace {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-basis: calc(33.33% - 20px);
        border: none;
        margin: 10px;
        background: transparent;
    }

    .keypad span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: var(--keysize); height: var(--keysize);
        font-size: 24px;
        font-weight: 300;
        color: rgba(255, 255, 255, .66);
        border-radius: 50%;
        cursor: pointer;
        background-color: rgba(0, 0, 0, .15);
    }

    .keypad-clear {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        color: rgba(255, 255, 255, .66);
        margin-top: 60px;
        cursor: pointer;
    }

    .keypad-clear svg {
        width: 24px;
        margin-right: 1em;
    }
</style>
