<template>
<svg viewBox="0 0 13.619 15.565">
<path fill="currentColor" d="M13.619,12.841v1.265c0,0.806-0.654,1.459-1.459,1.459H1.459C0.654,15.565,0,14.911,0,14.106v-1.265
	c0-2.256,1.83-4.086,4.086-4.086c0.876,0,1.292,0.486,2.724,0.486c1.432,0,1.851-0.486,2.724-0.486
	C11.789,8.755,13.619,10.585,13.619,12.841z M12.646,12.841c0-1.718-1.395-3.113-3.113-3.113c-0.596,0-1.192,0.486-2.724,0.486
	c-1.535,0-2.128-0.486-2.724-0.486c-1.718,0-3.113,1.395-3.113,3.113v1.265c0,0.268,0.219,0.486,0.486,0.486H12.16
	c0.268,0,0.486-0.219,0.486-0.486V12.841z M2.918,3.891C2.918,1.742,4.66,0,6.81,0c2.149,0,3.891,1.742,3.891,3.891
	c0,2.149-1.742,3.891-3.891,3.891C4.66,7.782,2.918,6.04,2.918,3.891z M3.891,3.891c0,1.608,1.31,2.918,2.918,2.918
	c1.608,0,2.918-1.31,2.918-2.918S8.418,0.973,6.81,0.973C5.201,0.973,3.891,2.283,3.891,3.891z"/>
</svg>
</template>
