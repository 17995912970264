<template>
    <div class="dashboard-column">
        <h2>{{ title }} <span>{{ count }}</span></h2>
        <div class="dashboard-table">
            <div class="dashboard-table-row dashboard-table-heading">
                <div class="workshop">Workshop</div>
                <div class="date">Date</div>
                <div class="store">Store</div>
                <div class="machine">Machine ID</div>
                <div class="action"> </div>
            </div>
            <div class="dashboard-table-row" :class="{priority: job.priority}" v-for="job in rows" @click="openModal(job)">
                <div class="workshop">
                    <span class="dot" :class="jobClasses(job)"></span>
                    <span>{{ job.dashboardWorkshop }}</span>
                </div>
                <div class="date">{{ job.created_at }}</div>
                <div class="store">{{ job.store.name }}</div>
                <div class="machine">{{ job.machine.identifier }}</div>
                <div class="action">
                    <PencilIcon />
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import PencilIcon from "../Icons/PencilIcon";

export default {
    mixins: [],
    components: {PencilIcon},
    props: {
        title: {
            type: String,
            default: ""
        },
        rows: {
            type: Array,
            default: []
        },
    },
    data() {
        return {
        }
    },
    mounted() {
        // this.setSort()
    },
    computed: {
        count() {
            return this.rows.length;
        }
    },
    methods: {
        openModal(job) {
            this.ebus.emit('modal:open', job.modal)
        },
        jobClasses(job) {
            const classes = ['']

            if (job.dashboard_status == 2) {
                classes.push('dot-orange')
            } else if (job.dashboard_status == 3) {
                classes.push('dot-yellow')
            } else if (job.dashboard_status == 5) {
                classes.push('dot-green')
            } else {
                if(job.dashboard_location == 2) {
                    classes.push('dot-pink')
                } else if(job.dashboard_location == 4) {
                    classes.push('dot-pink')
                } else if(job.dashboard_location == 6) {
                    classes.push('dot-pink')
                }
            }

            return classes.join(' ')
        }
    }
}
</script>
<style scoped>
.dashboard-column {
    flex: 0 0 33%;
    padding: 0 20px;
}

.dashboard-column h2 {
    color: var(--default-contrast);
    font-size: 1.25em;
}

.dashboard-column h2 span {
    color: var(--default-contrast);
    background-color: var(--default-light);
    padding: 5px 9px;
    border-radius: 50px;
    font-size: 16px;
    margin-left: 10px;
    display: inline-block;
}

.dashboard-table-row {
    display: flex;
    align-items: center;
    padding: 12px 15px;
    margin-bottom: 4px;
    background-color: var(--default-off-white);
    border-radius: 5px;
    transition: background-color var(--transition-delay) var(--transition-timing-function);
}

.dashboard-table-row:not(.dashboard-table-heading):hover {
    background-color: var(--default-light);
    cursor: pointer;
}

.dashboard-table-row div {
    flex: 1 1 15%;
    display: flex;
}

.dashboard-table-row.priority .workshop,
.dashboard-table-row.priority .date,
.dashboard-table-row.priority .store,
.dashboard-table-row.priority .machine {
    color: var(--default-primary);
}


.dashboard-table-row .store {
    flex-basis: 45%;
}



.dashboard-table-row .workshop {
    font-weight: bold;
}

.dashboard-table-row .action {
    flex-basis: 60px;
    justify-content: flex-end;
}

.dashboard-table-heading {
    padding: 8px 15px;
    margin-bottom: 10px;
    background: none;
}

.dashboard-table-heading div {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 1px;
    color: var(--default-grey);
}

.dashboard-table span {
    margin-right: 10px;
    display: inline-block;
}

.dashboard-table .dot {
    width: 18px;
    height: 18px;
    border-radius: 9px;
    display: inline-block;
    background-color: var(--default-off-white);
}

.dashboard-table .dot-priority {
    background-color: var(--default-primary);
}

.dashboard-table .dot-orange {
    background-color: var(--default-warning);
}

.dashboard-table .dot-pink {
    background-color: var(--default-dash-pink);
}

.dashboard-table .dot-green {
    background-color: var(--default-dash-green);
}

.dashboard-table .dot-purple {
    background-color: var(--default-dash-purple);
}

.dashboard-table .dot-blue {
    background-color: var(--default-dash-blue);
}

.dashboard-table .dot-contrast {
    background-color: var(--default-contrast);
}

.dashboard-table .dot-yellow {
    background-color: var(--default-dash-yellow);
}

.dashboard-table .action svg {
    width: 14px;
    color: var(--default-grey-light);
}

</style>
