<template>
<div class="checklist-card">
    <div class="checklist-icon">
        <ChecklistIcon />
    </div>
    <p class="checklist-title">{{ checklist.title }}</p>
</div>
</template>
<script>
    import ChecklistIcon from '@/Icons/ChecklistIcon'

    export default {
        components: {
            ChecklistIcon
        },
        props: {
            checklist: {
                type: Object,
                default: {}
            }
        },
        data() {
            return {
            }
        }
    }
</script>

<style scoped>

    .checklist-title {
        font-size: 20px;
        margin-top: 30px;
        font-weight: 800;
        text-align: center;
    }

    .checklist-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 60px;
    }

    .checklist-icon {
        width: 60px;
    }
</style>
