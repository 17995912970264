<template>
    <Constrained>
        <Titlebar title="New User" />

        <Field label="Name">
            <input v-model="form.name" type="text" />
            <p class="error" v-if="$attrs.errors.name">{{ $attrs.errors.name}}</p>
        </Field>

        <Field label="Roles">
            <multiselect
                id="roles"
                placeholder="Which roles does this user belong to"
                label="name"
                mode="tags"
                valueProp="id"
                v-model="form.roles"
                :options="roles"
            ></multiselect>
            <p class="error" v-if="$attrs.errors.roles">{{ $attrs.errors.roles }}</p>
        </Field>

        <Field
            label="Workshops"
            v-if="needsWorkshop"
        >
            <multiselect
                id="workshops"
                placeholder="Which workshops is this use asigned to"
                label="name"
                mode="tags"
                valueProp="id"
                v-model="form.workshops"
                :options="workshops"
            ></multiselect>
            <p class="error" v-if="$attrs.errors.workshops">{{ $attrs.errors.workshops }}</p>
        </Field>

        <div class="field-group">
            <Field label="Email">
                <input v-model="form.email" type="email" />
                <p class="error" v-if="$attrs.errors.email">{{ $attrs.errors.email }}</p>
            </Field>
            <Field label="Phone">
                <input v-model="form.phone" type="text" />
                <p class="error" v-if="$attrs.errors.phone">{{ $attrs.errors.phone }}</p>
            </Field>
        </div>

        <Field label="Pin">
            <input
                v-model="form.password"
                type="text"
                maxlength="4"
                minlength="4"
                @keydown="numberOnly"
            />
            <p class="error" v-if="$attrs.errors.password">{{ $attrs.errors.password }}</p>
        </Field>

<!--        <Field>
            <Checkbox
                label="Recieves Mail"
                v-model="form.receives_mail"
                :error="$attrs.errors.receives_mail"
            />
        </Field>-->

        <template #actions>
            <FormActions
                :actions="actions"
                :setAction="setAction"
                :submit="submit"
            />
        </template>
    </Constrained>
</template>

<script>
    import { form, roles, workshops } from '@/Mixins'
    import FieldInline from '@/Components/FieldInline'

    export default {
        mixins: [form, roles, workshops],
        components: {
            FieldInline,
        },
        data() {
            return {
                form: {
                    name: '',
                    email: '',
                    phone: '',
                    password: '',
                    receives_mail: false,
                    workshops: [],
                    roles: []
                }
            }
        },
        computed: {
            needsWorkshop() {
                let needs = true

                const roles = this.roles.filter(role => {
                    return role.name === 'admin' || role.name === 'support'
                }).map(r => (r.id))

                this.form.roles.forEach(role => {
                    if(roles.indexOf(role) > -1) {
                        needs = false
                    }
                })

                return needs;
            }
        },
        methods: {
            numberOnly(e) {
                const reg = new RegExp('^[0-9]+$')
                const result = reg.test(e.key)

                if(!result) {
                    e.preventDefault()
                }
            }
        }
    }
</script>
