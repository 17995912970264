<template>
    <Layout>
        <template #sidebar>
            <ChecklistCard :checklist="checklist" />
        </template>

        <Titlebar title="Edit checklist" />

        <div class="content-block">

            <div class="field-inline">
                <label>List name</label>
                <input type="text" v-model="form.title" />
            </div>

            <div class="field-inline">
                <label>Show this checklist when:</label>
                <multiselect
                    id="statuses"
                    placeholder="Select a step to show this checklist on"
                    mode="tags"
                    v-model="form.statuses"
                    :options="statuses"
                ></multiselect>
            </div>

            <div class="field-inline field-checkboxes">
                <label>Machine Type:</label>
                <div
                    :key="type"
                    v-for="type in machineTypes"
                >
                    <label :for="type.id">{{ $filters.capitalize(type.name)}}</label>
                    <input
                        :id="type.id"
                        :value="type.id"
                        name="machine_type"
                        type="radio"
                        v-model="form.machine_type_id"
                    >
                </div>
                <p v-if="$attrs.errors.machine_type_id">{{ $attrs.errors.machine_type_id }}</p>
            </div>
        </div>

        <div class="content-block">
            <h3>Steps</h3>
            <draggable
                :list="form.items"
                item-key="id"
                class="list"
                ref="list"
            >
                <template #item="{element}">
                    <div class="list-item">
                        <div class="list-item-index">Step {{ getIndex(element) }}.</div>
                        <div class="list-item-content">
                            <input placeholder="Step Title" v-model="element.title" type="text" />
                            <textarea placeholder="Step Content" v-model="element.content"></textarea>
                        </div>
                        <div class="list-item-actions">
                            <button @click="focus(element)"><PencilIcon /></button>
                            <button><UpdownIcon /></button>
                            <button @click="remove(element)"><TrashIcon /></button>
                        </div>
                    </div>
                </template>
            </draggable>

            <div class="add-button">
                <button
                    type="button"
                    @click="addNew"
                    class="button-section"
                >
                <ListIcon />
                Add a step
                </button>
            </div>
        </div>


        <template #actions>
            <FormActions
                :actions="actions"
                :setAction="setAction"
                :submit="submit"
            />
        </template>
    </Layout>
</template>
<script>
    import { form, statuses, machineTypes, checklist } from '@/Mixins'
    import ChecklistCard from '@Cards/ChecklistCard'
    import ListIcon from '@/Icons/ListIcon'
    import TrashIcon from '@/Icons/TrashIcon'
    import UpdownIcon from '@/Icons/UpdownIcon'
    import PencilIcon from '@/Icons/PencilIcon'
    import draggable from 'vuedraggable'

    export default {
        mixins: [checklist, statuses, machineTypes, form],
        components: {
            ChecklistCard,
            ListIcon,
            TrashIcon,
            UpdownIcon,
            PencilIcon,
            draggable
        },
        data() {
            return {
                form: {
                    statuses: this.checklist.statuses,
                    title: this.checklist.title,
                    machine_type_id: this.checklist.machine_type_id,
                    items: this.checklist.items ? this.checklist.items : []
                },
            }
        },
        methods: {
            addNew() {
                this.form.items.push({title: '', content: ''})
            },
            getIndex(element) {
                return this.form.items.indexOf(element) + 1
            },
            focus(element) {
                const index = this.getIndex(element) - 1
                const item = this.$refs.list.targetDomElement.children[index]
                item.querySelector('input').focus()
            },
            remove(element) {
                const index = this.getIndex(element) - 1
                this.form.items.splice(index, 1)
            }
        }
    }
</script>

<style scoped>

    .list-item {
        display: flex;
        margin-top: 15px;
        /* padding: 12px 14px; */
        /* background-color: var(--default-light);*/
    }

    /*.list-item + .list-item {
        margin-top: 4px;
    }*/

    .list-item input,
    .list-item textarea {
        width: 100%;
    }

    .list-item input {
        /*width: 100%;*/
        font-weight: 900;
        background-color: var(--default-light);
        margin-bottom: .5em;
        padding: 10px;
        border: none;
        /*border: 1px solid var(--default-border-white);*/
    }

    .list-item textarea {
        resize: none;
        background-color: var(--default-light);
        padding: 10px;
        border: none;
        /*border: 1px solid var(--default-border-white);*/
        border-radius: 0;

    }

    .list-item-index {
        width: 120px;
    }

    .list-item-content {
        flex-grow: 1;
        margin: 0 20px 0 0;
    }

    .list-item-actions {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .list-item-actions button {
        border: none;
        cursor: pointer;
        background-color: transparent;
    }

    .list-item-actions svg {
        height: 16px;
        color: var(--default-grey);
    }

    .button-section {
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .add-button {
        margin-left: 120px;
        margin-right: 48px;
    }

</style>
