<template>
    <div class="field">
        <div class="field-label" v-if="label">
            <p class="label">{{ label }}</p>
            <p v-if="date || author" class="meta">{{ date }}<span v-if="author"> by <span class="weight-medium">{{ author }}</span></span></p>
        </div> <div class="field-input">
            <slot />
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            label: {
                type: String,
                default: ''
            },
            date: {
                type: String,
                default: ''
            },
            author: {
                type: String,
                default: ''
            }
        },
        mounted() {
            // Check if the parent is a field-group
            // If it is then check if any of the children have labels
            // If they don't return them and apply extra style
            // to that field
            if(!this.label) {
                const nolabel = Array.from(this.$el.parentNode.children).filter(el => {
                    const label = el.querySelector('.field-label')
                    return (label === null)
                })

                nolabel.forEach(el => {
                    el.classList.add('field-label-compensation')
                })
            }
        }
    }
</script>
<style>

    .field-group {
        display: flex;
        align-items: flex-start;
        width: 100%;
    }

    .field-group .field {
        flex: 0 0 calc(50% - 1em);
    }

    .field-group .field:first-child {
        margin-right: 1em;
    }

    .field-group .field:last-child {
        margin-left: 1em;
    }

    .field-group .field + .field {
        margin-top: 0;
    }

    .field + .field-group,
    .field + .content-block,
    .field-group + .field-group,
    .field-group + .field {
        margin-top: 2em;
    }

    .field + .field {
        margin-top: 2em;
    }

    .field-label {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1em;
        color: var(--default-text-color);
    }

    .field-label-compensation .field-input {
        margin-top: 2.75em;
        /* This should be automatic */
    }

    .label, .meta {
        margin: 0;
    }

    .label {
        font-weight: 800;
        font-size: 18px;
    }

    .field .error {
        margin-top: 10px;
        color: var(--default-error);
        font-size: 14px;
    }

    .field .multiselect {
        border-radius: var(--default-border-radius);
        min-height: 48px;
    }

    .field-short {
        width: 50%;
    }

    .field-has-icon {
        position: relative;
        display: flex;
        align-items: center;
    }

    .field-icon {
        position: absolute;
        z-index: 50;
        top: 0;
        right: 1em;
        display: flex;
        align-items: center;
        height: 100%;
        font-size: 18px;
        /*transform: scale(1.75, .9);*/
        color: var(--default-contrast);
    }

    .field textarea {
        min-height: 100px;
    }

    .field input[type="text"],
    .field input[type="email"],
    .field input[type="number"] {
        min-width: 50%;
        width: 100%;
        height: 48px;
        border: 0;
        padding: 0;
        padding-left: 14px;
        border-radius: var(--default-border-radius);
        background-color: var(--default-input-background);
    }

</style>
