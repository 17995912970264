<template>
    <div class="sidebar-table">
        <p v-if="title">{{ title }}</p>
        <div
            :key="item"
            v-for="item in items"
            class="sidebar-table-row"
        >
            <div class="sidebar-table-col">
                {{ item.key }}
            </div>
            <div class="sidebar-table-col">
                {{ item.value }}
            </div>
        </div>
        <div
            class="sidebar-table-total"
            v-if="total.value !== ''"
        >
            <div class="sidebar-table-col">
                {{ total.key }}
            </div>
            <div class="sidebar-table-col">
                {{ total.value }}
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            title: {
                type: String,
                default: null
            },
            items: {
                type: Array,
                default: () => {}
            },
            total: {
                type: Object,
                default: {
                    key: 'Total',
                    value: ''
                }
            }
        }
    }
</script>
<style scoped>

    .sidebar-table {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 30px;
    }

    .sidebar-table p {
        font-weight: 900;
        margin-bottom: 1em;
    }

    .sidebar-table-row {
        display: flex;
        justify-content: space-between;
        padding: 12px 14px;
        font-size: 14px;
        border-radius: var(--default-border-radius);
        background-color: var(--default-white);
    }

    .sidebar-table-row +
    .sidebar-table-row {
        margin-top: 4px;
    }

    .sidebar-table-total {
        display: flex;
        justify-content: space-between;
        padding: 12px 14px;
        margin-top: 4px;
        font-size: 15px;
        font-weight: 800;
    }

</style>
