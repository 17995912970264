<template>
    <nav v-if="navigation">
        <div class="main-nav">
            <Logo />
            <Link v-for="item in navigation"
            :key="item.name"
            :href="item.link"
            :class="{ 'active' : item.active }"
            >
                <div v-if="item.icon" v-html="item.icon" class="icon"></div>
                <img v-else src="https://via.placeholder.com/50">
                <span>{{ item.name }}</span>
            </Link>
        </div>
        <div class="auth-nav" @click="logout">
            <Avatar :name="user.name" />
        </div>
    </nav>
</template>
<script>
    import { usePage } from '@inertiajs/inertia-vue3'
    import Avatar from '@/Components/Avatar'
    import Logo from '@/Components/Logo'

    export default {
        components: {
            Logo,
            Avatar
        },
        data() {
            return {
                user: usePage().props.value.auth.user,
                navigation: usePage().props.value.navigation
            }
        },
        methods: {
            logout() {
                this.$inertia.visit('/logout')
            }
        }
    }
</script>

<style>

    nav {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        text-transform: uppercase;
        background-color: var(--default-nav-background);
        min-height: 100vh;
    }

    nav a {
        text-decoration: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 10px auto;
        padding: 10px 0;
        justify-content: center;
        color: var(--default-nav-text);
        background-color: var(--default-default-background);
        opacity: 0.5;
    }

    nav a.active {
        background-color: var(--default-primary);
        opacity: 1;
    }

    nav a span {
        margin-top: 1em;
    }

    .logo {
        display: block;
        width: var(--nav-width);
        height: var(--nav-width);
        margin-top: 10px;
        color: var(--default-light);
        transform: rotate(-90deg);
    }

    .main-nav > a {
        font-size: 9px;
        font-weight: 800;
        letter-spacing: 1px;
    }

    .icon {
        display: block;
        width: 24px;
    }

    .auth-nav {
        position: relative;
        cursor: pointer;
    }

    .auth-nav .avatar {
        display: flex;
        flex-direction: column;
        --font-size: 30px;
        margin: 0 auto;
    }

    .auth-nav .avatar .avatar-name {
        order: 0;
        position: relative;
        transform: rotate(-180deg);
    }

    .auth-nav .avatar .avatar-name span {
        writing-mode: vertical-rl;
        font-size: 14px;
        letter-spacing: 0.1em;
    }

    .auth-nav .avatar .avatar-thumbnail {
        order: 1;
    }

</style>
