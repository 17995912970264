<template>
    <div class="workshop-stats">
        <div
            :key="workshop"
            v-for="workshop in ordered_workshops"
            class="workshop-stat"
        >
            <div class="workshop-stat_title">{{ workshop.name }}</div>
            <div class="workshop-stat_types">
                <div class="workshop-stat_type">
                    <MachineIcon /> <span>{{ workshop.machine }}</span>
                </div>
                <div class="workshop-stat_type">
                    <ToolIcon /> <span>{{ workshop.tool }}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { workshops } from '@/Mixins'
    import MachineIcon from '@/Icons/MachineIcon'
    import ToolIcon from '@/Icons/ToolIcon'

    const sort_asc = (a, b) => (a > b) ? 1 : (a < b) ? -1 : 0

    export default {
        mixins: [workshops],
        components: {
            MachineIcon,
            ToolIcon,
        },
        data() {
            return {
                ordered_workshops: Object.entries(this.workshops).map(w => {
                    return w[1]
                }).sort((w1, w2) => sort_asc(w1.sort, w2.sort))
            }
        }
    }
</script>
<style>
    .workshop-stats {
        margin-right: 1em;
        display: flex;
    }

    .workshop-stat {
        text-align: center;
    }

    .workshop-stat +
    .workshop-stat {
        margin-left: 1.5em;
    }

    .workshop-stat_types {
        display: flex;
        align-items: center;
        background-color: var(--default-light);
        padding: 7px 14px;
        margin-top: .25em;
        border-radius: 50px;
    }

    .workshop-stat_type {
        display: flex;
        align-items: center;
    }

    .workshop-stat_type +
    .workshop-stat_type
    {
        margin-left: 12px;
    }

    .workshop-stat_type svg {
        width: 13px;
    }
    .workshop-stat_type span {
        padding-left: 5px;
    }
</style>
