<template>
    <Modal />
    <div :class="default">
        <Navigation v-if="!dashboardLayout" />

        <aside v-if="showSidebar">
            <slot name="sidebar" />
        </aside>
        <main>
            <div class="container">
                <slot></slot>
            </div>
            <div class="actions">
                <slot name="actions"></slot>
            </div>
            <Notifications ref="notifications" :notifications="notifications" />
        </main>
    </div>
</template>

<script>
    import Navigation from '@/Components/Navigation'
    import Notifications from '@/Components/Notifications'

    export default {
        components: {
            Navigation,
            Notifications,
        },
        props: {
            toasts: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                notifications: [],
                showUserTooltip: false,
                showSidebar: this.$slots.sidebar !== undefined,
                dashboardLayout: false
            }
        },
        mounted() {
            if(this.$page.props.flash) {
                this.notifications = this.$page.props.flash.toasts
            }
            if(this.$page.props.dashboardLayout) {
                this.dashboardLayout = this.$page.props.dashboardLayout
            }
        },
        watch: {
            '$page.props.flash'() {
                if(this.$page.props.flash) {
                    this.notifications = this.$page.props.flash.toasts
                }
            }
        },
        computed: {
            default() {
                const classes = ['default']

                if(this.showSidebar) {
                    classes.push('show-sidebar')
                } else if(this.dashboardLayout) {
                    classes.push('dashboard-layout')
                }

                return classes.join(' ')
            }
        }
    }
</script>

<style>
    @import '@vueform/multiselect/themes/default.css';

    .default {
        display: grid;
        grid-template-columns: var(--nav-width) calc(100% - var(--nav-width));
    }

    .default.show-sidebar {
        grid-template-columns: var(--nav-width) var(--sidebar-width) calc(100% - var(--nav-width) - var(--sidebar-width));
    }

    .default.dashboard-layout {
        grid-template-columns: 1fr;
    }

    .default aside {
        padding: 30px;
        background-color: var(--default-sidebar-background);
        color: var(--default-sidebar-text);
    }

    .default main {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: var(--main-padding);
    }

    .default.dashboard-layout main {
        padding: 0;
    }

    .default .actions {
        padding-top: 20px;
        display: flex;
        justify-content: flex-end;
    }

    .default .actions button + button {
        margin-left: 1em;
    }

</style>
