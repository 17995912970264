<template>
    <Layout>
        <template #sidebar>
            <MachineCard :machine="job.machine" :showLink="true" />
            <JobTracker :steps="steps" />
        </template>

        <Titlebar title="Job Info" />

        <EditableField
            label="Internal notes"
            :editor="job.lastEditorName"
            :author="job.ownerName ?? job.owner.name"
            :edit_date="job.special_request_updated_at"
            :create_date="job.created_at"
            :editable="editable"
            :value="job.special_request"
            endpoint="jobs.update.specialRequest"
            :model="job"
            blockStyle="notice-secondary"
        />
        <EditableField
            label="Fault reported"
            :author="job.store.name"
            :create_date="job.created_at"
            :editable="editable"
            :value="job.fault_report"
            endpoint="jobs.update.faultReport"
            blockStyle="notice-light"
            :model="job"
        />

        <div class="content-block">
            <h3>Grooming Notes</h3>
            <div class="content-block-section">
            <p>{{ job.grooming_notes }}</p>
            </div>
        </div>

        <Field>
            <div class="info-split">
                <div class="notice-light content-space-between">
                    <span>Model</span>
                    <span class="weight-medium">{{ job.machine.model.name }}</span>
                </div>
                <div class="notice-light content-space-between">
                    <span>Serial no.</span>
                    <span class="weight-medium">{{ job.machine.serial_number }}</span>
                </div>
            </div>
        </Field>

<!--        <Checklist
            v-for="checklist in checklists"
            :checklist="checklist"
        />-->

        <template #actions>
            <FormActions :actions="actions" :setAction="setAction" :submit="submit" />
        </template>
    </Layout>
</template>

<script>
    import { job, checklists, form } from '@/Mixins'
    import MachineCard from '@Cards/MachineCard'
    import JobTracker from '@/Components/JobTracker'
    import Field from '@/Components/Field'
    import EditableField from '@/Components/EditableField'

    export default {
        mixins: [job, checklists, form],
        props: {
            editable: {
                type: Boolean,
                default: false
            }
        },
        components: {
            JobTracker,
            MachineCard,
            Field,
            EditableField,
        },
    }
</script>

<style>

    .info-split {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 15px;
    }

    .notice-secondary, .notice-light {
        width: 100%;
    }

</style>
