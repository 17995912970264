<template>
    <Layout>
        <Titlebar :title="title" :links="links">
            <WorkshopStats :workshops="workshops" v-if="workshops" />
        </Titlebar>
        <Table :table="table" :row_action="showJob" />
    </Layout>
</template>

<script>
    import { table, workshops } from '@/Mixins'
    import Table from '@/Components/Table'
    import WorkshopStats from '@/Components/WorkshopStats'

    export default {
        mixins: [table, workshops],
        components: {
            Table,
            WorkshopStats
        },
        methods: {
            showJob(id) {
                let jobs = this.table.content.data;
                let job = jobs.find(job => job.id == id);
                let link = route('jobs.show', id);

                if(job.stepLink) {
                    link = job.stepLink;
                }

                this.$inertia.visit(link);
            }
        }
    }
</script>
