require('./bootstrap')

import { gsap, ScrollTrigger, Draggable } from "gsap/all"
import { createApp, h } from 'vue'
import { createInertiaApp, Link } from '@inertiajs/inertia-vue3'
import { InertiaProgress } from '@inertiajs/progress'
import Default from '@/Layouts/Default.vue'
import Constrained from '@/Layouts/Constrained.vue'
import Titlebar from '@/Components/Titlebar.vue'
import Checklist from '@/Components/Checklist.vue'
import Multiselect from '@vueform/multiselect'
import Checkbox from '@/Components/Checkbox'
import GearIcon from '@/Icons/GearIcon'
import CrossIcon from '@/Icons/CrossIcon'
import MarkerIcon from '@/Icons/MarkerIcon'
import SearchIcon from '@/Icons/SearchIcon'
import Field from '@/Components/Field'
import ModalPlugin from '@/Plugins/ModalPlugin'
import mitt from 'mitt'

createInertiaApp({
    resolve: name => require(`@/Pages/${name}`),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ModalPlugin)
            .component('Multiselect', Multiselect)
            .component('Layout', Default)
            .component('Constrained', Constrained)
            .component('Link', Link)
            .component('Titlebar', Titlebar)
            .component('Checklist', Checklist)
            .component('Field', Field)
            .component('GearIcon', GearIcon)
            .component('CrossIcon', CrossIcon)
            .component('SearchIcon', SearchIcon)
            .component('MarkerIcon', MarkerIcon)
            .component('Checkbox', Checkbox)

        gsap.registerPlugin(ScrollTrigger, Draggable)
        app.config.globalProperties.$gsap = gsap
        app.config.globalProperties.$notifications = []
        app.config.globalProperties.ebus = mitt()

        app.config.globalProperties.$filters = {
            capitalize(value) {
                if (!value) return ''
                value = value.toString()
                return value.charAt(0).toUpperCase() + value.slice(1)
            },
            // Put the rest of your filters here
        }

        app.mount(el)
    },
})

InertiaProgress.init()

// const intercept = (urlmatch, callback) => {
//     let send = XMLHttpRequest.prototype.send;
//     XMLHttpRequest.prototype.send = function() {
//         callback(operations => {
//             operations()
//             send.apply(this, arguments);
//         })
//         //this.addEventListener('readystatechange', function() {
//             //  if (this.responseURL.includes(urlmatch) && this.readyState === 4) {
//                 //    callback(this);
//                 //  }
//             //}, false);
//         //send.apply(this, arguments);
//     };
// };
//
// intercept(window.location.origin, (callback) => {
//
//     const detail = {
//         success: () => {
//             callback(() => {
//                 InertiaProgress.init()
//             })
//         }
//     }
//
//     const event = new CustomEvent('navigate', { detail })
//     window.dispatchEvent(event)
// });


