<template>
    <tr
        :class="classList"
    >
        <template v-if="head">
            <th
                :key="cell"
                v-for="cell in cells"
                :class="getHeadClass(cell)"
                @click="sort(cell)"
            >
                <div class="table-cell">
                    <SortIcon :sort="cell.sortDirection" />
                    <span>{{ cell.value }}</span>
                </div>
            </th>
        </template>
        <td v-else
            :key="cell"
            v-for="cell in cells"
        >
            <div class="table-cell">
                <component
                    v-if="isCustomCell(cell)"
                    :is="getCellComponent(cell)"
                    :value="cell.value"
                    :extra="cell.extra"
                    @click="getCellMethod(cell)"
                />
                <span
                    v-else
                    @click="cell.action"
                    :class="cell.classList"
                >
                    {{ cell.value }}
                </span>
            </div>
        </td>
    </tr>
</template>
<script>
import ActionCell from '@Table/ActionCell'
import StoreCell from '@Table/StoreCell'
import MachineCell from '@Table/MachineCell'
import PartCell from '@Table/PartCell'
import ChecklistCell from '@Table/ChecklistCell'
import UserCell from '@Table/UserCell'
import WorkshopCell from '@Table/WorkshopCell'
import ServiceCell from '@Table/ServiceCell'
import StockCell from '@Table/StockCell'
import FrequencyCell from '@Table/FrequencyCell'
import SortIcon from '@/Icons/SortIcon'

export default {
    components: {
        ActionCell,
        StoreCell,
        MachineCell,
        PartCell,
        ChecklistCell,
        UserCell,
        WorkshopCell,
        ServiceCell,
        StockCell,
        FrequencyCell,
        SortIcon,
    },
    props: {
        head: {
            type: Boolean,
            default: false
        },
        cells: {
            type: Array,
            default: []
        }
    },
    computed: {
        classList() {
            return this.head ? 'table-head' : 'table-row'
        }
    },
    methods: {
        isCustomCell(cell) {
            if(
                cell.component !== undefined ||
                cell.key === 'action' ||
                cell.key === 'store' ||
                cell.key === 'machine' ||
                cell.key === 'part' ||
                cell.key === 'checklist' ||
                cell.key === 'user' ||
                cell.key === 'workshop' ||
                cell.key === 'service'
            ) {
                return true
            }
            return false
        },
        getCellComponent(cell) {

            let name = ''

            if(cell.component !== undefined) {
                name = cell.component.charAt(0).toUpperCase() + cell.component.slice(1);
            } else {
                name = cell.key.charAt(0).toUpperCase() + cell.key.slice(1);
            }

            return `${name}Cell`
        },
        getCellMethod(cell) {
            if(typeof cell.action === 'function') {
                cell.action()
            }
        },
        getHeadClass(cell) {
            const classList = []

            if(cell.sort) {
                classList.push('sortable')
            }

            if(cell.sortDirection) {
                classList.push('active')
            }

            return classList.join(' ')
        },
        sort(cell) {
            if(!cell.sort) return

            if(!cell.sortDirection) {
                cell.sortDirection = 'asc'
            } else {
                if(cell.sortDirection === 'asc') {
                    cell.sortDirection = 'desc'
                } else if(cell.sortDirection === 'desc') {
                    cell.sortDirection = ''
                }
            }

            this.$emit('sort', cell)
        }
    }
}

</script>
<style scoped>

    td, th {
        margin: 0;
        padding: 0;
        text-align: left;
    }

    th svg {
        display: none;
        width: 10px;
        margin-right: 10px;
        color: inherit;
    }

    th.sortable {
        cursor: pointer;
    }

    th.sortable:hover .table-cell,
    th.sortable.active .table-cell {
        color: var(--default-secondary);
    }

    th.sortable svg {
        display: block;
    }

    td {
        background-color: var(--default-light);
    }

    td:first-child {
        border-radius: var(--default-border-radius) 0 0 var(--default-border-radius);
    }

    td:last-child {
        border-radius: 0 var(--default-border-radius) var(--default-border-radius) 0;
    }

    .table-cell {
        padding: 15px;
    }

    .table-head .table-cell {
        display: flex;
        align-items: center;
        padding: 15px 15px 10px 15px;
        text-transform: uppercase;
        font-size: 13px;
        letter-spacing: 1px;
        color: var(--default-grey);
        transition: color 150ms ease-in;
    }

    .table-head th:first-child .table-cell {
        padding-left: 0;
    }

</style>
