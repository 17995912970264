<template>
    <Layout>
        <template #sidebar>
            <WorkshopCard
                :workshop="workshop"
                :showTable="true"
            />
        </template>

        <Titlebar title="Workshop settings" />

        <div class="content-block">

            <FieldInline
                v-model="form.name"
                label="Workshop name:"
                :error="$attrs.errors.name"
            />

            <FieldInline
                v-model="form.address"
                label="Address:"
                :error="$attrs.errors.address"
            />

            <FieldInline
                v-model="form.rego"
                label="Rego:"
                :error="$attrs.errors.rego"
            />
        </div>

        <div class="content-block">
            <h3>People</h3>
            <div class="content-block-section users-block" v-if="users.length">
                <Link
                    v-for="user in users"
                    :href="getUserLink(user.id)"
                    class="user-block"
                >
                    <UserCell :value="{name:user.name}" />
                    <p>{{ user.rolesList }}</p>
                </Link>
            </div>
            <div class="content-block-section users-block" v-else>
                <p class="no-users">No users assigned to this workshop</p>
            </div>
        </div>

        <template #actions>
            <button
                @click="setAction(action)"
                v-for="action in actions"
                class="button-primary"
            >
            {{ action.text }}
            </button>
        </template>
    </Layout>
</template>

<script>
    import { users, workshop, form } from '@/Mixins'
    import WorkshopCard from '@Cards/WorkshopCard'
    import UserCell from '@Table/UserCell'
    import FieldInline from '@/Components/FieldInline'

    export default {
        mixins: [workshop, form, users],
        components: {
            WorkshopCard,
            UserCell,
            FieldInline
        },
        data() {
            return {
                form: this.workshop
            }
        },
        methods: {
            getUserLink(id) {
                return route('users.show', id)
            }
        }
    }
</script>

<style>

    .workshop-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 61px;
        margin: 0 auto;
        margin-bottom: 30px;
        color: var(--default-contrast);
    }

    .users-block {
        display: flex;
        flex-direction: column;
        padding-bottom: 0;
    }

    .no-users {
        margin-bottom: 12px;
    }

    .user-block {
        display: flex;
        margin-bottom: 12px;
        align-items: center;
    }

    .user-cell {
        flex: 0 0 20%;
        padding-right: 20px;
    }

</style>
